import React, { ComponentProps, useCallback } from 'react';
import Select, { SingleValue } from 'react-select';

import { useLocalizationContext } from '../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useCitiesList } from '../../../../../services/cities';
import { City } from '../../../../City/models/City';
import { CountryResponse } from '../../../../Countries/model/country-response';

type CitySelectProps = Omit<
  ComponentProps<typeof Select>,
  'options' | 'isLoading' | 'getOptionLabel' | 'getOptionValue' | 'placeholder' | 'onChange'
> & {
  selectedValue: City | null;
  onSelect: (value: City | null) => void;
  country?: CountryResponse | null;
};

export const CitySelect = ({ country, selectedValue, onSelect, ...props }: CitySelectProps) => {
  const {
    data: paginatedCities,
    isLoading: isLoadingCities,
    isFetching: isFetchingCities,
  } = useCitiesList({ limit: 0, offset: 1, searchText: '', countryId: country?.id }, !!country);
  const { language: languageFromContext } = useLocalizationContext();
  const handleChange = useCallback((value: SingleValue<City>) => onSelect(value), [onSelect]);
  const handleOptionLabel = useCallback(
    (option: City) => option.translations[languageFromContext],
    [languageFromContext],
  );
  const handleOptionValue = useCallback((option: City) => option.id, []);
  return (
    <Select<City>
      isLoading={isLoadingCities || isFetchingCities}
      isDisabled={props.isDisabled}
      getOptionLabel={handleOptionLabel}
      getOptionValue={handleOptionValue}
      onChange={handleChange}
      value={selectedValue}
      options={paginatedCities?.items}
      menuPlacement={'auto'}
    />
  );
};
