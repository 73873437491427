import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  getRestaurantBillingInformation,
  updateRestaurantBillingInformation,
} from '../api/restaurant-billing-information';
import { useToast } from '../common/components';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { RestaurantBillingInformationResponse } from '../modules/Restaurants/RestaurantBillingInformation/models/billing-information-response';
import { RestaurantBillingInformationUpdateRequest } from '../modules/Restaurants/RestaurantBillingInformation/models/restaurant-billing-information-update-request';

const RESTAURANT_BILLING_INFORMATION_QUERY_KEY = 'restaurantBillingInformation';

const getRestaurantBillingInformationQueryKey = (restaurantId: string) => [
  RESTAURANT_BILLING_INFORMATION_QUERY_KEY,
  restaurantId,
];

export const useRestaurantBillingInformation = (
  restaurantId: string,
): QueryObserverResult<RestaurantBillingInformationResponse, AxiosError<HttpException>> => {
  return useQuery(getRestaurantBillingInformationQueryKey(restaurantId), () =>
    getRestaurantBillingInformation(restaurantId),
  );
};

export const useUpdateRestaurantBillingInformation = (): UseMutationResult<
  RestaurantBillingInformationResponse,
  AxiosError<HttpException>,
  { id: string; restaurantBillingInformationUpdateRequest: RestaurantBillingInformationUpdateRequest }
> => {
  const queryClient = useQueryClient();
  const translations = useTranslations();
  const { displayToast } = useToast();
  return useMutation(
    ({
      id,
      restaurantBillingInformationUpdateRequest,
    }: {
      id: string;
      restaurantBillingInformationUpdateRequest: RestaurantBillingInformationUpdateRequest;
    }) => updateRestaurantBillingInformation(id, restaurantBillingInformationUpdateRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(RESTAURANT_BILLING_INFORMATION_QUERY_KEY);
      },
      onError: (error) => {
        const toastMessage = error.response?.data.message
          ? error.response?.data.message
          : translations('something_went_wrong');
        displayToast('error', toastMessage);
      },
    },
  );
};
