import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Image } from '@chakra-ui/react';

import { FileInput, FormFooter, FormGroup } from '../../../../common/components';
import FormInput from '../../../../common/components/FormInput/FormInput';
import {
  ImagePreviewValidation,
  isFileTooLarge,
} from '../../../../common/components/ImagePreviewValidation/ImagePreviewValidation';
import { Language } from '../../../../common/models/enums/language';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { CreateReusableTipRequest } from '../../models/create-reusable-tip-request';
import { ReusableTipFormValues } from '../../models/reusable-tip-form-values';
import { ReusableTipResponse } from '../../models/reusable-tip-response';
import { UpdateReusableTipRequest } from '../../models/update-reusable-tip-request';

type ReusableTipFormProps = {
  isLoading: boolean;
  onSubmit(data: CreateReusableTipRequest | UpdateReusableTipRequest): void;
  model?: ReusableTipResponse;
};

export const ReusableTipForm = ({ model, onSubmit, isLoading }: ReusableTipFormProps) => {
  const translations = useTranslations();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ReusableTipFormValues>({
    defaultValues: {
      title: { translationKey: translations('reusable_tips_form_title_translation_key_prefix') },
      description: { translationKey: translations('reusable_tips_form_description_translation_key_prefix') },
    },
  });

  const imageNamePreview: string | undefined = watch('temporaryImage')?.[0]?.name;
  const imageSizePreview: number | undefined = watch('temporaryImage')?.[0]?.size;

  useEffect(() => {
    reset(model);
  }, [reset, model]);

  const onFormSubmit = handleSubmit(({ temporaryImage, ...form }: ReusableTipFormValues) => {
    onSubmit({
      titleTranslationKey: form.title.translationKey,
      descriptionTranslationKey: form.description.translationKey,
      image: temporaryImage?.[0],
    });
  });

  const handleDeleteUploadedImage = () => setValue('temporaryImage', undefined);

  return (
    <form onSubmit={onFormSubmit}>
      <FormInput
        labelTooltipText={translations('reusable_tips_form_translation_key_tooltip')}
        id={'titleTranslationKey'}
        label={translations('reusable_tips_form_title')}
        useFormRegisterReturn={register('title.translationKey')}
        setValue={setValue}
      />
      <FormInput
        labelTooltipText={translations('reusable_tips_form_translation_key_tooltip')}
        id={'descriptionTranslationKey'}
        label={translations('reusable_tips_form_description')}
        useFormRegisterReturn={register('description.translationKey')}
        setValue={setValue}
      />
      {model ? (
        <FormInput
          id={`title.translations.${Language.En}`}
          label={translations('reusable_tips_form_title_en')}
          validationError={errors.title?.translations ? errors.title?.translations[Language.En]?.message : undefined}
          useFormRegisterReturn={register(`title.translations.${Language.En}`)}
          isDisabled={true}
          setValue={setValue}
        />
      ) : null}
      {model ? (
        <FormInput
          id={`description.translations.${Language.En}`}
          label={translations('reusable_tips_form_description_en')}
          validationError={
            errors.description?.translations ? errors.description?.translations[Language.En]?.message : undefined
          }
          useFormRegisterReturn={register(`description.translations.${Language.En}`)}
          isDisabled={true}
          setValue={setValue}
        />
      ) : null}
      <FormGroup label={translations('reusable_tips_form_image')} inputId={'image'}>
        <>
          <Image id="image" src={getValues('imageUrl') || ''} />
          <FileInput accept="image/*" register={register('temporaryImage')} aria-label="temporaryImage" />
          {imageNamePreview && imageSizePreview ? (
            <ImagePreviewValidation
              imageNamePreview={imageNamePreview}
              imageSizePreview={imageSizePreview}
              handleDeleteUploadedImage={handleDeleteUploadedImage}
            />
          ) : null}
        </>
      </FormGroup>
      <FormFooter
        isLoadingSubmitResult={isLoading}
        isSubmitDisabled={imageSizePreview ? isFileTooLarge(imageSizePreview) : false}
      />
    </form>
  );
};
