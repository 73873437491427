import React from 'react';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Flex } from '@chakra-ui/react';

import {
  AddEditItemLink,
  ErrorMessages,
  LinkButton,
  MainPageContent,
  PageHeading,
  Table,
} from '../../common/components';
import { useDebounce } from '../../common/hooks/useDebounce';
import { Language } from '../../common/models/enums/language';
import { config } from '../../config/config';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useCountries } from '../../services/countries';
import { CountryResponse } from './model/country-response';

export const Countries = () => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);

  const {
    data,
    isFetching,
    isLoading,
    error: loadCitiesError,
  } = useCountries({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });

  const columns = React.useMemo(
    () => [
      {
        id: '1',
        Header: translations('countries_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('countries_table_translation_key'),
        accessor: 'translationKey' as const,
      },
      {
        id: '3',
        Header: translations('countries_table_name'),
        accessor: 'translations' as const,
        Cell: ({ cell }: CellProps<CountryResponse>) => cell.value[Language.En],
      },
      {
        id: '4',
        Header: translations('countries_table_currency'),
        accessor: 'currency' as const,
      },
      {
        id: 'actions',
        Header: translations('countries_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<CountryResponse>) => (
          <Flex>
            <AddEditItemLink to={generatePath(Routes.COUNTRIES_COUNTRY_PATH, { id: original.id })} m={1} isEdit />
            <LinkButton to={generatePath(Routes.COUNTRIES_COUNTRY_PRICING_PATH, { id: original.id })} m={1}>
              {translations('countries_table_pricing')}
            </LinkButton>
          </Flex>
        ),
      },
    ],
    [translations],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('countries_header')}</PageHeading>
      <ErrorMessages errors={[loadCitiesError]} />
      <Table<CountryResponse>
        isLoading={isFetching || isLoading}
        data={data?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={data?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('countries_searchbar')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};
