import { CLASSIC_META_CATEGORY_ID } from '../../Restaurants/restaurant.consts';
import { CategoryFormModel } from '../CategoriesForm';
import { CategoryGroup, CategoryRequest } from '../model/Category.model';

export const getParsedData = (values: CategoryFormModel): CategoryRequest => {
  const { metaCategory, imageTemporary, ...data } = values;
  const formattedValues: CategoryRequest = {
    ...data,
    metaCategoryId: values.metaCategory?.id ?? CLASSIC_META_CATEGORY_ID,
    image: values.imageTemporary?.[0],
  };
  return {
    ...formattedValues,
  };
};

export const mapCategoryGroupToTranslation = (group: string | null): string => {
  switch (group) {
    case CategoryGroup.CUPS:
      return 'categories_group_cups';
    case CategoryGroup.BOWLS:
      return 'categories_group_bowls';
    case CategoryGroup.PIZZA:
      return 'categories_group_pizza';
    default:
      return '';
  }
};
