import React from 'react';
import { Box, Card, CardBody, Flex } from '@chakra-ui/react';

type CategoryGroupCardProps = {
  header: string;
  width: string | string[];
  children: React.ReactNode;
};

export const CategoryGroupCard = ({ header, width, children }: CategoryGroupCardProps) => {
  return (
    <Box width={width} p={1}>
      <Card width={'100%'}>
        <CardBody>
          <Flex width={'100%'} wrap={'wrap'}>
            <Box width={'100%'} mb={-2}>
              {header}
            </Box>
            {children}
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
};
