import React, { JSX, ReactNode, useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Switch,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import { FormFooter, FormGroup, Select, useToast } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';
import { cloneDeep } from 'lodash';
import { City } from 'modules/City/models/City';
import { KeyAccountGroupsResponse } from 'modules/KeyAccountGroups/models/key-account-groups-response';
import { TagResponse } from 'modules/Tags/models/tag-response';

import './DatePicker.css';

import { getRestaurantDetailsFromGoogle } from '../../../api/restaurants';
import FormInput from '../../../common/components/FormInput/FormInput';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../common/constants/common';
import {
  accountPriorityOptions,
  AccountPrioritySelect,
} from '../../../common/constants/select-options/accountPriority.consts';
import { Role } from '../../../common/types/auth';
import { getFormattedDate } from '../../../common/utils';
import {
  addHttpsProtocol,
  generateUrlForGoogleFaviconApi,
  getStringUrl,
  hasUrlHttpProtocol,
  isValidStringUrl,
} from '../../../common/utils/url.helper';
import { DATE_PICKER_LOCALIZATION_CONFIG } from '../../../config/configConsts';
import { useLocalizationContext } from '../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useSessionContext } from '../../../contexts/SessionContext';
import { ClosingSource, ClosingSourceStatus } from '../../ClosingSources/model/ClosingSource';
import { MetaCategory } from '../../MetaCategories/model/MetaCategory';
import { useRestaurantCharacteristicsLists } from '../ImportRestaurants/utils/use-restaurants-characteristics-lists.hook';
import {
  RestaurantDetailsResponse,
  RestaurantOpeningHoursResponse,
  RestaurantPromotionResponse,
  RestaurantRequest,
} from '../models';
import { CLASSIC_META_CATEGORY_ID } from '../restaurant.consts';
import { canAccountPriorityBeChanged, getKeyAccountGroupPriority } from '../utils/account-priority.helper';
import { getDeliverersData } from '../utils/deliverers.helper';
import { handleGoogleOpeningHours } from '../utils/opening-hours.helper';
import {
  defaultRestaurantStatus,
  RestaurantStatus,
  StarterSetSizeSelect,
  starterSetSizeSelectOptions,
  StatusSelect,
  statusSelectOptions,
} from './RestaurantForm.form';
import { isOpeningHours, prepareOpeningHours } from './restaurantFormHelper';
import RestaurantGoogleInput from './RestaurantGoogleInput';
import { RestaurantOpeningHoursDay } from './RestaurantOpeningHoursDay';

export enum RestaurantFormMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  IMPORT = 'IMPORT',
  DUPLICATE = 'DUPLICATE',
  MASS_UPDATE = 'MASS_UPDATE',
}

type FormModel = Omit<RestaurantDetailsResponse, 'metaCategories'> & {
  metaCategories: MetaCategory[];
};

type RestaurantFormProps = {
  methods: UseFormReturn<RestaurantDetailsResponse>;
  mode: RestaurantFormMode;
  isLoading: boolean;
  onSubmit: (request: RestaurantRequest) => void;
  model?: RestaurantDetailsResponse;
  hasFormFooterCustomContent?: boolean;
  customFormFooter?: ReactNode | ReactNode[] | null;
  onDuplicateRestaurant?: () => void;
  onCancel?: () => void;
};

const RestaurantForm = ({
  methods,
  mode,
  isLoading,
  onSubmit,
  model,
  customFormFooter = null,
  hasFormFooterCustomContent = false,
  onDuplicateRestaurant,
  onCancel,
}: RestaurantFormProps): JSX.Element => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [updatedFields, setUpdatedFields] = useState<string[]>([]);
  const { displayToast } = useToast();
  const { colors } = useTheme();
  const { role } = useSessionContext();
  const [openingHours, setOpeningHours] = useState<RestaurantOpeningHoursResponse[]>([]);
  const [isOverrideEnabled, setIsOverrideEnabled] = useState<boolean>(false);

  const {
    cities,
    isCityListLoading,
    keyAccounts,
    isKeyAccountGroupListLoading,
    tags,
    isTagListLoading,
    metaCategories,
    isMetaCategoryListLoading,
    closingSources,
    isClosingSourceListLoading,
    promotions,
    isPromotionListLoading,
  } = useRestaurantCharacteristicsLists(ALL_PAGINATED_LIST_ITEMS_PARAMS);

  // FYI: `tags` were added along with import restaurants changes to solve some issues with `tags`
  // removed `tags` due to no issues, if there are any in the future - `tags` must be restored
  useEffect(() => {
    setOpeningHours(prepareOpeningHours(model));
    if (model) {
      if (mode !== RestaurantFormMode.IMPORT) {
        // FYI: it is done in EditImportedRestaurant along with import restaurant validation, so we do not want to reset model here
        methods.reset(model);
      }
      setIsOverrideEnabled(!!model.overrideOpeningHours);
    }
  }, [methods, model, mode]);

  const watchCustomDeliveryUrl: string | null = methods.watch('deliverers.customDelivery.url');
  const watchCustomDeliveryImageUrl: string | null = methods.watch('deliverers.customDelivery.imageUrl');
  const watchKeyAccountId: string | undefined = methods.watch('keyAccountId');

  const updateFieldsFromGoogle = (fields?: string[]) => async () => {
    const googlePlaceId = methods.getValues('googlePlaceId');
    if (googlePlaceId) {
      setIsLoadingGoogle(true);
      getRestaurantDetailsFromGoogle(googlePlaceId, fields)
        .then((details) => {
          for (const [key, value] of Object.entries(details)) {
            if (value) {
              setUpdatedFields((prevState) => [...prevState, key]);
              methods.setValue(key as keyof RestaurantDetailsResponse, value.toString());
            }
            if (key === 'restaurantOpeningHours' && isOpeningHours(value) && !isOverrideEnabled) {
              const restaurantOpeningHours = handleGoogleOpeningHours(value);
              if (model) {
                model.restaurantOpeningHours = restaurantOpeningHours;
              }
              setOpeningHours(restaurantOpeningHours);
            }
          }
        })
        .catch(() => {
          const toastMessage = translations('restaurant_update_fields_fetching_error');
          displayToast('error', toastMessage);
        })
        .finally(() => {
          setIsLoadingGoogle(false);
        });
    }
  };

  const handleSetFavicon = () => {
    const url: string = methods.getValues('deliverers.customDelivery.url') || '';
    let trimmedUrl = url.trim();
    if (trimmedUrl.length && !hasUrlHttpProtocol(trimmedUrl)) {
      trimmedUrl = addHttpsProtocol(trimmedUrl);
    }
    if (trimmedUrl.length > 0 && !isValidStringUrl(trimmedUrl)) {
      methods.setValue('deliverers.customDelivery.imageUrl' as keyof RestaurantDetailsResponse, '');
      methods.setError('deliverers.customDelivery.url' as keyof RestaurantDetailsResponse, {
        message: translations('validation_incorrect_url_format'),
        type: 'validate',
      });
      return;
    }
    if (trimmedUrl.length > 0 && isValidStringUrl(trimmedUrl)) {
      methods.setValue(
        'deliverers.customDelivery.imageUrl' as keyof RestaurantDetailsResponse,
        generateUrlForGoogleFaviconApi(new URL(trimmedUrl).hostname),
      );
    }
    if (trimmedUrl.length <= 0) {
      methods.setValue('deliverers.customDelivery.imageUrl' as keyof RestaurantDetailsResponse, '');
    }
    methods.clearErrors('deliverers.customDelivery.url' as keyof RestaurantDetailsResponse);
  };

  const onFormSubmit = methods.handleSubmit(({ deliverers, closingSource, city, id, ...form }: FormModel) => {
    const customDeliveryUrl = getStringUrl(deliverers?.customDelivery?.url?.trim());
    const customDeliveryImageUrl =
      customDeliveryUrl && isValidStringUrl(customDeliveryUrl)
        ? generateUrlForGoogleFaviconApi(new URL(customDeliveryUrl).hostname)
        : null;
    const deliverersData = getDeliverersData(customDeliveryUrl, customDeliveryImageUrl, deliverers);

    const tempOpeningHours = cloneDeep(openingHours);
    const sanitizedOpeningHours: RestaurantOpeningHoursResponse[] = [];
    for (const openingHoursDay of tempOpeningHours) {
      sanitizedOpeningHours.push({
        day: openingHoursDay.day,
        periods:
          openingHoursDay.periods?.filter((period) =>
            Boolean(
              // Checking for always open format in the second part of OR statement
              (period.openTime && period.closeTime) || (period.openTime === '00:00' && period.closeTime === null),
            ),
          ) ?? [],
      });
    }

    onSubmit({
      ...form,
      latitude: form.latitude || null,
      longitude: form.longitude || null,
      websiteLink: getStringUrl(form.websiteLink) || undefined,
      addressLinkAndroid: getStringUrl(form.addressLinkAndroid) || undefined,
      deliverers: deliverersData,
      tags: form.tags?.map((t: TagResponse) => t.id),
      promotions: form.promotions?.map((t: RestaurantPromotionResponse) => t.id),
      cityId: city?.id,
      status: form.status,
      closingSourceId: closingSource?.id,
      metaCategories: form.metaCategories?.map((metaCategory: MetaCategory) => metaCategory.id),
      starterSetStartDate: getFormattedDate(form.starterSetStartDate),
      restaurantOpeningHours: sanitizedOpeningHours,
      overrideOpeningHours: isOverrideEnabled,
    });
  });

  const restaurantStatusSelectOptions = (): StatusSelect[] => {
    return role !== Role.ADMINISTRATOR
      ? statusSelectOptions.filter((option) => option.value !== RestaurantStatus.DELETED)
      : statusSelectOptions;
  };

  if (
    isCityListLoading ||
    isPromotionListLoading ||
    isKeyAccountGroupListLoading ||
    isTagListLoading ||
    isMetaCategoryListLoading ||
    isClosingSourceListLoading
  ) {
    return <Spinner />;
  }

  const updateCustomDelivery = (event: React.FocusEvent<HTMLInputElement>) => {
    methods.setValue('deliverers.customDelivery.url', event.target.value.trim() as never);
    handleSetFavicon();
  };

  const handleOverrideSwitch = () => {
    setIsOverrideEnabled(!isOverrideEnabled);
  };

  const fieldPlaceholder =
    mode === RestaurantFormMode.MASS_UPDATE ? translations('restaurants_mass_update_input_placeholder') : undefined;

  return (
    <form data-testid="form" onSubmit={onFormSubmit} autoComplete="off">
      <RestaurantGoogleInput
        fieldErrors={methods.formState.errors.googlePlaceId?.message}
        label={translations('restaurants_form_google_place_id')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('googlePlaceId')}
        onClick={updateFieldsFromGoogle()}
        fieldName="googlePlaceId"
        setValue={methods.setValue}
        buttonDisabled={isLoadingGoogle}
        formMode={mode}
      />
      <RestaurantGoogleInput
        fieldErrors={methods.formState.errors.restaurantName?.message}
        label={translations('restaurants_form_name')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('restaurantName')}
        onClick={updateFieldsFromGoogle(['name'])}
        buttonDisabled={isLoadingGoogle}
        fieldName="restaurantName"
        setValue={methods.setValue}
        formMode={mode}
      />
      <FormInput
        id="restaurantDetail"
        label={translations('restaurants_form_detail')}
        useFormRegisterReturn={methods.register('restaurantDetail')}
        setValue={methods.setValue}
        validationError={methods.formState.errors.restaurantDetail?.message}
        placeholder={fieldPlaceholder}
      />
      <FormGroup
        label={translations('restaurants_form_city')}
        inputId="city"
        validationError={(methods.formState.errors.city as FieldError)?.message}
      >
        <Controller
          control={methods.control}
          name="city"
          render={({ field: { value, onChange, name } }) => (
            <Select<City>
              name={name}
              getOptionLabel={(option) =>
                cities?.find((city) => city.id === (option as City).id)?.translations[language] ||
                (option as City).translations[language]
              }
              getOptionValue={(option) => (option as City).id}
              value={value}
              onChange={onChange}
              options={cities}
              placeholder={fieldPlaceholder}
            />
          )}
        />
      </FormGroup>
      <RestaurantGoogleInput
        fieldErrors={methods.formState.errors.restaurantAddress?.message}
        label={translations('restaurants_form_address')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('restaurantAddress')}
        onClick={updateFieldsFromGoogle(['formatted_address'])}
        buttonDisabled={isLoadingGoogle}
        fieldName="restaurantAddress"
        setValue={methods.setValue}
        formMode={mode}
      />
      <RestaurantGoogleInput
        fieldErrors={methods.formState.errors.streetName?.message}
        label={translations('restaurants_form_street_name')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('streetName')}
        onClick={updateFieldsFromGoogle(['address_component'])}
        buttonDisabled={isLoadingGoogle}
        fieldName="streetName"
        setValue={methods.setValue}
        formMode={mode}
      />
      <RestaurantGoogleInput
        fieldErrors={methods.formState.errors.phoneNumber?.message}
        label={translations('restaurants_form_phone')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('phoneNumber')}
        onClick={updateFieldsFromGoogle(['international_phone_number'])}
        buttonDisabled={isLoadingGoogle}
        fieldName="phoneNumber"
        setValue={methods.setValue}
        formMode={mode}
      />
      <RestaurantGoogleInput
        fieldErrors={methods.formState.errors.websiteLink?.message}
        label={translations('restaurants_form_website_link')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('websiteLink')}
        onClick={updateFieldsFromGoogle(['website'])}
        buttonDisabled={isLoadingGoogle}
        fieldName="websiteLink"
        setValue={methods.setValue}
        formMode={mode}
      />
      <Box width="100%" display="flex" alignItems="center">
        <FormGroup
          label={translations('restaurants_form_custom_delivery_link')}
          inputId="deliverers.customDelivery.url"
          validationError={methods.formState.errors.deliverers?.customDelivery?.url?.message}
        >
          <InputGroup>
            <Input
              id="deliverers.customDelivery.url"
              {...methods.register('deliverers.customDelivery.url')}
              onBlur={updateCustomDelivery}
              placeholder={fieldPlaceholder}
            />
            <InputRightElement width="auto">
              <Button
                right="0.5rem"
                h="1.75rem"
                size="sm"
                onClick={handleSetFavicon}
                isDisabled={watchCustomDeliveryUrl?.trim().length === 0}
              >
                {translations('restaurants_form_custom_delivery_link_button')}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormGroup>
        {watchCustomDeliveryImageUrl && watchCustomDeliveryImageUrl.length > 0 ? (
          <Image
            alt="favicon"
            src={watchCustomDeliveryImageUrl ?? undefined}
            width="50px"
            height="50px"
            marginLeft="10px"
          />
        ) : null}
      </Box>
      <FormInput
        label={translations('restaurants_form_wolt_link')}
        validationError={methods.formState.errors.deliverers?.wolt?.url?.message}
        id={'deliverers.wolt.url'}
        useFormRegisterReturn={methods.register('deliverers.wolt.url')}
        setValue={methods.setValue}
        placeholder={fieldPlaceholder}
      />
      <FormInput
        label={translations('restaurants_form_lieferando_link')}
        validationError={methods.formState.errors.deliverers?.lieferando?.url?.message}
        id={'deliverers.lieferando.url'}
        useFormRegisterReturn={methods.register('deliverers.lieferando.url')}
        setValue={methods.setValue}
        placeholder={fieldPlaceholder}
      />
      <FormInput
        label={translations('restaurants_form_uber_link')}
        validationError={methods.formState.errors.deliverers?.uberEats?.url?.message}
        id={'deliverers.uberEats.url'}
        useFormRegisterReturn={methods.register('deliverers.uberEats.url')}
        setValue={methods.setValue}
        placeholder={fieldPlaceholder}
      />
      <RestaurantGoogleInput
        fieldErrors={methods.formState.errors.addressLinkAndroid?.message}
        label={translations('restaurants_form_link_android')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('addressLinkAndroid')}
        onClick={updateFieldsFromGoogle(['url'])}
        buttonDisabled={isLoadingGoogle}
        fieldName="addressLinkAndroid"
        setValue={methods.setValue}
        formMode={mode}
      />
      <Box width="60%" mt={5} mb={1}>
        <FormControl>
          <Flex>
            <Tooltip
              label={translations('restaurants_form_override_tooltip')}
              shouldWrapChildren={true}
              isDisabled={isOverrideEnabled}
            >
              <Switch
                mt={0.5}
                mr={2}
                id="override-location-data"
                {...methods.register('overrideOpeningHours')}
                onChange={handleOverrideSwitch}
              />
            </Tooltip>
            <FormLabel mt={0.5} htmlFor="override-location-data">
              {translations('restaurants_form_override_google_maps_opening_hours')}
            </FormLabel>
            {mode !== RestaurantFormMode.MASS_UPDATE ? (
              <Tooltip
                label={translations('restaurants_form_override_google_tooltip')}
                shouldWrapChildren={true}
                isDisabled={!isOverrideEnabled}
              >
                <Button
                  right="0.5rem"
                  h="1.75rem"
                  size="sm"
                  ml={3}
                  onClick={updateFieldsFromGoogle(['opening_hours'])}
                  isDisabled={isLoadingGoogle || isOverrideEnabled}
                >
                  {translations('update_field_from_google')}
                </Button>
              </Tooltip>
            ) : null}
          </Flex>
        </FormControl>
        {openingHours
          ? openingHours.map((day) => (
              <RestaurantOpeningHoursDay
                day={day}
                openingHours={openingHours}
                setOpeningHours={setOpeningHours}
                isOverrideEnabled={isOverrideEnabled}
                isLoadingGoogle={isLoadingGoogle}
                key={day.day}
              />
            ))
          : null}
      </Box>
      <RestaurantGoogleInput
        formMode={mode}
        fieldErrors={methods.formState.errors.latitude?.message}
        label={translations('restaurants_form_latitude')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('latitude')}
        onClick={updateFieldsFromGoogle(['geometry/location'])}
        buttonDisabled={isLoadingGoogle}
        fieldName="latitude"
        setValue={methods.setValue}
      />
      <RestaurantGoogleInput
        formMode={mode}
        fieldErrors={methods.formState.errors.longitude?.message}
        label={translations('restaurants_form_longitude')}
        updatedFields={updatedFields}
        setUpdatedFields={setUpdatedFields}
        useFormRegisterReturn={methods.register('longitude')}
        onClick={updateFieldsFromGoogle(['geometry/location'])}
        buttonDisabled={isLoadingGoogle}
        fieldName="longitude"
        setValue={methods.setValue}
      />
      <FormGroup
        label={translations('restaurants_form_tags')}
        inputId="tags"
        validationError={(methods.formState.errors.tags as FieldError)?.message}
      >
        <Controller
          control={methods.control}
          name="tags"
          render={({ field: { value, onChange, name } }) => (
            <Select<TagResponse>
              name={name}
              isMulti
              getOptionLabel={(option) =>
                tags?.find((tag) => tag.id === (option as TagResponse).id)?.translations[language] ||
                ((<span style={{ color: 'red' }}>{(option as TagResponse).translations[language]}</span>) as any)
              }
              getOptionValue={(option) => (option as TagResponse).id || (option as TagResponse).translations[language]}
              value={value}
              onChange={onChange}
              options={tags}
              placeholder={fieldPlaceholder}
              isDisabled={mode === RestaurantFormMode.MASS_UPDATE}
            />
          )}
        />
      </FormGroup>
      <FormGroup
        label={translations('restaurants_form_promotions')}
        inputId="promotions"
        validationError={(methods.formState.errors.promotions as FieldError)?.message}
      >
        <Controller
          control={methods.control}
          name="promotions"
          render={({ field: { value, onChange, name } }) => (
            <Select<RestaurantPromotionResponse>
              name={name}
              aria-labelledby="promotions-label"
              isMulti
              getOptionLabel={(option) =>
                promotions?.find((promotion) => promotion.id === (option as RestaurantPromotionResponse).id)
                  ?.translations[language] ||
                ((
                  <span style={{ color: 'red' }}>{(option as RestaurantPromotionResponse).translations[language]}</span>
                ) as any)
              }
              getOptionValue={(option) =>
                (option as RestaurantPromotionResponse).id ||
                (option as RestaurantPromotionResponse).translations[language]
              }
              value={value}
              onChange={onChange}
              options={promotions}
              placeholder={fieldPlaceholder}
              isDisabled={mode === RestaurantFormMode.MASS_UPDATE}
            />
          )}
        />
      </FormGroup>
      <FormGroup
        label={translations('restaurants_form_status')}
        inputId="status"
        validationError={(methods.formState.errors.status as FieldError)?.message}
      >
        <Controller
          control={methods.control}
          name="status"
          render={({ field: { value, onChange, name } }) => (
            <Select<StatusSelect>
              name={name}
              getOptionLabel={(option) => (option as StatusSelect).label}
              getOptionValue={(option) => (option as StatusSelect).value}
              defaultValue={
                mode !== RestaurantFormMode.MASS_UPDATE
                  ? restaurantStatusSelectOptions().find((option) => option.value === defaultRestaurantStatus)
                  : undefined
              }
              value={restaurantStatusSelectOptions().find((option) => option.value === value)}
              valueMapper={(v: StatusSelect | null) => v?.value}
              onChange={onChange}
              options={restaurantStatusSelectOptions()}
              placeholder={fieldPlaceholder}
            />
          )}
        />
      </FormGroup>
      <FormGroup
        inputId="metaCategories"
        label={translations('restaurants_form_meta_categories')}
        validationError={(methods.formState.errors.metaCategories as FieldError)?.message}
      >
        <Controller
          name="metaCategories"
          control={methods.control}
          render={({ field: { value, onChange, name } }) => (
            <Select<MetaCategory>
              name={name}
              isMulti
              getOptionLabel={(option) =>
                metaCategories?.find((metaCategory) => metaCategory.id === (option as MetaCategory).id)?.translations[
                  language
                ] || ((<span style={{ color: 'red' }}>{(option as MetaCategory).translations[language]}</span>) as any)
              }
              getOptionValue={(option) =>
                (option as MetaCategory).id || (option as MetaCategory).translations[language]
              }
              onChange={onChange}
              defaultValue={metaCategories?.find((option) => option.id === CLASSIC_META_CATEGORY_ID)}
              value={value}
              options={metaCategories}
              placeholder={fieldPlaceholder}
              isDisabled={mode === RestaurantFormMode.MASS_UPDATE}
            />
          )}
        />
      </FormGroup>

      <FormGroup
        label={translations('key_account_group')}
        inputId="keyAccountId"
        validationError={methods.formState.errors.keyAccountId?.message}
      >
        <Controller
          control={methods.control}
          name="keyAccountId"
          render={({ field: { value, onChange, name } }) => (
            <Select<KeyAccountGroupsResponse>
              name={name}
              getOptionLabel={(option) =>
                `${(option as KeyAccountGroupsResponse).keyAccount} - ${(option as KeyAccountGroupsResponse).segment
                  ?.name}`
              }
              getOptionValue={(option) => (option as KeyAccountGroupsResponse).id}
              onChange={onChange}
              value={
                value
                  ? keyAccounts?.find((group) => group.id === value) ?? {
                      id: undefined,
                      keyAccount: value || '',
                      segment: {
                        name: value?.length ? translations('restaurant_form_key_account_error') : '',
                      },
                    }
                  : undefined
              }
              valueMapper={(v: KeyAccountGroupsResponse | null) => v?.id}
              options={keyAccounts}
              placeholder={fieldPlaceholder}
            />
          )}
        />
      </FormGroup>

      <FormGroup
        label={translations('restaurants_form_account_priority')}
        inputId="accountPriority"
        validationError={(methods.formState.errors.accountPriority as FieldError)?.message}
      >
        <Controller
          control={methods.control}
          name="accountPriority"
          render={({ field: { value, onChange, name } }) => (
            <Select<AccountPrioritySelect>
              isDisabled={!canAccountPriorityBeChanged(watchKeyAccountId, model)}
              name={name}
              getOptionLabel={(option) => (option as AccountPrioritySelect).label}
              getOptionValue={(option) => (option as AccountPrioritySelect).value}
              value={
                !canAccountPriorityBeChanged(watchKeyAccountId, model)
                  ? getKeyAccountGroupPriority(watchKeyAccountId, keyAccounts)
                  : accountPriorityOptions.find((option) => option.value === value)
              }
              valueMapper={(v: AccountPrioritySelect | null) => v?.value}
              onChange={onChange}
              options={accountPriorityOptions}
              placeholder={fieldPlaceholder}
            />
          )}
        />
      </FormGroup>

      <FormGroup
        label={translations('restaurants_form_closing_source')}
        inputId="closingSource"
        validationError={(methods.formState.errors.closingSource as FieldError)?.message}
      >
        <Controller
          control={methods.control}
          name="closingSource"
          render={({ field: { value, onChange, name } }) => (
            <>
              {value?.status === ClosingSourceStatus.INACTIVE ? (
                <Text color={colors.grey[200]}>{`${value?.name} (${value?.status})`}</Text>
              ) : null}
              <Select<ClosingSource>
                name={name}
                getOptionLabel={(option) => (option as ClosingSource).name}
                getOptionValue={(option) => (option as ClosingSource).id}
                defaultValue={mode !== RestaurantFormMode.MASS_UPDATE && closingSources ? closingSources[0] : undefined}
                value={closingSources?.find((option) => option.name === value?.name) || value}
                onChange={onChange}
                options={closingSources}
                placeholder={fieldPlaceholder}
              />
            </>
          )}
        />
      </FormGroup>

      <FormGroup label={translations('restaurants_form_starter_set_start_date')} inputId="starterSetStartDate">
        <Controller
          control={methods.control}
          name="starterSetStartDate"
          render={({ field: { value, onChange } }) => (
            <DatePicker
              locale={DATE_PICKER_LOCALIZATION_CONFIG.locale}
              format={DATE_PICKER_LOCALIZATION_CONFIG.dateFormat}
              value={value ? new Date(value) : null}
              onChange={(v) => {
                onChange(v);
              }}
            />
          )}
        />
      </FormGroup>
      <FormGroup
        label={translations('restaurants_form_starter_set_size')}
        inputId="starterSetSize"
        validationError={(methods.formState.errors.starterSetSize as FieldError)?.message}
      >
        <Controller
          control={methods.control}
          name="starterSetSize"
          render={({ field: { value, onChange, name } }) => (
            <Select<StarterSetSizeSelect>
              name={name}
              getOptionLabel={(option) => (option as StarterSetSizeSelect).label}
              getOptionValue={(option) => (option as StarterSetSizeSelect).value}
              value={starterSetSizeSelectOptions.find((option) => option.value === value)}
              valueMapper={(v: StarterSetSizeSelect | null) => v?.value}
              onChange={onChange}
              options={starterSetSizeSelectOptions}
              placeholder={fieldPlaceholder}
              defaultValue={undefined}
            />
          )}
        />
      </FormGroup>
      <FormInput
        label={translations('restaurants_form_wholesale_customer_id')}
        validationError={methods.formState.errors.wholesaleCustomerId?.message}
        id={'wholesaleCustomerId'}
        useFormRegisterReturn={methods.register('wholesaleCustomerId')}
        setValue={methods.setValue}
        placeholder={fieldPlaceholder}
      />
      <Flex>
        <FormFooter isLoadingSubmitResult={isLoading} hasCustomContent={hasFormFooterCustomContent || false}>
          {customFormFooter ? (
            customFormFooter
          ) : (
            <>
              <Button mr={4} type="submit" isDisabled={!!Object.keys(methods.formState.errors).length}>
                {translations('save')}
              </Button>
              <Button onClick={onCancel}>{translations('cancel')}</Button>
            </>
          )}
        </FormFooter>
        {mode === RestaurantFormMode.UPDATE ? (
          <Flex justifyContent="flex-start" mt={4} ml={4}>
            <Button onClick={onDuplicateRestaurant} color={colors.orange[500]}>
              {translations('restaurants_form_duplicate_button')}
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </form>
  );
};

export default RestaurantForm;
