import { MetaCategoryFormValues, MetaCategoryRequest } from '../model/MetaCategory';

export const getParsedData = (values: MetaCategoryFormValues): MetaCategoryRequest => {
  const formattedValues = {
    color: values.color ? values.color : null,
    type: values.type,
    image: values.imageTemporary?.[0],
    translationKey: values.translationKey,
  } as MetaCategoryRequest;
  return {
    ...formattedValues,
  };
};
