import React, { JSX } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter, FormGroup, Select } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import { Language } from '../../common/utils';
import { useLocalizationContext } from '../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useCountries } from '../../services/countries';
import { CountryResponse } from '../Countries/model/country-response';
import { City, CityFormValues, CityRequest } from './models/City';
import { useCityFormValidationSchema } from './CityForm.form';

type Props = {
  data?: City | undefined;
  isLoading: boolean;
  onSubmit(data: CityRequest): void;
};

const CityForm = ({ isLoading, onSubmit, data }: Props): JSX.Element => {
  const translations = useTranslations();
  const { language: languageFromContext } = useLocalizationContext();
  const { data: paginatedCountries } = useCountries();
  const validationSchema = useCityFormValidationSchema();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<CityFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { translationKey: translations('cities_form_translation_key_prefix') },
  });

  React.useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onFormSubmit = handleSubmit(({ ...form }: CityFormValues) => {
    onSubmit({ translationKey: form.translationKey, countryId: form.country.id });
  });

  return (
    <form onSubmit={onFormSubmit}>
      <FormInput
        id={`translationKey`}
        label={translations(`cities_form_translation_key`)}
        validationError={errors.translationKey ? errors.translationKey?.message : undefined}
        useFormRegisterReturn={register(`translationKey`)}
        setValue={setValue}
        labelTooltipText={translations('cities_form_translation_key_tooltip')}
      />
      {data ? (
        <FormInput
          id={`translations.${Language.En}`}
          label={translations('cities_form_name_en')}
          validationError={errors.translations ? errors.translations[Language.En]?.message : undefined}
          useFormRegisterReturn={register(`translations.${Language.En}`)}
          isDisabled={true}
          setValue={setValue}
        />
      ) : null}
      <FormGroup
        label={translations('cities_form_country')}
        inputId="country"
        validationError={(errors.country as FieldError)?.message}
      >
        <Controller
          control={control}
          name="country"
          render={({ field: { value, onChange, name } }) => (
            <Select<CountryResponse>
              name={name}
              getOptionLabel={(option) => (option as CountryResponse).translations[languageFromContext]}
              getOptionValue={(option) => (option as CountryResponse).id}
              onChange={onChange}
              value={value}
              options={paginatedCountries?.items}
            />
          )}
        />
      </FormGroup>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default CityForm;
