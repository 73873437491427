export const RESTAURANT_BILLING_INFORMATION_QUANTITY_MIN_VALUE = 0;
export const RESTAURANT_BILLING_INFORMATION_QUANTITY_STEP = 1;
export const RESTAURANT_BILLING_INFORMATION_QUANTITY_PRECISION = 0;
export const RESTAURANT_BILLING_INFORMATION_LOGICAL_INVENTORY_INCREMENTS_DEFAULT_VALUE = 20;

export const RESTAURANT_BILLING_INFORMATION_FEE_MIN_VALUE = 0;
export const RESTAURANT_BILLING_INFORMATION_FEE_STEP = 0.5;
export const RESTAURANT_BILLING_INFORMATION_FEE_PRECISION = 2;

export const RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE = '';

export const RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH = 255;
