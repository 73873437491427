import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  createReusableTip,
  deleteReusableTip,
  getReusableTip,
  getReusableTips,
  updateReusableTip,
  updateReusableTipsOrder,
} from '../api/reusable-tips';
import { DEFAULT_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { CreateReusableTipRequest } from '../modules/ReusableTips/models/create-reusable-tip-request';
import { ReusableTipResponse } from '../modules/ReusableTips/models/reusable-tip-response';
import { UpdateReusableTipRequest } from '../modules/ReusableTips/models/update-reusable-tip-request';
import { UpdateReusableTipsOrderRequest } from '../modules/ReusableTips/models/update-reusable-tips-order-request';

const CK_REUSABLE_TIPS = 'reusable-tips';

export const useReusableTips = (
  params: BaseQueryParams = DEFAULT_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<ReusableTipResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_REUSABLE_TIPS, { text: params.searchText, page: params.offset, limit: params.limit }],
    () => getReusableTips(params),
    { cacheTime: 0 },
  );
};

export const useReusableTip = (id: string): QueryObserverResult<ReusableTipResponse, AxiosError<HttpException>> => {
  return useQuery([CK_REUSABLE_TIPS, id], () => getReusableTip(id));
};

export const useCreateReusableTip = (): UseMutationResult<
  ReusableTipResponse,
  AxiosError<HttpException>,
  CreateReusableTipRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(createReusableTip, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_REUSABLE_TIPS);
    },
  });
};

export const useUpdateReusableTip = (): UseMutationResult<
  ReusableTipResponse,
  AxiosError<HttpException>,
  { id: string; request: UpdateReusableTipRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, request }: { id: string; request: UpdateReusableTipRequest }) => updateReusableTip(id, request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_REUSABLE_TIPS);
      },
    },
  );
};

export const useUpdateReusableTipsOrder = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  UpdateReusableTipsOrderRequest
> => {
  const queryClient = useQueryClient();
  return useMutation(updateReusableTipsOrder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_REUSABLE_TIPS);
    },
  });
};

export const useDeleteReusableTip = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deleteReusableTip(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_REUSABLE_TIPS);
    },
  });
};
