import React, { useEffect } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormFooter, FormGroup } from '../../../../common/components';
import CurrencySelect from '../../../../common/components/CurrencySelect/CurrencySelect';
import FormInput from '../../../../common/components/FormInput/FormInput';
import { Language } from '../../../../common/models/enums/language';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { CountryFormValues } from '../../model/country-form-values';
import { CountryRequest } from '../../model/country-request';
import { CountryResponse } from '../../model/country-response';
import { useCountryFormValidationSchema } from './hooks/use-country-form-validation-schema.hook';

type CountryFormProps = {
  isLoading: boolean;
  onSubmit(data: CountryRequest): void;
  data?: CountryResponse;
};

export const CountryForm = ({ data, isLoading, onSubmit }: CountryFormProps) => {
  const translations = useTranslations();
  const validationSchema = useCountryFormValidationSchema();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
  } = useForm<CountryFormValues>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onFormSubmit = handleSubmit(({ ...form }: CountryFormValues) => {
    onSubmit({ translationKey: form.translationKey, countryCode: form.countryCode, currency: form.currency });
  });

  return (
    <form onSubmit={onFormSubmit}>
      <FormInput
        id={`translationKey`}
        label={translations(`countries_form_translation_key`)}
        validationError={errors.translationKey ? errors.translationKey?.message : undefined}
        useFormRegisterReturn={register(`translationKey`)}
        setValue={setValue}
        labelTooltipText={translations('countries_form_translation_key_tooltip')}
      />
      {data ? (
        <FormInput
          id={`translations.${Language.En}`}
          label={translations('cities_form_name_en')}
          validationError={errors.translations ? errors.translations[Language.En]?.message : undefined}
          useFormRegisterReturn={register(`translations.${Language.En}`)}
          isDisabled={true}
          setValue={setValue}
        />
      ) : null}
      <FormGroup
        label={translations('countries_form_currency')}
        inputId="currency"
        validationError={(errors.currency as FieldError)?.message}
      >
        <Controller
          control={control}
          name="currency"
          render={({ field: { value, onChange, name } }) => (
            <CurrencySelect
              selectedCurrency={value}
              name={name}
              onSelect={onChange}
              inputId={'currency'}
              isDisabled={!!data}
            />
          )}
        />
      </FormGroup>
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};
