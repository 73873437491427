import { LossContractType } from '../../../models/enums/loss-contract-type.enum';

export const mapLossContractTypeToSelectValueTranslationKey = (lossContractType: LossContractType) => {
  switch (lossContractType) {
    case LossContractType.NONE:
      return 'restaurant_billing_information_loss_contract_type_select_none_label';
    case LossContractType.ONE_PERCENT_LOSS_COVERAGE:
      return 'restaurant_billing_information_loss_contract_type_select_percentage_coverage_label';
    case LossContractType.STOCK_LOSS:
      return 'restaurant_billing_information_loss_contract_type_select_stock_loss_label';
    default: {
      const exhaustiveCheck: never = lossContractType;
      throw new Error(exhaustiveCheck);
    }
  }
};
