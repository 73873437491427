import React, { JSX, useEffect, useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Box, Button, FormControl, FormLabel, Image, Radio, RadioGroup, Stack, Text, Textarea } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages, FileInput, FormFooter, MainPageContent, PageHeading } from 'common/components';
import { Language } from 'common/utils';
import { config } from 'config/config';
import { useTranslations } from 'contexts/LocalizationContext';
import { useApplicationUsersList } from 'services/application-users';
import useSendNotifications from 'services/notifications';

import FormInput from '../../common/components/FormInput/FormInput';
import { ImagePreviewValidation } from '../../common/components/ImagePreviewValidation/ImagePreviewValidation';
import { ApplicationUser } from '../ApplicationUsers/models/application-user';
import {
  EmailInputType,
  NotificationReceiversType,
  NotificationsForm,
  NotificationType,
  OtherNotificationIgnoreSettingType,
} from './Notifications.model';
import mapNotificationsDataForRequest from './SendNotifications.utils';
import useSendNotificationValidation from './useSendNotificationValidation.hook';

const SendNotifications = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = useState('');
  const validationSchema = useSendNotificationValidation();

  const {
    reset: resetFormValues,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    // TODO: According to react-hook-form release note, you don't have to provide the type when using the useForm function
    //  because it automatically infers the types from the Yup schema
    //  https://github.com/react-hook-form/resolvers/releases/tag/v3.1.1
  } = useForm<NotificationsForm>({
    defaultValues: {
      notificationReceiversType: NotificationReceiversType.SELECTED_USERS,
      notificationType: NotificationType.DEFAULT,
      otherNotificationIgnoreSettingType: OtherNotificationIgnoreSettingType.ACTIVATED_USERS,
      emailInputType: EmailInputType.TEXT,
    },
    resolver: yupResolver(validationSchema),
  });

  const watchNotificationReceiversType = watch('notificationReceiversType');
  const watchNotificationType = watch('notificationType');
  const watchEmailInputType = watch('emailInputType');

  const {
    data: users,
    isFetching: isFetchingApplicationUsers,
    error: fetchingApplicationUsersError,
  } = useApplicationUsersList({
    searchText,
    limit: config.paginationSize,
    offset: 1,
  });
  const {
    mutate: sendNotifications,
    isLoading: isSendingNotifications,
    isSuccess: isSendingNotificationsSuccess,
    error: sendNotificationsError,
    reset: resetSendNotificationsQuery,
  } = useSendNotifications();

  const imageNamePreview = watch('temporaryImage')?.[0]?.name;
  const imageSizePreview = watch('temporaryImage')?.[0]?.size;

  const handleDeleteUploadedImage = () => setValue('temporaryImage', undefined);

  useEffect(() => {
    if (isSendingNotificationsSuccess) {
      window.alert(translations('notification_send_success'));
      resetFormValues();
      resetSendNotificationsQuery();
    }
  }, [isSendingNotificationsSuccess, translations, resetFormValues, resetSendNotificationsQuery, getValues]);

  useEffect(() => {
    if (sendNotificationsError) {
      if (sendNotificationsError.response?.status === 504) {
        window.alert(`${translations('notification_send_timeout')}`);
        resetFormValues();
      } else {
        window.alert(`${translations('notification_send_error')} (${sendNotificationsError})`);
      }
      resetSendNotificationsQuery();
    }
  }, [sendNotificationsError, translations, resetFormValues, resetSendNotificationsQuery]);

  const onFormSubmit = (values: NotificationsForm) => {
    if (values.notificationReceiversType === NotificationReceiversType.ALL_USERS) {
      const confirmed = window.confirm(translations('notification_sent_to_all_confirmation'));
      if (!confirmed) {
        return;
      }
    }

    const mappedData = mapNotificationsDataForRequest(values);
    sendNotifications({
      values: mappedData,
      ignoreSetting: OtherNotificationIgnoreSettingType.ALL_USERS === values.otherNotificationIgnoreSettingType,
    });
  };
  return (
    <MainPageContent>
      <PageHeading>{translations('notifications_header')}</PageHeading>
      <ErrorMessages errors={fetchingApplicationUsersError} />
      <Box mt={10}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormControl>
            <FormLabel>{translations('notification_send_type_label')}</FormLabel>
            <Controller
              control={control}
              name="notificationReceiversType"
              render={({ field: { value, onChange } }) => (
                <RadioGroup onChange={onChange} value={value}>
                  <Stack direction="row">
                    <Radio value={NotificationReceiversType.ALL_USERS}>
                      {translations('notification_all_users_radio_option')}
                    </Radio>
                    <Radio value={NotificationReceiversType.SELECTED_USERS}>
                      {translations('notification_selected_users_radio_option')}
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
          <Box mt={5} textAlign="left">
            <FormControl>
              <FormLabel>{translations('notification_type_label')}</FormLabel>
              <Controller
                control={control}
                name="notificationType"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup onChange={onChange} value={value}>
                    <Stack direction="row">
                      <Radio value={NotificationType.DEFAULT}>
                        {translations('notification_type_default_radio_option')}
                      </Radio>
                      <Radio value={NotificationType.IN_APP}>
                        {translations('notification_type_in_app_radio_option')}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Box>
          <Box mt={5} textAlign="left">
            <FormControl>
              <Controller
                control={control}
                name="otherNotificationIgnoreSettingType"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup onChange={onChange} value={value}>
                    <Stack direction="row">
                      <Radio value={OtherNotificationIgnoreSettingType.ACTIVATED_USERS}>
                        {translations('notification_other_type_activated_radio_option')}
                      </Radio>
                      <Radio value={OtherNotificationIgnoreSettingType.ALL_USERS}>
                        {translations('notification_other_type_all_radio_option')}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Box>
          {watchNotificationReceiversType === NotificationReceiversType.SELECTED_USERS && (
            <Box mt={10}>
              <FormControl>
                <FormLabel>{translations('notification_email_input_type')}</FormLabel>
                <Controller
                  control={control}
                  name="emailInputType"
                  render={({ field: { value, onChange } }) => (
                    <RadioGroup onChange={onChange} value={value}>
                      <Stack direction="column">
                        <Radio value={EmailInputType.TEXT}>{translations('notification_email_input_text')}</Radio>
                        <Radio value={EmailInputType.SELECT}>{translations('notification_email_input_select')}</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
                <Text color="red" textAlign="left">
                  {errors.emailInputType?.message}
                </Text>
              </FormControl>
              <Box mt={5}>
                <FormControl>
                  {watchEmailInputType === EmailInputType.TEXT && (
                    <>
                      <Textarea
                        {...register('inputMailList')}
                        placeholder={translations('notification_email_input_placeholder')}
                      />
                      <Text color="red" textAlign="left">
                        {errors.inputMailList?.message}
                      </Text>
                    </>
                  )}
                  {watchEmailInputType === EmailInputType.SELECT && (
                    <>
                      <Controller
                        control={control}
                        name="selectedUsersList"
                        render={({ field: { value, onChange, name } }) => (
                          <Select
                            inputId="emailsSelect"
                            placeholder={translations('notification_email_select_placeholder')}
                            name={name}
                            isMulti
                            getOptionLabel={(option: ApplicationUser) => option.email}
                            getOptionValue={(option: ApplicationUser) => option.id}
                            value={value}
                            onChange={onChange}
                            onInputChange={setSearchText}
                            options={users?.items}
                            isLoading={isFetchingApplicationUsers}
                          />
                        )}
                      />
                      <Text color="red" textAlign="left">
                        {(errors as FieldErrors).selectedUsersList?.message as string}
                      </Text>
                    </>
                  )}
                </FormControl>
              </Box>
            </Box>
          )}
          <Box mt={10}>
            {Object.values(Language).map((language) => (
              <Box mb={3} key={language}>
                <FormInput
                  id={`title_${language}`}
                  label={`${translations('notification_title_label')} ${translations(
                    `notification_${language}_label`,
                  )}`}
                  useFormRegisterReturn={register(`title_${language}`)}
                  setValue={setValue}
                  validationError={(errors as FieldErrors)[`title_${language}`]?.message as string}
                />
                <FormInput
                  id={`body_${language}`}
                  label={`${translations('notification_body_label')} ${translations(`notification_${language}_label`)}`}
                  useFormRegisterReturn={register(`body_${language}`)}
                  setValue={setValue}
                  validationError={(errors as FieldErrors)[`body_${language}`]?.message as string}
                />
                {watchNotificationType === NotificationType.IN_APP && (
                  <>
                    <FormInput
                      id={`buttonText_${language}`}
                      label={`${translations('notification_in_app_button_text')} ${translations(
                        `notification_${language}_label`,
                      )}`}
                      useFormRegisterReturn={register(`buttonText_${language}`)}
                      setValue={setValue}
                      validationError={(errors as FieldErrors)[`buttonText_${language}`]?.message as string}
                    />
                  </>
                )}
                <FormInput
                  id={`link_${language}`}
                  label={
                    watchNotificationType === NotificationType.IN_APP
                      ? `${translations('notification_in_app_button_link_label')} ${translations(
                          `notification_${language}_label`,
                        )}`
                      : `${translations('notification_link_label')} ${translations(`notification_${language}_label`)}`
                  }
                  useFormRegisterReturn={register(`link_${language}`)}
                  setValue={setValue}
                  validationError={(errors as FieldErrors)[`link_${language}`]?.message as string}
                />
              </Box>
            ))}
            <Box mt={10}>
              {watchNotificationType === NotificationType.IN_APP && (
                <Box mt={5}>
                  <FormControl>
                    <FormLabel htmlFor="image">{translations('notification_in_app_image')}</FormLabel>
                    <Image id="image" src={getValues('image')} />
                    <FileInput accept="image/*" register={register('temporaryImage')} aria-label="temporaryImage" />
                    {imageNamePreview && imageSizePreview ? (
                      <ImagePreviewValidation
                        imageNamePreview={imageNamePreview}
                        imageSizePreview={imageSizePreview}
                        handleDeleteUploadedImage={handleDeleteUploadedImage}
                      />
                    ) : null}
                  </FormControl>
                </Box>
              )}
            </Box>
            <FormFooter hasCustomContent>
              <Button isLoading={isSendingNotifications} type="submit">
                {translations('notification_send_button')}
              </Button>
            </FormFooter>
          </Box>
        </form>
      </Box>
    </MainPageContent>
  );
};

export default SendNotifications;
