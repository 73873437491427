import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CellProps } from 'react-table';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Button, Center, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormGroup, NumberInput, Table } from '../../../../common/components';
import { convertFeeInMainUnitToMinUnit, convertFeeInMinUnitToMainUnit } from '../../../../common/utils/fee.helper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useUpdateCountryPricing } from '../../../../services/countries';
import { CategoryPricingFormValues } from '../../model/category-pricing-form-values';
import { CategoryPricingResponse } from '../../model/category-pricing-response';
import { CountryPricingResponse } from '../../model/country-pricing-response';
import { usePricingValidationSchema } from './hooks/use-pricing-validation-schema.hook';

type CountryPricingTableProps = {
  countryPricing: CountryPricingResponse;
  isLoading: boolean;
};

export const CountryPricingTable = ({ countryPricing, isLoading }: CountryPricingTableProps) => {
  const translations = useTranslations();
  const [activeRowCategoryId, setActiveRowCategoryId] = useState<string | null>(null);
  const { mutate: updateCategoryPricing } = useUpdateCountryPricing();
  const validationSchema: yup.AnyObjectSchema = usePricingValidationSchema();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CategoryPricingFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const currency = useMemo(() => countryPricing?.country.currency, [countryPricing]);

  const handleEdit = useCallback(
    (original: CategoryPricingResponse) => {
      return () => {
        reset({
          notReturnFee: convertFeeInMinUnitToMainUnit(original.notReturnFee),
          extensionFee: convertFeeInMinUnitToMainUnit(original.extensionFee),
        });
        setActiveRowCategoryId(original.category.id);
      };
    },
    [reset],
  );

  const handleCancel = () => setActiveRowCategoryId(null);

  const handleFormSubmit = handleSubmit((form) => {
    if (activeRowCategoryId) {
      updateCategoryPricing(
        {
          countryId: countryPricing.country.id,
          categoryId: activeRowCategoryId,
          values: {
            notReturnFee: convertFeeInMainUnitToMinUnit(form.notReturnFee),
            extensionFee: convertFeeInMainUnitToMinUnit(form.extensionFee),
          },
        },
        {
          onSuccess: () => handleCancel(),
        },
      );
    }
  });

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('countries_pricing_table_category_name'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<CategoryPricingResponse>) => original.category.translations.en,
      },
      {
        id: '2',
        Header: translations('countries_pricing_table_not_return_fee'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<CategoryPricingResponse>) => {
          if (activeRowCategoryId === original.category.id) {
            return (
              <Center>
                <FormGroup label={''} inputId="notReturnFee" validationError={errors.notReturnFee?.message}>
                  <NumberInput
                    w={110}
                    name="notReturnFee"
                    color={'grey.900'}
                    register={register('notReturnFee')}
                    defaultValue={convertFeeInMinUnitToMainUnit(original.notReturnFee)}
                    onChange={setValue}
                  />
                </FormGroup>
              </Center>
            );
          }
          return convertFeeInMinUnitToMainUnit(original.notReturnFee).toFixed(2);
        },
      },
      {
        id: '3',
        Header: translations('countries_pricing_table_extension_fee'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<CategoryPricingResponse>) => {
          if (activeRowCategoryId === original.category.id) {
            return (
              <Center>
                <FormGroup label={''} inputId="extensionFee" validationError={errors.extensionFee?.message}>
                  <NumberInput
                    w={110}
                    name={'extensionFee'}
                    color={'grey.900'}
                    register={register('extensionFee')}
                    onChange={setValue}
                  />
                </FormGroup>
              </Center>
            );
          }
          return convertFeeInMinUnitToMainUnit(original.extensionFee).toFixed(2);
        },
      },
      {
        id: '4',
        Header: translations('countries_table_currency'),
        Cell: () => currency,
      },
      {
        id: 'actions',
        Header: translations('countries_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<CategoryPricingResponse>) => {
          if (activeRowCategoryId === original.category.id) {
            return (
              <Flex w={100}>
                <Button mr={2} type={'submit'}>
                  <CheckIcon cursor="pointer" color={'green.500'} />
                </Button>
                <Button onClick={handleCancel}>
                  <CloseIcon cursor="pointer" color={'red.500'} w={3} />
                </Button>
              </Flex>
            );
          }
          return (
            <Flex w={100}>
              <Button isDisabled={!!activeRowCategoryId} leftIcon={<EditIcon />} onClick={handleEdit(original)}>
                {translations('edit')}
              </Button>
            </Flex>
          );
        },
      },
    ],
    [errors, activeRowCategoryId, currency, register, handleEdit, setValue, translations],
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <Table data={countryPricing.pricing} columns={columns} isLoading={isLoading} />
    </form>
  );
};
