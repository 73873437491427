import React, { ChangeEvent, JSX, useCallback, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, Flex, Tooltip, useTheme } from '@chakra-ui/react';
import {
  AddEditItemLink,
  BooleanValueIcon,
  ErrorMessages,
  LinkButton,
  MainPageContent,
  PageHeading,
  Table,
} from 'common/components';
import { DayOfWeek } from 'common/models';
import { formatOpeningHoursPeriods } from 'common/utils';
import { config } from 'config/config';
import { Routes } from 'config/routes';
import { useTranslations } from 'contexts/LocalizationContext';
import { useRestaurantList, useRestaurantOpeningHours } from 'services/restaurants';

import { useDebounce } from '../../common/hooks/useDebounce';
import { useHandleTablePageCheckbox } from '../../common/hooks/useHandleTablePageCheckbox';
import { useHandleTableRowCheckbox } from '../../common/hooks/useHandleTableRowCheckbox';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { RestaurantFilterMode } from './models/enums/restaurant-filter-mode.enum';
import { RestaurantStatusUpdate } from './RestaurantStatusUpdate/RestaurantStatusUpdate';
import { RestaurantOpeningHoursResponse, RestaurantResponse } from './models';

const Restaurants = (): JSX.Element => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const [selectedRestaurantsIds, setSelectedRestaurantsIds] = useState<string[]>([]);
  const { colors } = useTheme();

  const {
    data: paginatedRestaurants,
    isFetching,
    error: loadRestaurantListError,
  } = useRestaurantList({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
    filterMode: RestaurantFilterMode.TABLE,
  });
  const { handlePageCheckbox } = useHandleTablePageCheckbox(
    selectedRestaurantsIds,
    setSelectedRestaurantsIds,
    paginatedRestaurants?.items.map((item) => item.id),
  );

  const { handleCheckbox } = useHandleTableRowCheckbox(selectedRestaurantsIds, setSelectedRestaurantsIds);
  const { mutate: getOpeningHours, error: openingHoursError } = useRestaurantOpeningHours();

  const getIsChecked = useCallback(
    (original: RestaurantResponse) => selectedRestaurantsIds.some((id) => id === original.id),
    [selectedRestaurantsIds],
  );

  const getIsSelectPageChecked = useCallback(
    (pageItems: RestaurantResponse[] | undefined) =>
      pageItems?.every((item) => selectedRestaurantsIds.includes(item.id)),
    [selectedRestaurantsIds],
  );

  const handleSelectCheckbox = useCallback(
    (original: RestaurantResponse) => (e: ChangeEvent<HTMLInputElement>) => handleCheckbox(original.id, e),
    [handleCheckbox],
  );

  const handleOpeningHours = useCallback(
    (original: RestaurantResponse) => () => getOpeningHours({ id: original.id }),
    [getOpeningHours],
  );

  const columns = useMemo(
    () => [
      {
        id: '1',
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantResponse>) => (
          <Checkbox
            name={original.id}
            key={original.id}
            size="sm"
            colorScheme="orange"
            isChecked={getIsChecked(original)}
            onChange={handleSelectCheckbox(original)}
          />
        ),
        Header: (
          <Tooltip
            label={
              !getIsSelectPageChecked(paginatedRestaurants?.items)
                ? translations('table_select_page')
                : translations('table_unselect_all')
            }
          >
            <Box>
              <Checkbox
                name="page"
                key="page"
                size="sm"
                colorScheme="orange"
                isChecked={getIsSelectPageChecked(paginatedRestaurants?.items)}
                onChange={handlePageCheckbox}
              />
            </Box>
          </Tooltip>
        ),
      },
      {
        id: '2',
        Header: translations('restaurants_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '3',
        Header: translations('restaurants_table_name'),
        accessor: 'restaurantName' as const,
      },
      {
        id: '4',
        Header: translations('restaurants_table_open_time'),
        accessor: 'restaurantOpeningHours' as const,
        Cell: ({ cell: { value } }: { cell: { value: RestaurantOpeningHoursResponse[] | null } }) => {
          const tooltipLabel = value
            ?.map((data) => `${DayOfWeek[data.day]}: ${formatOpeningHoursPeriods(data.periods)}`)
            .join('\n');

          return tooltipLabel ? (
            <Tooltip label={tooltipLabel} fontSize="md">
              <InfoOutlineIcon />
            </Tooltip>
          ) : null;
        },
      },
      {
        id: '5',
        Header: translations('restaurants_table_status'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantResponse>) => <RestaurantStatusUpdate status={original.status} id={original.id} />,
      },
      {
        id: '6',
        Header: translations('restaurants_table_address'),
        accessor: 'restaurantAddress' as const,
      },
      {
        id: '7',
        Header: translations('restaurants_table_two_factor_return'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantResponse>) => <BooleanValueIcon value={!!original.isTwoFactorReturnEnabled} />,
      },
      {
        id: '8',
        Header: translations('restaurants_table_deposit'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantResponse>) => <BooleanValueIcon value={!!original.isDepositEnabled} />,
      },
      {
        id: 'actions',
        Header: translations('restaurants_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantResponse>) => (
          <Flex wrap="wrap">
            <AddEditItemLink to={`${Routes.RESTAURANTS_PATH}/${original.id}`} m={1} isEdit />
            {original.googlePlaceId && (
              <Button m={1} onClick={handleOpeningHours(original)} color={colors.orange[500]}>
                {translations('update_google_info')}
              </Button>
            )}
            <LinkButton
              m={1}
              to={generatePath(Routes.RESTAURANT_IMAGES_PATH, { restaurantId: original.id })}
              color={colors.orange[500]}
            >
              {translations('images')}
            </LinkButton>
            <LinkButton
              m={1}
              to={generatePath(Routes.RESTAURANT_RETURN_CODES_PATH, { restaurantId: original.id })}
              color={colors.orange[500]}
            >
              {translations('return_codes')}
            </LinkButton>
            <LinkButton
              m={1}
              to={generatePath(Routes.RESTAURANT_TWO_FACTOR_RETURN_PATH, { restaurantId: original.id })}
              color={colors.orange[500]}
            >
              {translations('two_factor_return')}
            </LinkButton>
            <LinkButton
              m={1}
              to={generatePath(Routes.RESTAURANT_DEPOSIT_PATH, { restaurantId: original.id })}
              color={colors.orange[500]}
            >
              {translations('deposit')}
            </LinkButton>
            <LinkButton
              m={1}
              to={generatePath(Routes.RESTAURANT_INVENTORY_COUNTS_PATH, { restaurantId: original.id })}
              color={colors.orange[500]}
            >
              {translations('inventory_counts')}
            </LinkButton>
            <LinkButton
              m={1}
              to={generatePath(Routes.RESTAURANT_BILLING_INFORMATION_PATH, { restaurantId: original.id })}
              color={colors.orange[500]}
            >
              {translations('restaurant_billing_information')}
            </LinkButton>
          </Flex>
        ),
      },
    ],
    [
      paginatedRestaurants,
      handleSelectCheckbox,
      handlePageCheckbox,
      getIsChecked,
      getIsSelectPageChecked,
      translations,
      colors,
      handleOpeningHours,
    ],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('restaurants_header')}</PageHeading>
      <ErrorMessages errors={[loadRestaurantListError, openingHoursError]} />
      <Flex justifyContent="flex-end">
        <AddEditItemLink to={Routes.CREATE_RESTAURANT_PATH}>{translations('restaurants_add')}</AddEditItemLink>
        <LinkButton
          state={selectedRestaurantsIds}
          to={Routes.MASS_EDIT_RESTAURANTS_PATH}
          ml={2}
          isDisabled={!selectedRestaurantsIds.length}
        >
          {translations('restaurants_mass_edit', { '{{number}}': selectedRestaurantsIds.length.toString() })}
        </LinkButton>
      </Flex>
      <Table<RestaurantResponse>
        isLoading={isFetching}
        data={paginatedRestaurants?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedRestaurants?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('restaurants_searchbox')}
        searchEnabled
        paginationEnabled
      />
    </MainPageContent>
  );
};

export default Restaurants;
