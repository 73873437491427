import React, { JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'config/routes';
import invariant from 'tiny-invariant';

import { ErrorMessages } from '../../common/components';
import { ChildPageHeading } from '../../common/components/ChildPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useMetaCategory, useUpdateMetaCategory } from '../../services/meta-categories';
import { MetaCategory, MetaCategoryFormValues } from './model/MetaCategory';
import { getParsedData } from './utils/metaCategory.helper';
import MetaCategoriesForm from './MetaCategoriesForm';

const UpdateMetaCategory = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const translations = useTranslations();
  const { isLoading: isMetaCategoriesFetching, data: metaCategory } = useMetaCategory(id);
  const {
    isLoading: isMetaCategoriesUpdate,
    mutate: updateMetaCategory,
    error: updateMetaCategoryError,
  } = useUpdateMetaCategory();

  const formData = metaCategory
    ? ({
        translationKey: metaCategory?.translationKey,
        translations: metaCategory?.translations,
        color: metaCategory?.color,
        type: metaCategory?.type,
        image: metaCategory?.image,
      } as MetaCategory)
    : undefined;

  const onMetaCategorySubmit = (values: MetaCategoryFormValues) => {
    if (!metaCategory?.id) {
      return;
    }
    const parsedData = getParsedData(values);
    updateMetaCategory(
      { values: parsedData, id: metaCategory.id },
      {
        onSuccess: () => navigate(Routes.META_CATEGORIES_PATH),
      },
    );
  };

  return (
    <>
      <ChildPageHeading>{translations('meta_categories_update_header')}</ChildPageHeading>
      <ErrorMessages errors={updateMetaCategoryError} />
      <MetaCategoriesForm
        formData={formData}
        isLoading={isMetaCategoriesFetching || isMetaCategoriesUpdate}
        onSubmit={onMetaCategorySubmit}
      />
    </>
  );
};

export default UpdateMetaCategory;
