export enum Language {
  En = 'en',
  De = 'de',
  Fr = 'fr',
  Nl = 'nl',
  Se = 'se',
  Dk = 'dk',
  Fi = 'fi',
  No = 'no',
  Pl = 'pl',
  It = 'it',
}

export const REQUIRED_TRANSLATIONS: string[] = [Language.En, Language.De];
export const OPTIONAL_TRANSLATIONS: string[] = Object.values(Language).filter(
  (language) => !REQUIRED_TRANSLATIONS.includes(language),
);

export const FOREIGN_LANGUAGES = Object.values(Language).filter((language) => language !== Language.En);
