import React, { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Button, Flex, Image, useDisclosure } from '@chakra-ui/react';

import { AddEditItemLink, ErrorMessages, MainPageContent, PageHeading, Table } from '../../common/components';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../common/constants/common';
import { useDebounce } from '../../common/hooks/useDebounce';
import { Language, useExecuteWithConfirmation } from '../../common/utils';
import { config } from '../../config/config';
import { DEBOUNCE_SEARCH_TIME } from '../../config/configConsts';
import { Routes } from '../../config/routes';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useDeleteReusableTip, useReusableTips } from '../../services/reusable-tips';
import { UpdateReusableTipsOrderModal } from './components/UpdateReusableTipsOrderModal/UpdateReusableTipsOrderModal';
import { ReusableTipResponse } from './models/reusable-tip-response';

export const ReusableTips = () => {
  const translations = useTranslations();
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearch = useDebounce(searchText, DEBOUNCE_SEARCH_TIME);
  const [page, setPage] = React.useState(1);
  const {
    isOpen: isReorderModalOpen,
    onOpen: handleOpenReorderModal,
    onClose: handleCloseReorderModal,
  } = useDisclosure();
  const {
    data: paginatedReusableTips,
    isFetching: isFetchingReusableTips,
    error: loadReusableTipsError,
  } = useReusableTips({
    searchText: debouncedSearch,
    limit: config.paginationSize,
    offset: page,
  });
  const { data: allReusableTips } = useReusableTips(ALL_PAGINATED_LIST_ITEMS_PARAMS);
  const { mutate: deleteReusableTip, isLoading: isDeletingReusableTip, error: deleteError } = useDeleteReusableTip();
  const { executeWithConfirmation: onDeleteReusableTip } = useExecuteWithConfirmation(
    translations('reusable_tips_delete_confirmation'),
    deleteReusableTip,
  );

  const handleDeleteReusableTip = useCallback(
    (original: ReusableTipResponse) => {
      return () => onDeleteReusableTip(original.id);
    },
    [onDeleteReusableTip],
  );

  const columns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('reusable_tips_table_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('reusable_tips_table_title_translation_key'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReusableTipResponse>) => <>{original.title.translationKey}</>,
      },
      {
        id: '3',
        Header: translations('reusable_tips_table_title_en'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReusableTipResponse>) => <>{original.title.translations[Language.En]}</>,
      },
      {
        id: '4',
        Header: translations('reusable_tips_table_image'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReusableTipResponse>) => <Image width="200px" src={original.imageUrl || ''} />,
      },
      {
        id: 'actions',
        Header: translations('reusable_tips_table_actions'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<ReusableTipResponse>) => (
          <Flex>
            <AddEditItemLink to={generatePath(Routes.UPDATE_REUSABLE_TIP_PATH, { id: original.id })} m={1} isEdit />
            <Button m={1} onClick={handleDeleteReusableTip(original)}>
              {translations('delete')}
            </Button>
          </Flex>
        ),
      },
    ],
    [translations, handleDeleteReusableTip],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('reusable_tips_header')}</PageHeading>
      <ErrorMessages errors={[loadReusableTipsError, deleteError]} />
      <Flex justifyContent="flex-end">
        <Button onClick={handleOpenReorderModal} mr={1}>
          {translations('reusable_tips_reorder')}
        </Button>
        <AddEditItemLink to={Routes.CREATE_REUSABLE_TIP_PATH}>{translations('reusable_tips_add')}</AddEditItemLink>
      </Flex>
      <Table<ReusableTipResponse>
        isLoading={isFetchingReusableTips || isDeletingReusableTip}
        data={paginatedReusableTips?.items}
        columns={columns}
        page={page}
        onPageChanged={setPage}
        totalPages={paginatedReusableTips?.totalPages}
        searchText={searchText}
        onSearchTextChanged={setSearchText}
        searchboxPlaceholder={translations('reusable_tips_search_box')}
        searchEnabled
        paginationEnabled
      />
      {allReusableTips?.items ? (
        <UpdateReusableTipsOrderModal
          data={allReusableTips.items}
          isOpen={isReorderModalOpen}
          onCancel={handleCloseReorderModal}
        />
      ) : null}
    </MainPageContent>
  );
};
