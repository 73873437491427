import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDisclosure } from '@chakra-ui/react';

import { extractUId } from '../../../../common/utils/qrCodes.helper';
import { readBlob } from '../../../../common/utils/readBlob.helper';
import { useMarkItemsAsLost, useUndoMarkItemsAsLost } from '../../../../services/lost-items';
import { LostItemsFromCsvFormType } from '../../components/LostItemsForm/LostItems.form';
import { LostItemsFormActionType } from '../../model/enums/lost-items-form-action-type.enum';
import { LostItemsResult } from '../../model/lost-items-result';

export const useLostItemsHook = (methods: UseFormReturn<LostItemsFromCsvFormType>) => {
  const [items, setItems] = useState<LostItemsResult>();
  const watchLostActionType = methods.watch('action');
  const { isOpen: isSummaryModalOpen, onOpen: onOpenSummaryModal, onClose: onCloseSummaryModal } = useDisclosure();

  const { mutate: markLostItems, isLoading: isMarkLostItemsInProgress } = useMarkItemsAsLost({
    onSuccessfulMark: (data) => {
      const result: LostItemsResult = {
        successItems: data.lostItems,
        failureItems: data.notLostItems,
        successInventoryCounts: data.finishedInventoryCounts,
        failureInventoryCounts: data.notFinishedInventoryCounts,
      };
      setItems(result);
      onOpenSummaryModal();
    },
  });
  const { mutate: undoMarkLostItems, isLoading: isUndoMarkLostItemsInProgress } = useUndoMarkItemsAsLost({
    onSuccessfulMark: (data) => {
      const result: LostItemsResult = { successItems: data.activeItems, failureItems: data.notActiveItems };
      setItems(result);
      onOpenSummaryModal();
    },
  });

  const handleCloseSummaryModal = () => {
    onCloseSummaryModal();
    methods.reset();
  };

  const handleFormSubmit = methods.handleSubmit(async (formValues: LostItemsFromCsvFormType) => {
    let productUIds = (await readBlob(formValues.csvFile![0]))?.split(/[\r\n]+/);
    productUIds = productUIds?.map((qrCode) => extractUId(qrCode) ?? '');
    if (!productUIds) {
      throw new Error('ProductUIds and restaurantId should not be empty, check form validation');
    }
    productUIds = productUIds.filter((uId) => uId !== '' && uId.length >= 20);
    if (watchLostActionType === LostItemsFormActionType.MARK_AS_LOST) {
      markLostItems({
        items: productUIds,
        inventoryCountsIds: formValues.inventoryCounts.map((inventoryCount) => inventoryCount.id),
      });
    } else if (watchLostActionType === LostItemsFormActionType.MARK_AS_ACTIVE) {
      undoMarkLostItems({ items: productUIds });
    }
  });

  return {
    items,
    isSummaryModalOpen,
    handleCloseSummaryModal,
    isSubmitFormLoading:
      watchLostActionType === LostItemsFormActionType.MARK_AS_LOST
        ? isMarkLostItemsInProgress
        : isUndoMarkLostItemsInProgress,
    handleFormSubmit,
  };
};
