import { generatePath } from 'react-router-dom';

import apiPaths from '../config/apiPaths';
import { RestaurantBillingInformationResponse } from '../modules/Restaurants/RestaurantBillingInformation/models/billing-information-response';
import { RestaurantBillingInformationUpdateRequest } from '../modules/Restaurants/RestaurantBillingInformation/models/restaurant-billing-information-update-request';
import axiosInstance from './axios';

export const getRestaurantBillingInformation = async (
  restaurantId: string,
): Promise<RestaurantBillingInformationResponse> => {
  const { data } = await axiosInstance.get(generatePath(apiPaths.RESTAURANTS_BILLING_INFORMATION, { restaurantId }));
  return data;
};

export const updateRestaurantBillingInformation = (
  restaurantId: string,
  updateRequest: RestaurantBillingInformationUpdateRequest,
): Promise<RestaurantBillingInformationResponse> => {
  return axiosInstance.put(generatePath(apiPaths.RESTAURANTS_BILLING_INFORMATION, { restaurantId }), updateRequest);
};
