import { LanguagesConfig, MetaCategory } from '../../MetaCategories/model/MetaCategory';

export enum CategoryGroup {
  CUPS = 'CUPS',
  BOWLS = 'BOWLS',
  PIZZA = 'PIZZA',
}

export interface Category {
  id: string;
  translationKey: string;
  translations: LanguagesConfig;
  categoryGroup: CategoryGroup | null;
  borrowingPeriod: number;
  extensionPeriod: number;
  metaCategory: MetaCategory | null;
  image?: string;
}

export interface CategoryRequest {
  translationKey: string;
  categoryGroup: CategoryGroup | null;
  borrowingPeriod: number;
  extensionPeriod: number;
  metaCategoryId: string;
  image?: File;
}
