import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { ErrorMessages, FullPageLoader } from '../../../../common/components';
import { ChildPageHeading } from '../../../../common/components/ChildPageHeading';
import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useCountry, useUpdateCountry } from '../../../../services/countries';
import { CountryForm } from '../CountryForm/CountryForm';

export const UpdateCountry = () => {
  const translations = useTranslations();
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const navigate = useNavigate();
  const { mutate: updateCountry, isLoading: isLoadingCountry, error: updateCountryError } = useUpdateCountry();
  const { data: country, isFetching: isUpdatingCountry, error: loadCountryError } = useCountry(id);

  return (
    <FullPageLoader show={isUpdatingCountry || isLoadingCountry}>
      <ChildPageHeading>{translations('countries_update_country_header')}</ChildPageHeading>
      <ErrorMessages errors={[loadCountryError, updateCountryError]} />
      <CountryForm
        isLoading={isLoadingCountry || isUpdatingCountry}
        onSubmit={(values) =>
          updateCountry(
            { id, values },
            {
              onSuccess: () => navigate(Routes.COUNTRIES_PATH),
            },
          )
        }
        data={country}
      />
    </FullPageLoader>
  );
};
