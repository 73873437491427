import React, { useState } from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Button, Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { DragDropContext, Draggable, DragUpdate, Droppable } from '@hello-pangea/dnd';

import { Modal } from '../../../../common/components';
import { reorder } from '../../../../common/components/DragAndDrop/helpers';
import { Language } from '../../../../common/models/enums/language';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useUpdateReusableTipsOrder } from '../../../../services/reusable-tips';
import { ReusableTipResponse } from '../../models/reusable-tip-response';

type UpdateReusableTipsOrderModalProps = {
  data: ReusableTipResponse[];
  isOpen: boolean;
  onCancel: () => void;
};

export const UpdateReusableTipsOrderModal = ({ data, isOpen, onCancel }: UpdateReusableTipsOrderModalProps) => {
  const [dndList, setDndList] = useState(data);
  const translations = useTranslations();
  const { mutate: updateReusableTipsOrder, isLoading: isUpdatingOrder } = useUpdateReusableTipsOrder();

  const onDragEnd = (result: DragUpdate) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(dndList, result.source.index, result.destination.index);
    setDndList(items);
  };

  const handleReset = () => setDndList(data);
  const handleCancel = () => {
    handleReset();
    onCancel();
  };
  const handleSave = () => {
    updateReusableTipsOrder({ order: dndList.map((item) => item.id) }, { onSuccess: () => onCancel() });
  };

  return (
    <Modal
      modalHeader={translations('reusable_tips_reorder_modal_header')}
      size="xl"
      isModalVisible={isOpen}
      onModalClose={handleCancel}
      customModalFooter={
        <Flex justifyContent="flex-end" p={2}>
          <Button colorScheme="orange" onClick={handleSave} mr={2} isLoading={isUpdatingOrder}>
            {translations('save')}
          </Button>
          <Button onClick={handleReset} mr={2}>
            {translations('reset')}
          </Button>
          <Button onClick={handleCancel}>{translations('cancel')}</Button>
        </Flex>
      }
    >
      <Flex bg={'orange.100'} p={2} mb={2}>
        <Text>
          <InfoOutlineIcon w={6} />
          {translations('reusable_tips_reorder_modal_explanation')}
        </Text>
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {dndList.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(draggableProvided) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      data-testid={`item-${index}`}
                    >
                      <Card my={1}>
                        <CardBody>{item.title.translations[Language.En]}</CardBody>
                      </Card>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
};
