import React, { ComponentProps, useCallback, useMemo } from 'react';
import Select, { SingleValue } from 'react-select';

import { SelectOption } from '../../../../../common/components/Select/models/select-option';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { LossContractType } from '../../models/enums/loss-contract-type.enum';
import { mapLossContractTypeToSelectValueTranslationKey } from './utils/loss-contract-type.helper';

type LossContractTypeSelectProps = Omit<
  ComponentProps<typeof Select>,
  'options' | 'isLoading' | 'getOptionLabel' | 'getOptionValue' | 'placeholder' | 'onChange'
> & {
  selectedValue: LossContractType | null;
  onSelect: (value: unknown) => void;
};

export const LossContractTypeSelect = ({
  isDisabled,
  name,
  selectedValue,
  onSelect,
  inputId,
}: LossContractTypeSelectProps) => {
  const translations = useTranslations();
  const contractTypeSelectOptions = useMemo(
    () =>
      Object.values(LossContractType).map((lossContractType) => ({
        value: lossContractType,
        label: translations(mapLossContractTypeToSelectValueTranslationKey(lossContractType)),
      })),
    [translations],
  );
  const handleChange = useCallback((v: SingleValue<SelectOption>) => onSelect(v?.value), [onSelect]);
  const handleOptionLabel = useCallback((option: SelectOption) => option.label, []);
  const handleOptionValue = useCallback((option: SelectOption) => option.value, []);

  return (
    <Select<SelectOption>
      inputId={inputId}
      name={name}
      value={contractTypeSelectOptions.find((option) => option.value === selectedValue)}
      onChange={handleChange}
      options={contractTypeSelectOptions}
      isDisabled={isDisabled}
      getOptionLabel={handleOptionLabel}
      getOptionValue={handleOptionValue}
    />
  );
};
