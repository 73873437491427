import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

import { AccountPriority } from '../../../common/models/enums/accountPriority';
import { isValidStringUrl } from '../../../common/utils/url.helper';
import { RestaurantDetailsResponse } from '../models';
import {
  INDIVIDUAL_KEY_ACCOUNT_ID,
  MAX_COMMON_LENGTH,
  MAX_LATITUDE,
  MAX_LONGITUDE,
  MAX_RESTAURANT_ADDRESS_LENGTH,
  MAX_RESTAURANT_ANDROID_LINK_LENGTH,
  MAX_RESTAURANT_DETAIL_LENGTH,
  MAX_RESTAURANT_GOOGLE_PLACE_ID_LENGTH,
  MAX_RESTAURANT_NAME_LENGTH,
  MAX_RESTAURANT_PHONE_LENGTH,
  MAX_RESTAURANT_STREET_NAME_LENGTH,
  MAX_RESTAURANT_WHOLESALE_CUSTOMER_ID_LENGTH,
  MIN_LATITUDE,
  MIN_LONGITUDE,
} from '../restaurant.consts';

export enum RestaurantStatus {
  LIVE_VISIBLE = 'LIVE_VISIBLE',
  LIVE_INVISIBLE = 'LIVE_INVISIBLE',
  COMING_SOON_INVISIBLE = 'COMING_SOON_INVISIBLE',
  COMING_SOON_VISIBLE = 'COMING_SOON_VISIBLE',
  LOST = 'LOST',
  DELETED = 'DELETED',
}

export enum RestaurantStatusNames {
  LIVE_VISIBLE = 'Live visible',
  LIVE_INVISIBLE = 'Live invisible',
  COMING_SOON_INVISIBLE = 'Coming soon invisible',
  COMING_SOON_VISIBLE = 'Coming soon visible',
  LOST = 'Lost',
  DELETED = 'Deleted',
}

export type StatusSelect = {
  label: RestaurantStatusNames;
  value: RestaurantStatus;
};

export const statusSelectOptions: StatusSelect[] = [
  { label: RestaurantStatusNames.LIVE_INVISIBLE, value: RestaurantStatus.LIVE_INVISIBLE },
  { label: RestaurantStatusNames.LIVE_VISIBLE, value: RestaurantStatus.LIVE_VISIBLE },
  { label: RestaurantStatusNames.COMING_SOON_INVISIBLE, value: RestaurantStatus.COMING_SOON_INVISIBLE },
  { label: RestaurantStatusNames.COMING_SOON_VISIBLE, value: RestaurantStatus.COMING_SOON_VISIBLE },
  { label: RestaurantStatusNames.LOST, value: RestaurantStatus.LOST },
  { label: RestaurantStatusNames.DELETED, value: RestaurantStatus.DELETED },
];

export const defaultRestaurantStatus = RestaurantStatus.COMING_SOON_VISIBLE;
export const restaurantDefaultValues: RestaurantDetailsResponse = {
  id: '',
  restaurantName: '',
  metaCategories: [],
  keyAccountId: INDIVIDUAL_KEY_ACCOUNT_ID,
  accountPriority: AccountPriority.STANDARD,
  status: defaultRestaurantStatus,
};

export enum StarterSetSize {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

export type StarterSetSizeSelect = {
  label: StarterSetSize;
  value: StarterSetSize;
};

export const starterSetSizeSelectOptions: StarterSetSizeSelect[] = [
  { label: StarterSetSize.S, value: StarterSetSize.S },
  { label: StarterSetSize.M, value: StarterSetSize.M },
  { label: StarterSetSize.L, value: StarterSetSize.L },
  { label: StarterSetSize.XL, value: StarterSetSize.XL },
  { label: StarterSetSize.XXL, value: StarterSetSize.XXL },
];

export const useRestaurantFormValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  const selectOptionSchema = {
    id: yup.string(),
    translations: yup.object().shape({
      en: yup.string(),
      de: yup.string(),
    }),
  };

  return yup.object().shape({
    restaurantName: yup
      .string()
      .required(translations('validation_required'))
      .max(MAX_RESTAURANT_NAME_LENGTH, translations('validation_too_long')),
    restaurantDetail: yup.string().nullable().max(MAX_RESTAURANT_DETAIL_LENGTH, translations('validation_too_long')),
    city: yup.object().nullable().shape(selectOptionSchema),
    restaurantAddress: yup.string().nullable().max(MAX_RESTAURANT_ADDRESS_LENGTH, translations('validation_too_long')),
    streetName: yup.string().nullable().max(MAX_RESTAURANT_STREET_NAME_LENGTH, translations('validation_too_long')),
    phoneNumber: yup.string().nullable().max(MAX_RESTAURANT_PHONE_LENGTH, translations('validation_too_long')),
    websiteLink: yup
      .string()
      .nullable()
      .test('test url', translations('validation_incorrect_url_format'), (text) => {
        if (text?.trim().length === 0 || !text) {
          return true;
        }
        return text.trim().length > 0 && isValidStringUrl(text.trim());
      })
      .max(MAX_COMMON_LENGTH, translations('validation_too_long')),
    deliverers: yup.object().shape({
      uberEats: yup.object().shape({
        url: yup
          .string()
          .nullable()
          .max(MAX_COMMON_LENGTH, translations('validation_too_long'))
          .test('test url', translations('validation_incorrect_url_format'), (text) => {
            if (text?.trim().length === 0 || !text) {
              return true;
            }
            return text.trim().length > 0 && isValidStringUrl(text.trim());
          }),
      }),
      lieferando: yup.object().shape({
        url: yup
          .string()
          .nullable()
          .max(MAX_COMMON_LENGTH, translations('validation_too_long'))
          .test('test url', translations('validation_incorrect_url_format'), (text) => {
            if (text?.trim().length === 0 || !text) {
              return true;
            }
            return text.trim().length > 0 && isValidStringUrl(text.trim());
          }),
      }),
      wolt: yup.object().shape({
        url: yup
          .string()
          .nullable()
          .max(MAX_COMMON_LENGTH, translations('validation_too_long'))
          .test('test url', translations('validation_incorrect_url_format'), (text) => {
            if (text?.trim().length === 0 || !text) {
              return true;
            }
            return text.trim().length > 0 && isValidStringUrl(text.trim());
          }),
      }),
      customDelivery: yup.object().shape({
        url: yup
          .string()
          .nullable()
          .test('test url', translations('validation_incorrect_url_format'), (text) => {
            if (text?.trim().length === 0 || !text) {
              return true;
            }
            return text.trim().length > 0 && isValidStringUrl(text.trim());
          })
          .max(MAX_COMMON_LENGTH, translations('validation_too_long')),
      }),
    }),
    addressLinkAndroid: yup
      .string()
      .nullable()
      .test('test url', translations('validation_incorrect_url_format'), (text) => {
        if (text?.trim().length === 0 || !text) {
          return true;
        }
        return text.trim().length > 0 && isValidStringUrl(text.trim());
      })
      .max(MAX_RESTAURANT_ANDROID_LINK_LENGTH, translations('validation_too_long')),
    googlePlaceId: yup
      .string()
      .nullable()
      .max(MAX_RESTAURANT_GOOGLE_PLACE_ID_LENGTH, translations('validation_too_long')),
    tags: yup.array().of(yup.object().shape(selectOptionSchema)),
    promotions: yup.array().of(yup.object().shape(selectOptionSchema)),
    status: yup.mixed().oneOf(Object.values(RestaurantStatus), translations('validation_incorrect_value')),
    keyAccountId: yup.string(),
    closingSourceId: yup.string(),
    wholesaleCustomerId: yup
      .string()
      .nullable()
      .max(MAX_RESTAURANT_WHOLESALE_CUSTOMER_ID_LENGTH, translations('validation_too_long')),
    latitude: yup
      .number()
      .typeError(translations('validation_incorrect_latitude'))
      .nullable()
      .max(MAX_LATITUDE, translations('validation_incorrect_latitude'))
      .min(MIN_LATITUDE, translations('validation_incorrect_latitude'))
      .transform((value?: string | number, originalValue?: string | number) =>
        originalValue?.toString().trim() === '' ? undefined : value,
      ),
    longitude: yup
      .number()
      .typeError(translations('validation_incorrect_longitude'))
      .nullable()
      .max(MAX_LONGITUDE, translations('validation_incorrect_longitude'))
      .min(MIN_LONGITUDE, translations('validation_incorrect_longitude'))
      .transform((value?: string | number, originalValue?: string | number) =>
        originalValue?.toString().trim() === '' ? undefined : value,
      ),
  });
};
