import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { FormGroup } from '../../../../common/components';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../../../../common/constants/common';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useUnderReviewInventoryCounts } from '../../../../services/inventory-counts';
import { InventoryCountResponse } from '../../../Restaurants/InventoryCounts/models/inventory-count-response';
import { getInventoryCountStatusTranslationKey } from '../../../Restaurants/InventoryCounts/utils/inventory-counts.helper';

export const LostItemsInventoryCountSelect = () => {
  const translations = useTranslations();
  const { control } = useFormContext();
  const { limit, offset } = ALL_PAGINATED_LIST_ITEMS_PARAMS;
  const { data: inventoryCounts, isFetching: isFetchingInventoryCounts } = useUnderReviewInventoryCounts(offset, limit);

  const getInventoryCountLabel = useCallback(
    (option: InventoryCountResponse) =>
      `${option.restaurant?.id} - ${option.restaurant?.restaurantName} (${translations(
        getInventoryCountStatusTranslationKey(option.status),
      )})`,
    [translations],
  );

  const getInventoryCountValue = useCallback((option: InventoryCountResponse) => option.id, []);

  return (
    <FormGroup
      label={translations('lost_items_inventory_counts_label')}
      inputId="inventoryCounts"
      labelTooltipText={translations('lost_items_inventory_counts_tooltip')}
    >
      <Controller
        control={control}
        name="inventoryCounts"
        render={({ field: { value, name, onChange } }) => (
          <Select
            inputId="inventoryCounts"
            name={name}
            placeholder={translations('lost_items_inventory_counts_placeholder')}
            isMulti
            getOptionLabel={getInventoryCountLabel}
            getOptionValue={getInventoryCountValue}
            value={value}
            onChange={onChange}
            options={inventoryCounts?.items || []}
            isLoading={isFetchingInventoryCounts}
          />
        )}
      />
    </FormGroup>
  );
};
