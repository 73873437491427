import React, { ComponentProps, JSX } from 'react';
import { Message } from 'react-hook-form';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Flex, FormControl, FormLabel, Text, Tooltip } from '@chakra-ui/react';

import colors from '../../../styles/colors';

type FormGroupProps = {
  label: React.ReactNode;
  labelTooltipText?: React.ReactNode;
  inputId: string;
  validationError?: string | Message | undefined;
  errorMessageProps?: ComponentProps<typeof Text>;
  isVertical?: boolean;
  reverseLayout?: boolean;
  children: React.ReactElement;
};
export const FormGroup = ({
  label,
  labelTooltipText,
  validationError,
  errorMessageProps,
  inputId,
  isVertical = true,
  reverseLayout = false,
  children,
}: FormGroupProps): JSX.Element => {
  const clonedInput = React.cloneElement(children, {
    id: inputId,
    ...('name' in children.props && { isInvalid: !!validationError || validationError }),
  });

  const Label = () => (
    <Flex mt={3} mb={2} alignItems="center">
      <FormLabel mb={0} htmlFor={inputId} mr={isVertical ? 1 : 2}>
        {label}
      </FormLabel>
      {labelTooltipText && (
        <Tooltip label={labelTooltipText} shouldWrapChildren>
          <QuestionOutlineIcon color={colors.black} data-testid="tooltip-trigger-icon" />
        </Tooltip>
      )}
    </Flex>
  );

  return (
    <FormControl w={children.props.w}>
      <Flex direction={isVertical ? 'column' : 'row'}>
        {!reverseLayout && <Label />}
        {clonedInput}
        <Text color={colors.red[500]} textAlign="left" {...errorMessageProps} height={6} fontSize={14}>
          {validationError}
        </Text>
        {reverseLayout && <Label />}
      </Flex>
    </FormControl>
  );
};
