import { ContractType } from '../../../models/enums/contract-type.enum';

export const mapLossContractTypeToSelectValueTranslationKey = (contractType: ContractType) => {
  switch (contractType) {
    case ContractType.YEARLY:
      return 'restaurant_billing_information_contract_type_select_yearly_label';
    case ContractType.MONTHLY:
      return 'restaurant_billing_information_contract_type_select_monthly_label';
    case ContractType.QUARTERLY:
      return 'restaurant_billing_information_contract_type_select_quarterly_label';
    default: {
      const exhaustiveCheck: never = contractType;
      throw new Error(exhaustiveCheck);
    }
  }
};
