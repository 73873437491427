import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorMessages } from '../../../../common/components';
import { ChildPageHeading } from '../../../../common/components/ChildPageHeading';
import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useCreateReusableTip } from '../../../../services/reusable-tips';
import { ReusableTipForm } from '../ReusableTipForm/ReusableTipForm';

export const CreateReusableTip = () => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { mutate: createReusableTip, isLoading, error } = useCreateReusableTip();

  return (
    <>
      <ChildPageHeading>{translations('reusable_tips_create_header')}</ChildPageHeading>
      <ErrorMessages errors={error} />
      <ReusableTipForm
        isLoading={isLoading}
        onSubmit={(createReusableTipRequest) =>
          createReusableTip(createReusableTipRequest, {
            onSuccess: () => navigate(Routes.REUSABLE_TIPS_PATH),
          })
        }
      />
    </>
  );
};
