import * as yup from 'yup';

import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { FEE_MAX_VALUE, FEE_MIN_VALUE } from '../../../utils/countries.consts';

export const usePricingValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    notReturnFee: yup
      .string()
      .required(translations('validation_required'))
      .test(
        'has-correct-value',
        translations('fee_validation_message', {
          '{{min-value}}': FEE_MIN_VALUE.toString(),
          '{{max-value}}': FEE_MAX_VALUE.toString(),
        }),
        (value) => Number(value) >= FEE_MIN_VALUE && Number(value) <= FEE_MAX_VALUE,
      ),
    extensionFee: yup
      .string()
      .required(translations('validation_required'))
      .test(
        'has-correct-value',
        translations('fee_validation_message', {
          '{{min-value}}': FEE_MIN_VALUE.toString(),
          '{{max-value}}': FEE_MAX_VALUE.toString(),
        }),
        (value) => Number(value) >= FEE_MIN_VALUE && Number(value) <= FEE_MAX_VALUE,
      ),
  });
};
