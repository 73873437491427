import React, { JSX } from 'react';
import { Button, ModalFooter } from '@chakra-ui/react';
import { Modal, ModalType } from 'common/components/Modal/Modal';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { ItemFailedResponse, ItemSuccessResponse } from '../../../modules/LostItems/model/lost-items.model';
import { ItemsSummarySectionModalBody } from './ItemsSummarySectionModalBody';

interface FailureItem {
  uId: string;
  message?: string;
}

interface SuccessItem {
  uId: string;
}

type ItemsSummaryModalProps = {
  isVisible: boolean;
  modalHeader: string | JSX.Element | JSX.Element[];
  successText: string | JSX.Element | JSX.Element[];
  failureText: string | JSX.Element | JSX.Element[];
  onCancel: () => void;
  failureItems?: FailureItem[];
  successItems?: SuccessItem[];
};
const ItemsSummaryModal = ({
  isVisible,
  modalHeader,
  successText,
  failureText,
  onCancel,
  failureItems,
  successItems,
}: ItemsSummaryModalProps): JSX.Element | null => {
  const translations = useTranslations();

  const columns = [
    {
      id: '1',
      Header: translations('product_u_id'),
      accessor: 'uId' as const,
    },
    {
      id: '2',
      Header: translations('error'),
      accessor: 'message' as const,
    },
  ];

  return (
    <Modal
      modalHeader={modalHeader}
      size="xl"
      isModalVisible={isVisible}
      onModalClose={onCancel}
      customModalFooter={
        <ModalFooter justifyContent="flex-start">
          <Button colorScheme="orange" onClick={onCancel}>
            {translations('ok')}
          </Button>
        </ModalFooter>
      }
      type={ModalType.INFO}
    >
      <ItemsSummarySectionModalBody<ItemFailedResponse, ItemSuccessResponse>
        columns={columns}
        successText={successText}
        failureText={failureText}
        successItems={successItems}
        failureItems={failureItems}
      />
    </Modal>
  );
};

export default ItemsSummaryModal;
