import { Language } from '../../../common/utils';

export interface MetaCategoryRestaurantResponse {
  id: string;
  name: string;
  translations: LanguagesConfig;
}

export type LanguagesConfig = {
  [key in Language]: string;
};

export interface MetaCategory {
  id: string;
  translationKey: string;
  translations: LanguagesConfig;
  color: string | null;
  image: string | null;
  type: MetaCategoryType | null;
}

export enum MetaCategoryType {
  STANDARD = 'STANDARD',
  SPECIAL = 'SPECIAL',
  HIDDEN = 'HIDDEN',
}

export interface MetaCategoryFormValues {
  translationKey: string;
  translations: LanguagesConfig;
  type: MetaCategoryType | null;
  color: string | null;
  image: string | null;
  imageTemporary?: FileList;
}

export interface MetaCategoryRequest {
  translationKey: string;
  color: string | null;
  type: MetaCategoryType | null;
  image?: File;
}
