const API_PATH = '/api/v1/admin';

const apiPaths = {
  RESTAURANTS: `${API_PATH}/restaurants`,
  RESTAURANTS_DRAFTS: `${API_PATH}/restaurants/drafts`,
  RESTAURANT_DRAFTS_BY_ID: `${API_PATH}/restaurants/:restaurantId/drafts/:draftId`,
  RESTAURANTS_DRAFTS_STATUS: `${API_PATH}/restaurants/:restaurantId/drafts/:draftId/status`,
  RESTAURANTS_LOST_ITEMS: `${API_PATH}/lost-items`,
  RESTAURANTS_LOST_ITEMS_UNDO: `${API_PATH}/lost-items/undo`,
  RESTAURANTS_RETURN_CODES: `${API_PATH}/restaurants/:restaurantId/return-codes`,
  RESTAURANTS_RETURN_CODES_RETURN_CODE: `${API_PATH}/restaurants/:restaurantId/return-codes/:returnCodeId`,
  RESTAURANTS_RETURN_CODES_BATCH: `${API_PATH}/restaurants/return-codes/batch`,
  GOOGLE_PLACE_DETAILS: `${API_PATH}/google-place-details`,
  RESTAURANT_USERS: `${API_PATH}/restaurant-users`,
  RESTAURANT_USERS_NOTIFICATION_SETTINGS: `${API_PATH}/restaurant-users/:userId/notification-settings`,
  TAGS: `${API_PATH}/tags`,
  CITY: `${API_PATH}/cities`,
  NEWS: `${API_PATH}/news`,
  USERS: `${API_PATH}/users`,
  USERS_SETTINGS: `${API_PATH}/users/:userId/settings`,
  USERS_ASSIGNED_ITEMS: `${API_PATH}/users/:userId/assigned-items`,
  ABOUT_US: `${API_PATH}/about-us`,
  PAYMENTS: `${API_PATH}/payments`,
  SCRIPTS: `${API_PATH}/scripts`,
  CATEGORIES: `${API_PATH}/product-categories`,
  CUSTOMERS: `${API_PATH}/customers`,
  CORPORATES: `${API_PATH}/organisations`,
  PARTNERS: `${API_PATH}/partners`,
  NOTIFICATIONS: `${API_PATH}/notifications`,
  KEY_ACCOUNT_GROUPS: `${API_PATH}/key-account-groups`,
  KEY_ACCOUNT_GROUPS_KEY_ACCOUNT_GROUP: `${API_PATH}/key-account-groups/:keyAccountId`,
  KEY_ACCOUNT_GROUPS_RETURN_CODES: `${API_PATH}/key-account-groups/:keyAccountId/return-codes`,
  KEY_ACCOUNT_GROUPS_CREATE_RETURN_CODES: `${API_PATH}/key-account-groups/:keyAccountId/return-codes/batch`,
  KEY_ACCOUNT_GROUPS_IMAGES: `${API_PATH}/key-account-groups/:keyAccountId/images`,
  KEY_ACCOUNT_GROUPS_IMAGES_IMAGE: `${API_PATH}/key-account-groups/:keyAccountId/images/:imageId`,
  KEY_ACCOUNT_GROUPS_RESTAURANTS: `${API_PATH}/key-account-groups/:keyAccountId/restaurants`,
  KEY_ACCOUNT_GROUPS_RESTAURANTS_RESTAURANT: `${API_PATH}/key-account-groups/:keyAccountId/restaurants/:restaurantId`,
  RESTAURANTS_ASSIGNED_ITEMS: `${API_PATH}/restaurants/:restaurantId/assigned-items`,
  RESTAURANTS_TWO_FACTOR_RETURN: `${API_PATH}/restaurants/:restaurantId/two-factor-return`,
  RESTAURANTS_DEPOSIT: `${API_PATH}/restaurants/:restaurantId/deposit`,
  RESTAURANTS_BILLING_INFORMATION: `${API_PATH}/restaurants/:restaurantId/billing`,
  PRODUCTS: `${API_PATH}/products`,
  RESTAURANT_PRODUCTS: `${API_PATH}/restaurant/:restaurantId/products`,
  WAREHOUSES: `${API_PATH}/warehouses`,
  LOCATIONS: `${API_PATH}/locations`,
  META_CATEGORIES: `${API_PATH}/meta-categories`,
  REPORTS_PATH: `${API_PATH}/stats/reports`,
  RELEVO_USERS: `${API_PATH}/relevo-users`,
  CLOSING_SOURCES: `${API_PATH}/closing-sources`,
  SEGMENTS: `${API_PATH}/segments`,
  FEATURE_FLAGS: `${API_PATH}/feature-flags`,
  COUNTRIES: `${API_PATH}/countries`,
  COUNTRIES_COUNTRY: `${API_PATH}/countries/:countryId`,
  COUNTRIES_COUNTRY_PRICING: `${API_PATH}/countries/:countryId/pricing`,
  COUNTRIES_COUNTRY_CATEGORY_PRICING: `${API_PATH}/countries/:countryId/pricing/:categoryId`,
  TEST_NOTIFICATIONS: `${API_PATH}/notifications/test`,
  FREQUENTLY_ASKED_QUESTIONS: `${API_PATH}/frequently-asked-questions`,
  FREQUENTLY_ASKED_QUESTIONS_SECTIONS: `${API_PATH}/frequently-asked-questions/sections`,
  FREQUENTLY_ASKED_QUESTIONS_SECTIONS_QUESTIONS: `${API_PATH}/frequently-asked-questions/sections/:sectionId/questions`,
  FREQUENTLY_ASKED_QUESTIONS_QUESTIONS: `${API_PATH}/frequently-asked-questions/sections/questions`,
  REUSE_CARDS: `${API_PATH}/reuse-card-users`,
  REUSE_CARDS_REUSE_CARD: `${API_PATH}/reuse-card-users/:reuseCardId`,
  REUSE_CARDS_CREATE_BATCH: `${API_PATH}/reuse-card-users/batch`,
  REUSE_CARDS_REUSE_CARD_ITEMS: `${API_PATH}/reuse-card-users/:reuseCardId/assigned-items`,
  RESTAURANTS_INVENTORY_COUNTS: `${API_PATH}/restaurants/:restaurantId/inventory-counts`,
  RESTAURANTS_INVENTORY_COUNTS_INVENTORY_COUNT: `${API_PATH}/restaurants/inventory-counts/:inventoryCountId`,
  RESTAURANTS_INVENTORY_COUNTS_INVENTORY_COUNT_INVOICES: `${API_PATH}/restaurants/inventory-counts/:inventoryCountId/invoices`,
  REUSE_CARDS_EVENTS: `${API_PATH}/reuse-card-users/:reuseCardId/events`,
  INVENTORY_COUNTS: `${API_PATH}/inventory-counts`,
  REUSABLE_TIPS: `${API_PATH}/reusable-tips`,
  REUSABLE_TIPS_REUSABLE_TIP: `${API_PATH}/reusable-tips/:reusableTipId`,
  REUSABLE_TIPS_REORDER: `${API_PATH}/reusable-tips/reorder`,
};

export default apiPaths;
