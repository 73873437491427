import React, { JSX, useCallback, useEffect, useState } from 'react';
import ReactDomServer from 'react-dom/server';
import { FormProvider, useForm } from 'react-hook-form';
import { WarningIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text, useTheme } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios/index';
import { PageHeading, useToast } from 'common/components';
import { useAssignProductsToRestaurant } from 'services/product-assignment';

import ItemsSummaryModal from '../../common/components/ItemsSummaryModal/ItemsSummaryModal';
import ScanditScanner from '../../common/components/Scanner/ScanditScanner';
import { HttpException } from '../../common/models/httpException';
import { useExecuteWithConfirmation } from '../../common/utils';
import { extractUId } from '../../common/utils/qrCodes.helper';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useHtmlTranslations } from '../../contexts/LocalizationContext/hooks';
import { useProductByUid } from '../../services/products';
import { ProductResponse } from '../Products/Product.model';
import { AssignProductFromCsvModal } from './AssignProductFromCsv/AssignProductFromCsvModal';
import { AssignItemsToRestaurantResponse } from './AssignProductFromCsv/model/AssignItemsToRestaurant.model';
import { ReassignBetweenRestaurantsModal } from './ReassignBetweenRestaurants/ReassignBetweenRestaurantsModal';
import { getItemAssignmentValidationSchema } from './AssignProduct.form';
import { ProductAssignmentForm, VisibleModal } from './AssignProduct.model';
import AssignProductForm from './AssignProdutForm';
import ProductAssignmentTable from './ProductAssignmentTable';

const LIST_WRAPPER_HEIGHT = '400px';

const AssignProduct = (): JSX.Element => {
  const translations = useTranslations();
  const htmlTranslations = useHtmlTranslations();
  const { displayToast } = useToast();
  const { colors } = useTheme();
  const [scannedProducts, setScannedProducts] = useState<ProductResponse[]>([]);
  const [code, setCode] = useState<string | null>(null);
  const [visibleModal, setVisibleModal] = useState<VisibleModal | null>(null);
  const [isMassAssign, setIsMassAssign] = useState(false);
  const [assignedItems, setAssignedItems] = useState<AssignItemsToRestaurantResponse>();

  const handleShowAssignSummary = (data: AssignItemsToRestaurantResponse) => {
    setAssignedItems(data);
    setVisibleModal(VisibleModal.PRODUCTS_ASSIGNMENT_SUCCESS);
  };

  const { mutate: assignProductsToRestaurant, isLoading: isAssignProductsInProgress } = useAssignProductsToRestaurant({
    onSuccessfulAssign: (data) => handleShowAssignSummary(data),
  });

  const formReturn = useForm<ProductAssignmentForm>({
    resolver: yupResolver(getItemAssignmentValidationSchema(translations)),
    defaultValues: { itemsVolume: null, category: null, restaurant: null },
  });

  const { trigger, getValues, watch, reset } = formReturn;

  const watchItemsVolume = watch('itemsVolume');
  const watchRestaurant = watch('restaurant');
  const watchCategory = watch('category');

  const isAlreadyScanned = useCallback(
    (uId: string | null): boolean => {
      return scannedProducts.some((item) => item.uId === uId);
    },
    [scannedProducts],
  );

  const { mutate: getRelevo, isLoading: isLoadingGetRelevo } = useProductByUid();

  const handleScan = async (qrCode: string) => {
    await trigger();
    if (!watchItemsVolume || !watchRestaurant || !watchCategory) {
      setCode(null);
      displayToast('error', translations('assign_products_empty_form_error'), false, 2000);
      return;
    }
    if (!!watchItemsVolume && scannedProducts.length === Number(watchItemsVolume)) {
      setCode(null);
      displayToast('error', translations('product_assignment_too_many_scanned_products_length_error'), false, 4000);
      return;
    }
    getRelevo(
      { uId: qrCode },
      {
        onSuccess: (productByUidResult) => {
          if (productByUidResult) {
            if (productByUidResult?.category.id === getValues('category')?.id) {
              setCode(null);
              setScannedProducts((products) => [productByUidResult, ...products]);
            } else {
              setCode(null);
              displayToast(
                'error',
                translations('product_assignment_category_different_than_selected_error'),
                false,
                2000,
              );
            }
          }
        },
        onError: (err: AxiosError<HttpException>) => {
          setCode(null);
          const message = err.response?.data.message;
          displayToast('error', message ?? translations('product_assignment_product_fetching_error'), false, 4000);
        },
      },
    );
  };

  useEffect(() => {
    if (code) {
      const uId = extractUId(code);
      if (uId && isAlreadyScanned(uId)) {
        return;
      }
      if (uId) {
        void handleScan(uId);
      }
    }
    // FYI: handleScan - consider using useEffectEvent once it is released in a stable version of React, and we upgrade it
    // https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
    // eslint-disable-next-line
  }, [code, isAlreadyScanned]);

  const triggerAssignProducts = async () => {
    await trigger();
    if (!getValues('itemsVolume')) {
      displayToast('error', translations('product_assignment_empty_items_number_input_error'));
      return;
    }

    if (!!getValues('itemsVolume') && !(Number(getValues('itemsVolume')) === scannedProducts.length)) {
      displayToast('error', translations('product_assignment_incorrect_scanned_products_length_error'));
      return;
    }

    await assignProductsToRestaurant({
      restaurantId: getValues('restaurant')!.id,
      productIds: scannedProducts.map(({ id }) => id),
    });
  };

  const handleProductDelete = (uId: string | null) => {
    const productsAfterDelete = scannedProducts.filter((product) => product.uId !== uId);
    setScannedProducts(productsAfterDelete);
  };

  const handleCancelClicked = () => {
    setScannedProducts([]);
    reset({ restaurant: null, category: null, itemsVolume: null });
    setVisibleModal(null);
  };

  const { executeWithConfirmation: handleCancel } = useExecuteWithConfirmation(
    translations('product_assignment_items_cancel_confirmation_modal_content'),
    handleCancelClicked,
  );

  const handleMassAssignClick = () => {
    setIsMassAssign(true);
    setVisibleModal(VisibleModal.ASSIGN_PRODUCTS_FROM_CSV);
  };

  const handleAssignFromCsvClick = () => {
    setIsMassAssign(false);
    setVisibleModal(VisibleModal.ASSIGN_PRODUCTS_FROM_CSV);
  };

  const handleReassignBetweenRestaurantsClick = () => setVisibleModal(VisibleModal.REASSIGN_BETWEEN_RESTAURANTS);

  const handleCloseModal = () => setVisibleModal(null);

  return (
    <Box textAlign="center" height="100%" width={{ base: '100%', '2xl': '80%' }} margin="auto">
      <Flex direction="column" height="100%" pb={2}>
        <Box flex={1}>
          <PageHeading>{translations('product_assignment_header')}</PageHeading>
          <Flex justifyContent="flex-start" direction="column">
            <Button mr="auto" mb="2" onClick={handleAssignFromCsvClick}>
              {translations('product_assignment_assign_from_csv_button')}
            </Button>
            <Button mr="auto" mb="2" onClick={handleReassignBetweenRestaurantsClick}>
              {translations('product_assignment_reassign_between_restaurants')}
            </Button>
            <Button mr="auto" onClick={handleMassAssignClick}>
              {translations('product_assignment_mass_assign_from_csv_button')}
            </Button>
          </Flex>
          <FormProvider {...formReturn}>
            <AssignProductForm scannedProductsLength={scannedProducts.length} />
          </FormProvider>
          <Flex
            mt={10}
            justifyContent={{ base: 'center', xl: 'left' }}
            alignItems={{ base: 'center', xl: 'stretch' }}
            direction={{ base: 'column', xl: 'row' }}
          >
            <Box width={{ base: '100%', lg: '50%' }} height="100%" mx={1}>
              <ScanditScanner onScan={setCode} />
            </Box>
            <Box
              width={{ base: '100%', lg: '50%' }}
              mt={{ base: 4, xl: 0 }}
              border="1px solid"
              borderColor={colors.orange[500]}
              mx={1}
            >
              {scannedProducts.length ? (
                <Flex alignItems="center" justifyContent="flex-start" direction="column" height="100%">
                  <Text fontSize="xl" mt={2}>
                    {translations('product_assignment_scanned_products_list_title')}
                  </Text>
                  {!!watchItemsVolume && (
                    <Text color={colors.grey[900]} width="70%">
                      {translations('product_assignment_scanned_products_volume_preview', {
                        '{{actual-scanned-number}}': String(scannedProducts.length),
                        '{{desired-scanned-number}}': String(watchItemsVolume),
                      })}
                    </Text>
                  )}
                  <Box width="100%" height={LIST_WRAPPER_HEIGHT} overflowY="scroll" pb={2}>
                    <ProductAssignmentTable
                      onProductDelete={handleProductDelete}
                      data={scannedProducts}
                      isLoading={isLoadingGetRelevo}
                    />
                  </Box>
                </Flex>
              ) : (
                <Flex height="100%" justifyContent="center" alignItems="center" direction="column" p={2}>
                  <WarningIcon fontSize="30px" color={colors.grey[50]} />
                  <Text fontSize="3xl" color={colors.grey[200]}>
                    {translations('product_assignment_empty_scanned_products_list')}
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>
        </Box>
        <Flex mt={10}>
          <Button
            colorScheme="orange"
            onClick={triggerAssignProducts}
            isDisabled={!scannedProducts.length}
            isLoading={isAssignProductsInProgress}
          >
            {translations('product_assignment_assign_items_button')}
          </Button>
          <Button p={5} ml={2} onClick={handleCancel}>
            {translations('cancel')}
          </Button>
        </Flex>
        <ItemsSummaryModal
          isVisible={visibleModal === VisibleModal.PRODUCTS_ASSIGNMENT_SUCCESS}
          modalHeader={translations('assign_items_summary_modal_header')}
          successText={htmlTranslations('product_assignment_items_modal_success_content', {
            '{{success-items-number}}': ReactDomServer.renderToString(
              <Text as="b">{(assignedItems?.assignedItems.length || 0).toString()}</Text>,
            ),
            '{{items-number}}': ReactDomServer.renderToString(
              <Text as="b">
                {(
                  (assignedItems?.assignedItems.length || 0) + (assignedItems?.notAssignedItems.length || 0)
                ).toString()}
              </Text>,
            ),
          })}
          failureText={translations('not_assigned_items')}
          failureItems={assignedItems?.notAssignedItems}
          successItems={assignedItems?.assignedItems.map((uId) => {
            return { uId };
          })}
          onCancel={handleCancelClicked}
        />
        <AssignProductFromCsvModal
          isVisible={visibleModal === VisibleModal.ASSIGN_PRODUCTS_FROM_CSV}
          onModalClose={handleCloseModal}
          isMassAssign={isMassAssign}
          onAssign={handleShowAssignSummary}
        />
        <ReassignBetweenRestaurantsModal
          isVisible={visibleModal === VisibleModal.REASSIGN_BETWEEN_RESTAURANTS}
          onModalClose={handleCloseModal}
          onAssign={handleShowAssignSummary}
        />
      </Flex>
    </Box>
  );
};

export default AssignProduct;
