import React, { JSX, useEffect, useMemo, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { usePapaParse } from 'react-papaparse';
import { CellProps, Column } from 'react-table';
import { InfoOutlineIcon, WarningIcon } from '@chakra-ui/icons';
import { Button, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import {
  ErrorMessages,
  FileInput,
  FormGroup,
  MainPageContent,
  Modal,
  PageHeading,
  Table,
  useToast,
} from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import { CSV_DELIMITERS } from '../../../common/constants/csvConvert.consts';
import { DayOfWeek } from '../../../common/models';
import { formatOpeningHoursPeriods } from '../../../common/utils';
import { cutLongString } from '../../../common/utils/helpers';
import { readBlob } from '../../../common/utils/readBlob.helper';
import { isValidStringUrl } from '../../../common/utils/url.helper';
import { useCreateRestaurants } from '../../../services/restaurants';
import colors from '../../../styles/colors';
import { City } from '../../City/models/City';
import { ClosingSourceResponse } from '../../ClosingSources/model/ClosingSource';
import { MetaCategory } from '../../MetaCategories/model/MetaCategory';
import { Promotion } from '../../Promotions/models/Promotion';
import { TagResponse } from '../../Tags/models/tag-response';
import { RestaurantDetailsResponse, RestaurantOpeningHoursResponse, RestaurantRequest } from '../models';
import { Deliverers } from '../models/restaurant-deliverers.model';
import {
  MAX_COMMON_LENGTH,
  MAX_RESTAURANT_ADDRESS_LENGTH,
  MAX_RESTAURANT_ANDROID_LINK_LENGTH,
  MAX_RESTAURANT_DETAIL_LENGTH,
  MAX_RESTAURANT_GOOGLE_PLACE_ID_LENGTH,
  MAX_RESTAURANT_NAME_LENGTH,
  MAX_RESTAURANT_PHONE_LENGTH,
  MAX_RESTAURANT_STREET_NAME_LENGTH,
} from '../restaurant.consts';
import { RestaurantStatus, RestaurantStatusNames, StarterSetSize } from '../RestaurantForm/RestaurantForm.form';
import { prepareOpeningHours } from '../RestaurantForm/restaurantFormHelper';
import { ImportRestaurantCsvRequest } from './model/import-restaurant-csv.request.model';
import {
  buildRestaurantTableRecord,
  getCamelCasedCsvHeaders,
  getCityById,
  getCsvNonEmptyColumns,
  getDeliverersTableValue,
  getMetaCategoryById,
  getMetaCategoryByTranslation,
  getPromotionById,
  getPromotionByTranslation,
  getRestaurantNonEmptyColumns,
  getTagById,
  getTagByTranslation,
  restaurantsCsvAsTextToJSON,
} from './utils/import-restaurants.helper';
import {
  restaurantRequestToRestaurantResponseMapper,
  restaurantResponseToRestaurantRequestMapper,
} from './utils/restaurant.mapper';
import { useRestaurantCharacteristicsLists } from './utils/use-restaurants-characteristics-lists.hook';
import EditImportedRestaurant from './EditImportedRestaurant';

interface CreateRestaurantsButtonTooltipProps {
  fieldName?: string;
  value: string | null;
  message: string;
  renderKey: string;
  isColumnItem?: boolean;
}

const ErrorFieldTooltip = ({
  fieldName,
  message,
  value,
  renderKey,
  isColumnItem,
}: CreateRestaurantsButtonTooltipProps) => (
  <Tooltip
    label={
      <Text>
        {fieldName ? fieldName : null}
        {message}
      </Text>
    }
    key={renderKey}
  >
    <div
      className={
        isColumnItem
          ? 'imported-restaurants-table-column-with-error-text'
          : 'imported-restaurants-table-item-with-error'
      }
    >
      <Flex>
        <Center>
          <WarningIcon color={colors.red[500]} w={10} h={4} />
        </Center>
        {value}
      </Flex>
    </div>
  </Tooltip>
);

const ImportRestaurants = (): JSX.Element => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const { readString } = usePapaParse();
  const { cities, keyAccounts, tags, metaCategories, closingSources, promotions } = useRestaurantCharacteristicsLists();
  const [importedRestaurants, setImportedRestaurants] = useState<RestaurantDetailsResponse[]>([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<RestaurantDetailsResponse>();
  const [nonEmptyColumns, setNonEmptyColumns] = useState<string[]>([]);
  const [unmatchedColumns, setUnmatchedColumns] = useState<string[]>([]);
  const fieldsWithErrors = document.getElementsByClassName('imported-restaurants-table-item-with-error');
  const headersWithErrors = document.getElementsByClassName('imported-restaurants-table-column-with-error-text');
  // FIXME: It broke down after upgrading React to v18 and related libraries to the newest versions
  // const rows = document.getElementsByTagName('tr');
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const {
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({});

  // useEffect(() => {
  //   Array.from(fieldsWithErrors).forEach((field) => {
  //     field.parentElement?.parentElement?.classList.add('imported-restaurants-table-row-with-error');
  //   });
  // }, [fieldsWithErrors, fieldsWithErrors.length]);

  // Array.from(rows).forEach((row) => {
  //   const currentRowErrors = row.getElementsByClassName('imported-restaurants-table-item-with-error');
  //   if (currentRowErrors.length === 0) {
  //     row.classList.remove('imported-restaurants-table-row-with-error');
  //   }
  // });

  const csvFile = watch('csvFile');

  const {
    mutate: createRestaurants,
    isLoading,
    error: createRestaurantsError,
  } = useCreateRestaurants({
    onSuccessfulCreate: (data) => {
      displayToast(
        data.notCreatedRestaurants.length > 0 ? 'warning' : 'success',
        translations('import_restaurants_create_response_message', {
          '{{created_restaurants}}': data.createdRestaurants.length.toString(),
          '{{not_created_restaurants}}': (
            data.createdRestaurants.length + data.notCreatedRestaurants.length
          ).toString(),
        }),
      );
      setImportedRestaurants([]);
      setNonEmptyColumns([]);
      setUnmatchedColumns([]);
    },
  });

  const columnsToWatch: Array<keyof ImportRestaurantCsvRequest> = useMemo(
    () => ['city', 'tags', 'promotions', 'metaCategories', 'keyAccount', 'closingSource', 'starterSetSize'],
    [],
  );

  const allAvailableColumns = useMemo(
    () => [
      'id',
      'restaurantName',
      'restaurantDetail',
      'restaurantAddress',
      'city',
      'keyAccount',
      'streetName',
      'googlePlaceId',
      'phoneNumber',
      'addressLinkAndroid',
      'websiteLink',
      'tags',
      'metaCategories',
      'starterSetStartDate',
      'starterSetSize',
      'wholesaleCustomerId',
      'deliverers',
      'closingSource',
      'promotions',
    ],
    [],
  );

  const extraFields = useMemo(() => ['bowlsPrice', 'cupsPrice', 'uberEatsUrl', 'lieferandoUrl', 'woltUrl'], []);

  useEffect(() => {
    const setRestaurants = async () => {
      if (csvFile?.length) {
        setIsCsvLoading(true);
        const csvFileAsText = (await readBlob(csvFile![0])) || '';
        readString(csvFileAsText, {
          worker: true,
          skipEmptyLines: true,
          delimitersToGuess: CSV_DELIMITERS,
          complete: (results) => {
            const restaurantsJSON = restaurantsCsvAsTextToJSON(results.data as string[][]);
            const columns = getCamelCasedCsvHeaders(results.data[0] as string[]);
            const nonEmptyColumnNames = getCsvNonEmptyColumns(results.data as string[][]);

            const invalidColumns = columns?.filter(
              (column: string) =>
                !allAvailableColumns.includes(column) &&
                !extraFields.includes(column) &&
                nonEmptyColumnNames.includes(column),
            );

            setUnmatchedColumns(invalidColumns);
            setNonEmptyColumns(nonEmptyColumnNames);

            const restaurants = restaurantsJSON.map((restaurant) =>
              buildRestaurantTableRecord(
                restaurant,
                cities,
                tags,
                promotions,
                metaCategories,
                keyAccounts,
                closingSources,
              ),
            );

            setImportedRestaurants(restaurants);
            reset(csvFile);
            setIsCsvLoading(false);
          },
        });
      }
    };
    setRestaurants();
  }, [
    allAvailableColumns,
    cities,
    columnsToWatch,
    csvFile,
    extraFields,
    keyAccounts,
    closingSources,
    metaCategories,
    promotions,
    readString,
    reset,
    setImportedRestaurants,
    tags,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: translations('import_restaurants_table_restaurant_id'),
        accessor: 'id' as const,
      },
      {
        Header: translations('import_restaurants_table_restaurant_name'),
        accessor: 'restaurantName' as const,
        Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
          const isRestaurantNameTooLong = value && value?.length >= MAX_RESTAURANT_NAME_LENGTH;
          if (!value || !value.length) {
            return (
              <ErrorFieldTooltip
                fieldName={translations('import_restaurants_table_restaurant_name')}
                value={''}
                message={translations('import_restaurants_create_restaurants_empty_restaurant_name')}
                renderKey={`${row.original.id}${row.original.restaurantName}`}
              />
            );
          } else if (isRestaurantNameTooLong) {
            return (
              <ErrorFieldTooltip
                fieldName={translations('import_restaurants_table_restaurant_name')}
                value={cutLongString(value, MAX_RESTAURANT_NAME_LENGTH)}
                message={translations('import_restaurants_validate_long_string', {
                  '{{number}}': MAX_RESTAURANT_NAME_LENGTH.toString(),
                })}
                renderKey={`${row.original.id}${row.original.restaurantName}`}
              />
            );
          } else {
            return value;
          }
        },
      },
      ...(nonEmptyColumns.includes('restaurantDetail')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_details'),
              accessor: 'restaurantDetail' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
                const isRestaurantDetailTooLong = value && value?.length >= MAX_RESTAURANT_DETAIL_LENGTH;
                if (isRestaurantDetailTooLong) {
                  return (
                    <ErrorFieldTooltip
                      fieldName={translations('import_restaurants_table_restaurant_details')}
                      value={value ? cutLongString(value, MAX_RESTAURANT_DETAIL_LENGTH) : null}
                      message={translations('import_restaurants_validate_long_string', {
                        '{{number}}': MAX_RESTAURANT_DETAIL_LENGTH.toString(),
                      })}
                      renderKey={`${row.original.id}${row.original.restaurantDetail}`}
                    />
                  );
                } else {
                  return value || '';
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('restaurantAddress')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_address'),
              accessor: 'restaurantAddress' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
                const isRestaurantAddressTooLong = value && value?.length >= MAX_RESTAURANT_ADDRESS_LENGTH;
                if (isRestaurantAddressTooLong) {
                  return (
                    <ErrorFieldTooltip
                      fieldName={translations('import_restaurants_table_restaurant_address')}
                      value={value ? cutLongString(value, MAX_RESTAURANT_ADDRESS_LENGTH) : null}
                      message={translations('import_restaurants_validate_long_string', {
                        '{{number}}': MAX_RESTAURANT_ADDRESS_LENGTH.toString(),
                      })}
                      renderKey={`${row.original.id}${row.original.restaurantAddress}`}
                    />
                  );
                } else {
                  return value || '';
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('city')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_city'),
              accessor: 'city' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, City | null>) => {
                const matchedCity = cities?.find(
                  (city) =>
                    city.id === value?.id ||
                    ((value?.translations.en || value?.translations.de) &&
                      Object.values(city.translations)
                        .map((el) => el.toLowerCase())
                        .includes(value.translations.en.toLowerCase() || value.translations.de.toLowerCase())),
                );
                const city = matchedCity && getCityById(matchedCity, cities)?.translations.en;

                if (!city && value?.translations.en.length) {
                  return (
                    <ErrorFieldTooltip
                      value={value?.translations.en ?? null}
                      message={`${value?.translations.en} ${translations('import_restaurants_city_error_message')}`}
                      renderKey={`${row.original.id}${row.original.city}`}
                    />
                  );
                }

                return city || '';
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('keyAccount')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_key_account'),
              accessor: 'keyAccountId' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | undefined>) => {
                const keyAccountGroup = keyAccounts?.find(
                  (item) => item.id === value || item.keyAccount.toLowerCase() === value?.toLowerCase(),
                )?.keyAccount;

                if (!keyAccountGroup && value?.length) {
                  return (
                    <ErrorFieldTooltip
                      value={value}
                      message={`${value} ${translations('import_restaurants_key_account_group_error_message')}`}
                      renderKey={`${row.original.id}${row.original.keyAccountId}`}
                    />
                  );
                }
                return keyAccountGroup || '';
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('streetName')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_street_name'),
              accessor: 'streetName' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
                const isStreetNameTooLong = value && value?.length >= MAX_RESTAURANT_STREET_NAME_LENGTH;

                if (isStreetNameTooLong) {
                  return (
                    <ErrorFieldTooltip
                      fieldName={translations('import_restaurants_table_restaurant_street_name')}
                      value={value ? cutLongString(value, MAX_RESTAURANT_STREET_NAME_LENGTH) : null}
                      message={translations('import_restaurants_validate_long_string', {
                        '{{number}}': MAX_RESTAURANT_STREET_NAME_LENGTH.toString(),
                      })}
                      renderKey={`${row.original.id}${row.original.streetName}`}
                    />
                  );
                } else {
                  return value || '';
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('googlePlaceId')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_google_place_id'),
              accessor: 'googlePlaceId' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
                const isGooglePlaceIdTooLong = value && value?.length >= MAX_RESTAURANT_GOOGLE_PLACE_ID_LENGTH;

                if (isGooglePlaceIdTooLong) {
                  return (
                    <ErrorFieldTooltip
                      fieldName={translations('import_restaurants_table_restaurant_google_place_id')}
                      value={value ? cutLongString(value, MAX_RESTAURANT_GOOGLE_PLACE_ID_LENGTH) : null}
                      message={translations('import_restaurants_validate_long_string', {
                        '{{number}}': MAX_RESTAURANT_GOOGLE_PLACE_ID_LENGTH.toString(),
                      })}
                      renderKey={`${row.original.id}${row.original.googlePlaceId}`}
                    />
                  );
                } else {
                  return value || '';
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('phoneNumber')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_phone_number'),
              accessor: 'phoneNumber' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
                const isPhoneToLong = value && value?.length >= MAX_RESTAURANT_PHONE_LENGTH;

                if (isPhoneToLong) {
                  return (
                    <ErrorFieldTooltip
                      fieldName={translations('import_restaurants_table_restaurant_phone_number')}
                      value={value ? cutLongString(value, MAX_RESTAURANT_PHONE_LENGTH) : null}
                      message={translations('import_restaurants_validate_long_string', {
                        '{{number}}': MAX_RESTAURANT_PHONE_LENGTH.toString(),
                      })}
                      renderKey={`${row.original.id}${row.original.phoneNumber}`}
                    />
                  );
                } else {
                  return value || '';
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('addressLinkAndroid')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_android_link'),
              accessor: 'addressLinkAndroid' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
                const isAddressLinkAndroidTooLong = value && value?.length >= MAX_RESTAURANT_ANDROID_LINK_LENGTH;

                if (value && value.trim().length && !isValidStringUrl(value.trim())) {
                  return (
                    <ErrorFieldTooltip
                      value={cutLongString(value, MAX_RESTAURANT_ANDROID_LINK_LENGTH)}
                      message={translations('import_restaurants_url_table_error_message')}
                      renderKey={`${row.original.id}${row.original.addressLinkAndroid}`}
                    />
                  );
                } else if (isAddressLinkAndroidTooLong) {
                  return (
                    <ErrorFieldTooltip
                      fieldName={translations('import_restaurants_table_restaurant_android_link')}
                      value={value ? cutLongString(value, MAX_RESTAURANT_ANDROID_LINK_LENGTH) : null}
                      message={translations('import_restaurants_validate_long_string', {
                        '{{number}}': MAX_RESTAURANT_ANDROID_LINK_LENGTH.toString(),
                      })}
                      renderKey={`${row.original.id}${row.original.addressLinkAndroid}`}
                    />
                  );
                } else {
                  return value || '';
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('websiteLink')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_website_link'),
              accessor: 'websiteLink' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
                const isWebsiteLinkTooLong = value && value?.length >= MAX_COMMON_LENGTH;

                if (value && value.trim().length && !isValidStringUrl(value.trim())) {
                  return (
                    <ErrorFieldTooltip
                      value={cutLongString(value, MAX_COMMON_LENGTH)}
                      message={translations('import_restaurants_url_table_error_message')}
                      renderKey={`${row.original.id}${row.original.addressLinkAndroid}`}
                    />
                  );
                } else if (isWebsiteLinkTooLong) {
                  return (
                    <ErrorFieldTooltip
                      value={value ? cutLongString(value, MAX_COMMON_LENGTH) : null}
                      message={translations('import_restaurants_validate_long_string', {
                        '{{number}}': MAX_COMMON_LENGTH.toString(),
                      })}
                      renderKey={`${row.original.id}${row.original.websiteLink}`}
                    />
                  );
                } else {
                  return value || '';
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('tags')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_tags'),
              accessor: 'tags' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, TagResponse[] | undefined>) => {
                return (
                  <>
                    {value?.map((tag) => {
                      const tagByTranslation = getTagByTranslation(tag, tags);
                      const tagById = getTagById(tag, tags);

                      if (tagById) {
                        return <div key={`${tag.id}${tag.translations.en}`}>{tagById.translations.en}</div>;
                      }

                      if (!tagByTranslation) {
                        return (
                          <ErrorFieldTooltip
                            value={tag.translations.en}
                            key={`${tag.id}${tag.translations.en}`}
                            message={`${tag.translations.en} ${translations('import_restaurants_tag_error_message')}`}
                            renderKey={`${row.original.id}${tag.translations.en}${tag.id}`}
                          />
                        );
                      }
                      return <div>{tag.translations.en}</div>;
                    })}
                  </>
                );
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('metaCategories')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_meta_categories'),
              accessor: 'metaCategories' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, MetaCategory[]>) => {
                return (
                  <>
                    {value?.map((category) => {
                      const categoryByTranslation = getMetaCategoryByTranslation(category, metaCategories);
                      const categoryById = getMetaCategoryById(category, metaCategories);

                      if (categoryById) {
                        return (
                          <div key={`${category.id}${category.translations.en}`}>{categoryById.translations.en}</div>
                        );
                      }

                      if (!categoryByTranslation) {
                        return (
                          <ErrorFieldTooltip
                            value={category.translations.en}
                            key={`${category.id}${category.translations.en}`}
                            message={`${category.translations.en} ${translations(
                              'import_restaurants_meta_category_error_message',
                            )}`}
                            renderKey={`${row.original.id}${category.translations.en}${category.id}`}
                          />
                        );
                      }
                      return <div>{category.translations.en}</div>;
                    })}
                  </>
                );
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('starterSetStartDate')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_starter_set_start_date'),
              accessor: 'starterSetStartDate' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, string | null>) => {
                const isStarterSetStartDateTooLong = value && value?.length >= MAX_COMMON_LENGTH;

                if (isStarterSetStartDateTooLong) {
                  return (
                    <ErrorFieldTooltip
                      fieldName={translations('import_restaurants_table_restaurant_starter_set_start_date')}
                      value={value ? cutLongString(value, MAX_COMMON_LENGTH) : null}
                      message={translations('import_restaurants_validate_long_string', {
                        '{{number}}': MAX_COMMON_LENGTH.toString(),
                      })}
                      renderKey={`${row.original.id}${row.original.starterSetStartDate}`}
                    />
                  );
                } else {
                  return value || '';
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('starterSetSize')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_starter_set_size'),
              accessor: 'starterSetSize' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, StarterSetSize | undefined>) => {
                const availableSetSizes = Object.values(StarterSetSize);

                if (value && !availableSetSizes.includes(value)) {
                  return (
                    <ErrorFieldTooltip
                      value={value}
                      message={`${value} ${translations('import_restaurants_set_size_error_message')}`}
                      renderKey={`${row.original.id}${value}`}
                    />
                  );
                }
                return value || '';
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('wholesaleCustomerId')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_wholesale_customer_id'),
              accessor: 'wholesaleCustomerId' as const,
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('woltUrl') ||
      nonEmptyColumns.includes('lieferandoUrl') ||
      nonEmptyColumns.includes('uberEatsUrl') ||
      nonEmptyColumns.includes('deliverers')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_deliverers'),
              accessor: 'deliverers' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, Deliverers | undefined>) => {
                if (
                  value &&
                  ((value.wolt?.url && !isValidStringUrl(value.wolt.url.trim())) ||
                    (value.lieferando?.url && !isValidStringUrl(value.lieferando.url.trim())) ||
                    (value.uberEats?.url && !isValidStringUrl(value.uberEats.url.trim())))
                ) {
                  return (
                    <ErrorFieldTooltip
                      value={getDeliverersTableValue(value) || ''}
                      message={`${translations('import_restaurants_url_table_error_message')}`}
                      renderKey={`${row.original.id}${value}`}
                    />
                  );
                } else {
                  return getDeliverersTableValue(value);
                }
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('closingSource')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_closing_source'),
              accessor: 'closingSource' as const,
              Cell: ({
                cell: { value, row },
              }: CellProps<RestaurantDetailsResponse, ClosingSourceResponse | undefined>) => {
                const closingSource = closingSources?.find(
                  (item) => item.name.toLowerCase() === value?.name.toLowerCase(),
                )?.name;

                if (!closingSource && value?.name.length) {
                  return (
                    <ErrorFieldTooltip
                      value={value.name}
                      message={`${value.name} ${translations('import_restaurants_closing_source_error_message')}`}
                      renderKey={`${row.original.id}${value.name}`}
                    />
                  );
                }

                return closingSource || '';
              },
            },
          ]
        : []),
      ...(nonEmptyColumns.includes('promotions')
        ? [
            {
              Header: translations('import_restaurants_table_restaurant_promotions'),
              accessor: 'promotions' as const,
              Cell: ({ cell: { value, row } }: CellProps<RestaurantDetailsResponse, Promotion[] | undefined>) => {
                return (
                  <>
                    {value?.map((promotion) => {
                      const promotionByTranslation = getPromotionByTranslation(promotion, promotions);
                      const promotionById = getPromotionById(promotion, promotions);

                      if (promotionById) {
                        return (
                          <div key={`${promotion.id}${promotion.translations.en}`}>{promotionById.translations.en}</div>
                        );
                      }

                      if (!promotionByTranslation) {
                        return (
                          <ErrorFieldTooltip
                            value={promotion.translations.en}
                            key={`${promotion.id}${promotion.translations.en}`}
                            message={`${promotion.translations.en} ${translations(
                              'import_restaurants_promotion_error_message',
                            )}`}
                            renderKey={`${row.original.id}${promotion.translations.en}${promotion.id}`}
                          />
                        );
                      }
                      return <div>{promotion.translations.en}</div>;
                    })}
                  </>
                );
              },
            },
          ]
        : []),
      {
        Header: translations('import_restaurants_table_restaurant_override_opening_hours'),
        accessor: 'overrideOpeningHours' as const,
        Cell: ({ cell: { value } }: CellProps<RestaurantDetailsResponse, boolean>) => {
          return <>{value ? translations('Yes') : translations('no')}</>;
        },
      },
      {
        Header: translations('restaurants_table_open_time'),
        accessor: 'restaurantOpeningHours' as const,
        Cell: ({ cell: { value } }: { cell: { value: RestaurantOpeningHoursResponse[] | null } }) => {
          let tooltipData = value;
          if (!tooltipData) {
            tooltipData = prepareOpeningHours(undefined);
          }
          const tooltipLabel = tooltipData
            ?.map((data) => `${DayOfWeek[data.day]}: ${formatOpeningHoursPeriods(data.periods)}`)
            .join('\n');

          return tooltipLabel ? (
            <Tooltip label={tooltipLabel} fontSize="md">
              <InfoOutlineIcon />
            </Tooltip>
          ) : null;
        },
      },
      {
        Header: translations('import_restaurants_table_restaurant_status'),
        accessor: 'status' as const,
        Cell: ({ cell: { value } }: CellProps<RestaurantDetailsResponse, RestaurantStatus>) => {
          return <>{RestaurantStatusNames[value]}</>;
        },
      },
      {
        Header: translations('import_restaurants_table_actions'),
        id: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<RestaurantDetailsResponse>) => (
          <Flex>
            <Tooltip label={unmatchedColumns.length ? translations('import_restaurants_columns_error_message') : null}>
              <div>
                <Button
                  m={1}
                  onClick={() => {
                    setSelectedRow(original);
                    setIsEditModalVisible(true);
                  }}
                  isDisabled={!!unmatchedColumns.length}
                >
                  {translations('edit')}
                </Button>
              </div>
            </Tooltip>
          </Flex>
        ),
      },
      ...unmatchedColumns.map((column, index) => ({
        Header: (
          <ErrorFieldTooltip
            value={cutLongString(column, MAX_COMMON_LENGTH)}
            key={`${index}${column}`}
            message={`${column} ${translations('import_restaurants_column_error_message')}`}
            renderKey={`${index}${column}`}
            isColumnItem
          />
        ),
        id: `${index}${column}`,
        accessor: column,
      })),
    ],
    [
      translations,
      nonEmptyColumns,
      unmatchedColumns,
      cities,
      keyAccounts,
      tags,
      metaCategories,
      closingSources,
      promotions,
    ],
  );

  const handleCreateRestaurants = () => {
    if (fieldsWithErrors.length || headersWithErrors.length) {
      displayToast(
        'error',
        translations('import_restaurants_create_restaurants_button_label1', {
          '{{number}}': (fieldsWithErrors.length + headersWithErrors.length).toString(),
        }) + translations('import_restaurants_create_restaurants_button_label2'),
      );
    } else {
      const restaurants = importedRestaurants.map((restaurant) =>
        restaurantResponseToRestaurantRequestMapper(restaurant),
      );
      createRestaurants({ restaurants });
    }
  };

  const onModalClose = () => {
    setIsEditModalVisible(false);
    setSelectedRow(undefined);
  };

  const onEditImportedRestaurant = (restaurant: RestaurantRequest) => {
    if (selectedRow) {
      const temporaryRestaurantsState = importedRestaurants;

      temporaryRestaurantsState[Number(selectedRow.id)] = restaurantRequestToRestaurantResponseMapper(
        selectedRow.id,
        restaurant,
      );

      const nonEmptyColumnNames = getRestaurantNonEmptyColumns(temporaryRestaurantsState);
      setNonEmptyColumns(nonEmptyColumnNames);
      setImportedRestaurants(temporaryRestaurantsState);
    }
    onModalClose();
  };

  return (
    <MainPageContent>
      <PageHeading>{translations('import_restaurants_header')}</PageHeading>
      <ErrorMessages errors={[createRestaurantsError]} />
      <form>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="space-between">
            <FormGroup
              label={translations('import_restaurants_from_csv_label')}
              labelTooltipText={translations('import_restaurants_from_csv_tooltip')}
              inputId="csvFile"
              validationError={(errors.csvFile as FieldError)?.message}
            >
              <FileInput accept="text/csv" register={register('csvFile')} aria-label="csvFile" />
            </FormGroup>
          </Flex>
          {/* <Tooltip*/}
          {/*  isOpen*/}
          {/*  placement="top"*/}
          {/*  hasArrow*/}
          {/*  label={*/}
          {/*    fieldsWithErrors.length || headersWithErrors.length ? (*/}
          {/*      <Stack>*/}
          {/*        <Text>*/}
          {/*          {translations('import_restaurants_create_restaurants_button_label1', {*/}
          {/*            '{{number}}': (fieldsWithErrors.length + headersWithErrors.length).toString(),*/}
          {/*          })}*/}
          {/*        </Text>*/}
          {/*        <Text>{translations('import_restaurants_create_restaurants_button_label2')}</Text>*/}
          {/*      </Stack>*/}
          {/*    ) : null*/}
          {/*  }*/}
          {/* >*/}
          {/*  <div>*/}
          <Button
            mt={5}
            // isDisabled={!importedRestaurants.length || !!fieldsWithErrors.length || !!headersWithErrors.length}
            isDisabled={!importedRestaurants.length}
            onClick={handleCreateRestaurants}
          >
            {/* {(!!fieldsWithErrors.length || !!headersWithErrors.length) && (*/}
            {/*  <WarningIcon stroke="none" color={colors.orange[500]} mr={2} />*/}
            {/* )}*/}
            {translations('import_restaurants_create_button')}
          </Button>
          {/*  </div>*/}
          {/* </Tooltip>*/}
        </Flex>
      </form>
      <Table
        isLoading={isLoading || isCsvLoading}
        data={importedRestaurants}
        columns={columns as Column<RestaurantDetailsResponse>[]}
      />
      <Modal isModalVisible={isEditModalVisible} onModalClose={onModalClose} customModalFooter={null} maxWidth="50%">
        <EditImportedRestaurant data={selectedRow} onSubmit={onEditImportedRestaurant} onCancel={onModalClose} />
      </Modal>
    </MainPageContent>
  );
};

export default ImportRestaurants;
