import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import invariant from 'tiny-invariant';

import { ErrorMessages, FullPageLoader } from '../../../../common/components';
import { ChildPageHeading } from '../../../../common/components/ChildPageHeading';
import { Routes } from '../../../../config/routes';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReusableTip, useUpdateReusableTip } from '../../../../services/reusable-tips';
import { ReusableTipForm } from '../ReusableTipForm/ReusableTipForm';

export const UpdateReusableTip = () => {
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const { data: reusableTip, isLoading: isLoadingReusableTip, error: loadReusableTipError } = useReusableTip(id);

  const navigate = useNavigate();
  const translations = useTranslations();

  const {
    mutate: updateReusableTip,
    isLoading: isUpdatingReusableTip,
    error: updateReusableTipError,
  } = useUpdateReusableTip();

  if (isLoadingReusableTip) {
    return (
      <FullPageLoader show>
        <Box h={100} />
      </FullPageLoader>
    );
  }

  return (
    <>
      <ChildPageHeading>{translations('reusable_tips_update_header')}</ChildPageHeading>
      <ErrorMessages errors={[updateReusableTipError, loadReusableTipError]} />
      <ReusableTipForm
        isLoading={isUpdatingReusableTip}
        onSubmit={(request) =>
          updateReusableTip(
            { id, request },
            {
              onSuccess: () => navigate(Routes.REUSABLE_TIPS_PATH),
            },
          )
        }
        model={reusableTip}
      />
    </>
  );
};
