import React from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { ErrorMessages, FullPageLoader } from '../../../../common/components';
import { ChildPageHeading } from '../../../../common/components/ChildPageHeading';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useCountryPricing } from '../../../../services/countries';
import { CountryPricingTable } from '../CountryPricingTable/CountryPricingTable';

export const CountryPricing = () => {
  const translations = useTranslations();
  const { id } = useParams<{ id: string }>();
  invariant(id, 'id is not set within the route');
  const {
    data: countryPricing,
    isLoading: isLoadingPricing,
    isFetching: isFetchingPricing,
    error: countryPricingError,
  } = useCountryPricing(id);

  return (
    <FullPageLoader show={isLoadingPricing}>
      <ChildPageHeading>
        {translations('countries_country_pricing_header', {
          '{{country}}': countryPricing?.country.translations.en || '',
        })}
      </ChildPageHeading>
      <ErrorMessages errors={[countryPricingError]} />
      {countryPricing ? <CountryPricingTable countryPricing={countryPricing} isLoading={isFetchingPricing} /> : null}
    </FullPageLoader>
  );
};
