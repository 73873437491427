import apiPaths from 'config/apiPaths';

import {
  LostItemsRequest,
  MarkItemsAsLostResponse,
  UndoMarkItemsAsLostResponse,
} from '../modules/LostItems/model/lost-items.model';
import axiosInstance from './axios';

export const markItemsAsLost = (lostItemsRequest: LostItemsRequest): Promise<MarkItemsAsLostResponse> =>
  axiosInstance
    .post(apiPaths.RESTAURANTS_LOST_ITEMS, {
      items: lostItemsRequest.items,
      inventoryCountsIds: lostItemsRequest.inventoryCountsIds,
    })
    .then(({ data }) => data);

export const undoMarkItemsAsLost = (lostItemsRequest: LostItemsRequest): Promise<UndoMarkItemsAsLostResponse> =>
  axiosInstance
    .post(apiPaths.RESTAURANTS_LOST_ITEMS_UNDO, {
      items: lostItemsRequest.items,
    })
    .then(({ data }) => data);
