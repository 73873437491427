import React, { JSX } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter } from 'common/components';
import { useTranslations } from 'contexts/LocalizationContext';

import FormInput from '../../common/components/FormInput/FormInput';
import { Language } from '../../common/utils';
import { TagFormValues, TagRequest } from './models/tag-request';
import { TagResponse } from './models/tag-response';
import { useTagFormValidationSchema } from './TagForm.form';

type TagFormProps = {
  isLoading: boolean;
  onSubmit: (request: TagRequest) => void;
  model?: TagResponse;
};

const TagForm = ({ isLoading, onSubmit, model }: TagFormProps): JSX.Element => {
  const translations = useTranslations();

  const validationSchema = useTagFormValidationSchema();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<TagFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { translationKey: translations('tags_form_translation_key_prefix') },
  });

  React.useEffect(() => {
    reset(model);
  }, [reset, model]);

  const onFormSubmit = handleSubmit(onSubmit);

  return (
    <form onSubmit={onFormSubmit}>
      <FormInput
        id={`translationKey`}
        label={translations(`tags_form_translation_key`)}
        validationError={errors.translationKey ? errors.translationKey?.message : undefined}
        useFormRegisterReturn={register(`translationKey`)}
        setValue={setValue}
        labelTooltipText={translations('tags_form_translation_key_tooltip')}
      />
      {model ? (
        <FormInput
          id={`translations.${Language.En}`}
          label={translations('tags_form_name_en')}
          validationError={errors.translations ? errors.translations[Language.En]?.message : undefined}
          useFormRegisterReturn={register(`translations.${Language.En}`)}
          isDisabled={true}
          setValue={setValue}
        />
      ) : null}
      <FormFooter isLoadingSubmitResult={isLoading} />
    </form>
  );
};

export default TagForm;
