import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useToast } from 'common/components/Toast/useToast.hook';
import { useTranslations } from 'contexts/LocalizationContext';

import { markItemsAsLost, undoMarkItemsAsLost } from '../api/lost-items';
import { HttpException } from '../common/models/httpException';
import {
  LostItemsRequest,
  MarkItemsAsLostResponse,
  UndoMarkItemsAsLostResponse,
} from '../modules/LostItems/model/lost-items.model';
import { CK_INVENTORY_COUNTS } from './inventory-counts';
import { CK_PRODUCTS } from './products';

export const useMarkItemsAsLost = ({
  onSuccessfulMark,
}: {
  onSuccessfulMark: (data: MarkItemsAsLostResponse) => void;
}): UseMutationResult<MarkItemsAsLostResponse, AxiosError<HttpException>, LostItemsRequest> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const queryClient = useQueryClient();

  return useMutation((data) => markItemsAsLost(data), {
    onSuccess: async (data) => {
      onSuccessfulMark(data);
      await queryClient.invalidateQueries(CK_INVENTORY_COUNTS);
    },
    onError: (error) => {
      const toastMessage = error.response?.data.message
        ? error.response?.data.message
        : translations('lost_items_api_error');
      displayToast('error', toastMessage);
    },
  });
};

export const useUndoMarkItemsAsLost = ({
  onSuccessfulMark,
}: {
  onSuccessfulMark: (data: UndoMarkItemsAsLostResponse) => void;
}): UseMutationResult<UndoMarkItemsAsLostResponse, AxiosError<HttpException>, LostItemsRequest> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const queryClient = useQueryClient();

  return useMutation((data) => undoMarkItemsAsLost(data), {
    onSuccess: (data) => {
      onSuccessfulMark(data);
      queryClient.invalidateQueries(CK_PRODUCTS);
    },
    onError: (error) => {
      const toastMessage = error.response?.data.message
        ? error.response?.data.message
        : translations('lost_items_mark_as_active_api_error');
      displayToast('error', toastMessage);
    },
  });
};
