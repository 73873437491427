import * as yup from 'yup';

import { useTranslations } from '../../../../../contexts/LocalizationContext';

export const useCountryFormValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    translationKey: yup
      .string()
      .required(translations('validation_required'))
      .max(255, translations('validation_too_long')),
  });
};
