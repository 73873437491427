import { convertFeeInMainUnitToMinUnit, convertFeeInMinUnitToMainUnit } from '../../../../common/utils/fee.helper';
import { BillingInformationResponse } from '../models/billing-information-response';
import { RestaurantBillingInformationFormValues } from '../models/restaurant-billing-information-form-values';
import { RestaurantBillingInformationUpdateRequest } from '../models/restaurant-billing-information-update-request';

export const getNumberFormValue = (value: number | null | undefined): string | null => {
  return value || value === 0 ? value.toString() : null;
};

export const convertFormValuesToUpdateRequest = (
  form: RestaurantBillingInformationFormValues,
): RestaurantBillingInformationUpdateRequest => {
  return {
    ...form,
    minMonthlyRevenueFee:
      form.minMonthlyRevenueFee !== null && !isNaN(form.minMonthlyRevenueFee)
        ? convertFeeInMainUnitToMinUnit(form.minMonthlyRevenueFee)
        : null,
    monthlySoftwareFee:
      form.monthlySoftwareFee !== null && !isNaN(form.monthlySoftwareFee)
        ? convertFeeInMainUnitToMinUnit(form.monthlySoftwareFee)
        : null,
    setupFee: form.setupFee !== null && !isNaN(form.setupFee) ? convertFeeInMainUnitToMinUnit(form.setupFee) : null,
    payPerUseFee: {
      bowls:
        (form.payPerUseFee?.bowls || form.payPerUseFee?.bowls === 0) && !isNaN(form.payPerUseFee.bowls)
          ? convertFeeInMainUnitToMinUnit(form.payPerUseFee.bowls)
          : null,
      cups:
        (form.payPerUseFee?.cups || form.payPerUseFee?.cups === 0) && !isNaN(form.payPerUseFee.cups)
          ? convertFeeInMainUnitToMinUnit(form.payPerUseFee.cups)
          : null,
      pizza:
        (form.payPerUseFee?.pizza || form.payPerUseFee?.pizza === 0) && !isNaN(form.payPerUseFee.pizza)
          ? convertFeeInMainUnitToMinUnit(form.payPerUseFee.pizza)
          : null,
    },
    lossFee: {
      bowls:
        (form.lossFee?.bowls || form.lossFee?.bowls === 0) && !isNaN(form.lossFee.bowls)
          ? convertFeeInMainUnitToMinUnit(form.lossFee.bowls)
          : null,
      cups:
        (form.lossFee?.cups || form.lossFee?.cups === 0) && !isNaN(form.lossFee.cups)
          ? convertFeeInMainUnitToMinUnit(form.lossFee.cups)
          : null,
      pizza:
        (form.lossFee?.pizza || form.lossFee?.pizza === 0) && !isNaN(form.lossFee.pizza)
          ? convertFeeInMainUnitToMinUnit(form.lossFee.pizza)
          : null,
    },
    baselineInventory: {
      bowls:
        (form.baselineInventory?.bowls || form.baselineInventory?.bowls === 0) && !isNaN(form.baselineInventory.bowls)
          ? Number(form.baselineInventory.bowls)
          : null,
      cups:
        (form.baselineInventory?.cups || form.baselineInventory?.cups === 0) && !isNaN(form.baselineInventory.cups)
          ? Number(form.baselineInventory.cups)
          : null,
      pizza:
        (form.baselineInventory?.pizza || form.baselineInventory?.pizza === 0) && !isNaN(form.baselineInventory.pizza)
          ? Number(form.baselineInventory.pizza)
          : null,
    },
    minTransactions: {
      bowls:
        (form.minTransactions?.bowls || form.minTransactions?.bowls === 0) && !isNaN(form.minTransactions.bowls)
          ? Number(form.minTransactions.bowls)
          : null,
      cups:
        (form.minTransactions?.cups || form.minTransactions?.cups === 0) && !isNaN(form.minTransactions.cups)
          ? Number(form.minTransactions.cups)
          : null,
      pizza:
        (form.minTransactions?.pizza || form.minTransactions?.pizza === 0) && !isNaN(form.minTransactions.pizza)
          ? Number(form.minTransactions.pizza)
          : null,
    },
    cityId: form.city?.id || null,
    businessName: form.businessName?.length ? form.businessName : null,
    street: form.street?.length ? form.street : null,
    houseNumber: form.houseNumber?.length ? form.houseNumber : null,
    careOf: form.careOf?.length ? form.careOf : null,
    postalCode: form.postalCode?.length ? form.postalCode : null,
    corporateTaxId: form.corporateTaxId?.length ? form.corporateTaxId : null,
    internalReferenceId: form.internalReferenceId?.length ? form.internalReferenceId : null,
    logicalInventoryIncrements: form.logicalInventoryIncrements !== null ? form.logicalInventoryIncrements : null,
  };
};

export const convertResponseToFormValues = (response: BillingInformationResponse) => {
  return {
    ...response,
    minMonthlyRevenueFee:
      response.minMonthlyRevenueFee !== null && !isNaN(response.minMonthlyRevenueFee)
        ? convertFeeInMinUnitToMainUnit(response.minMonthlyRevenueFee)
        : null,
    monthlySoftwareFee:
      response.monthlySoftwareFee !== null && !isNaN(response.monthlySoftwareFee)
        ? convertFeeInMinUnitToMainUnit(response.monthlySoftwareFee)
        : null,
    setupFee:
      response.setupFee !== null && !isNaN(response.setupFee) ? convertFeeInMinUnitToMainUnit(response.setupFee) : null,
    payPerUseFee: {
      bowls:
        (response.payPerUseFee?.bowls || response.payPerUseFee?.bowls === 0) && !isNaN(response.payPerUseFee?.bowls)
          ? convertFeeInMinUnitToMainUnit(response.payPerUseFee.bowls)
          : null,
      cups:
        (response.payPerUseFee?.cups || response.payPerUseFee?.cups === 0) && !isNaN(response.payPerUseFee?.cups)
          ? convertFeeInMinUnitToMainUnit(response.payPerUseFee.cups)
          : null,
      pizza:
        (response.payPerUseFee?.pizza || response.payPerUseFee?.pizza === 0) && !isNaN(response.payPerUseFee?.pizza)
          ? convertFeeInMinUnitToMainUnit(response.payPerUseFee.pizza)
          : null,
    },
    lossFee: {
      bowls:
        (response.lossFee?.bowls || response.lossFee?.bowls === 0) && !isNaN(response.lossFee?.bowls)
          ? convertFeeInMinUnitToMainUnit(response.lossFee.bowls)
          : null,
      cups:
        (response.lossFee?.cups || response.lossFee?.cups === 0) && !isNaN(response.lossFee?.cups)
          ? convertFeeInMinUnitToMainUnit(response.lossFee.cups)
          : null,
      pizza:
        (response.lossFee?.pizza || response.lossFee?.pizza === 0) && !isNaN(response.lossFee?.pizza)
          ? convertFeeInMinUnitToMainUnit(response.lossFee.pizza)
          : null,
    },
  };
};
