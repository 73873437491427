import { generatePath } from 'react-router-dom';

import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { CreateReusableTipRequest } from '../modules/ReusableTips/models/create-reusable-tip-request';
import { ReusableTipResponse } from '../modules/ReusableTips/models/reusable-tip-response';
import { UpdateReusableTipRequest } from '../modules/ReusableTips/models/update-reusable-tip-request';
import { UpdateReusableTipsOrderRequest } from '../modules/ReusableTips/models/update-reusable-tips-order-request';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const getReusableTips = async ({
  searchText,
  offset,
  limit,
}: BaseQueryParams): Promise<Paginated<ReusableTipResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  const { data } = await axiosInstance.get<Paginated<ReusableTipResponse>>(apiPaths.REUSABLE_TIPS, {
    params: searchParams,
  });
  return data;
};

export const getReusableTip = async (reusableTipId: string): Promise<ReusableTipResponse> => {
  const { data } = await axiosInstance.get<ReusableTipResponse>(
    generatePath(apiPaths.REUSABLE_TIPS_REUSABLE_TIP, { reusableTipId }),
  );
  return data;
};

export const createReusableTip = (createRequest: CreateReusableTipRequest): Promise<ReusableTipResponse> => {
  const data = createFormData(createRequest);
  return axiosInstance.post(apiPaths.REUSABLE_TIPS, data);
};

export const updateReusableTip = (
  reusableTipId: string,
  updateRequest: UpdateReusableTipRequest,
): Promise<ReusableTipResponse> => {
  const data = createFormData(updateRequest);
  return axiosInstance.patch(generatePath(apiPaths.REUSABLE_TIPS_REUSABLE_TIP, { reusableTipId }), data);
};

export const updateReusableTipsOrder = (
  updateReusableTipsOrderRequest: UpdateReusableTipsOrderRequest,
): Promise<void> => {
  return axiosInstance.put(apiPaths.REUSABLE_TIPS_REORDER, updateReusableTipsOrderRequest);
};

export const deleteReusableTip = (reusableTipId: string): Promise<void> => {
  return axiosInstance.delete(generatePath(apiPaths.REUSABLE_TIPS_REUSABLE_TIP, { reusableTipId }));
};
