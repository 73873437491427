import React, { useMemo } from 'react';
import ReactDomServer from 'react-dom/server';
import { Button, ModalFooter, Text } from '@chakra-ui/react';

import { Modal, ModalType } from '../../../../common/components';
import { ItemsSummarySectionModalBody } from '../../../../common/components/ItemsSummaryModal/ItemsSummarySectionModalBody';
import { useHtmlTranslations } from '../../../../contexts/LocalizationContext/hooks';
import { LostItemsFormActionType } from '../../model/enums/lost-items-form-action-type.enum';
import {
  InventoryCountFailedResponse,
  InventoryCountSuccessResponse,
  ItemFailedResponse,
  ItemSuccessResponse,
} from '../../model/lost-items.model';
import { LostItemsResult } from '../../model/lost-items-result';

type LostSummaryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  action: LostItemsFormActionType;
  items?: LostItemsResult;
};

export const LostSummaryModal = ({ isOpen, onClose, action, items }: LostSummaryModalProps) => {
  const translations = useHtmlTranslations();

  const lostItemsColumns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('product_u_id'),
        accessor: 'uId' as const,
      },
      {
        id: '2',
        Header: translations('error'),
        accessor: 'message' as const,
      },
    ],
    [translations],
  );

  const inventoryCountsColumns = useMemo(
    () => [
      {
        id: '1',
        Header: translations('lost_items_inventory_count_id'),
        accessor: 'id' as const,
      },
      {
        id: '2',
        Header: translations('error'),
        accessor: 'message' as const,
      },
    ],
    [translations],
  );

  const modalHeader = useMemo(
    () =>
      action === LostItemsFormActionType.MARK_AS_LOST
        ? translations('lost_items_summary_modal_header')
        : translations('lost_items_mark_as_active_summary_modal_header'),
    [action, translations],
  );

  const lostItemsFailureText = useMemo(
    () =>
      action === LostItemsFormActionType.MARK_AS_LOST
        ? translations('lost_items_summary_modal_failure_content')
        : translations('lost_items_mark_as_active_summary_modal_failure_content'),
    [action, translations],
  );

  const lostItemsSuccessTextArgs = useMemo(() => {
    return {
      '{{success-items-number}}': ReactDomServer.renderToString(
        <Text as="b">{(items?.successItems.length || 0).toString()}</Text>,
      ),
      '{{items-number}}': ReactDomServer.renderToString(
        <Text as="b">{((items?.successItems.length || 0) + (items?.failureItems.length || 0)).toString()}</Text>,
      ),
    };
  }, [items?.failureItems.length, items?.successItems.length]);

  const lostItemsSuccessText = useMemo(
    () =>
      action === LostItemsFormActionType.MARK_AS_LOST
        ? translations('lost_items_summary_modal_success_content', lostItemsSuccessTextArgs)
        : translations('lost_items_mark_as_active_summary_modal_success_content', lostItemsSuccessTextArgs),
    [action, lostItemsSuccessTextArgs, translations],
  );

  const inventoryCountsFailureText = translations(
    'lost_items_inventory_counts_mark_as_finished_summary_modal_failure_content',
  );

  const inventoryCountsSuccessTextArgs = useMemo(() => {
    return {
      '{{success-inventory-counts-number}}': ReactDomServer.renderToString(
        <Text as="b">{(items?.successInventoryCounts?.length || 0).toString()}</Text>,
      ),
      '{{inventory-counts-number}}': ReactDomServer.renderToString(
        <Text as="b">
          {((items?.successInventoryCounts?.length || 0) + (items?.failureInventoryCounts?.length || 0)).toString()}
        </Text>,
      ),
    };
  }, [items?.failureInventoryCounts?.length, items?.successInventoryCounts?.length]);

  const inventoryCountsSuccessText = useMemo(
    () =>
      translations(
        'lost_items_inventory_counts_mark_as_finished_summary_modal_success_content',
        inventoryCountsSuccessTextArgs,
      ),
    [inventoryCountsSuccessTextArgs, translations],
  );

  const showInventoryCountsSummary = useMemo(
    () =>
      action === LostItemsFormActionType.MARK_AS_LOST &&
      (!!items?.successInventoryCounts?.length || !!items?.failureInventoryCounts?.length),
    [action, items?.failureInventoryCounts?.length, items?.successInventoryCounts?.length],
  );

  return (
    <Modal
      modalHeader={modalHeader}
      size="xl"
      isModalVisible={isOpen}
      onModalClose={onClose}
      customModalFooter={
        <ModalFooter justifyContent="flex-start">
          <Button colorScheme="orange" onClick={onClose}>
            {translations('ok')}
          </Button>
        </ModalFooter>
      }
      type={ModalType.INFO}
    >
      <ItemsSummarySectionModalBody<ItemFailedResponse, ItemSuccessResponse>
        columns={lostItemsColumns}
        successText={lostItemsSuccessText}
        failureText={lostItemsFailureText}
        successItems={items?.successItems}
        failureItems={items?.failureItems}
      />
      {showInventoryCountsSummary ? (
        <ItemsSummarySectionModalBody<InventoryCountFailedResponse, InventoryCountSuccessResponse>
          columns={inventoryCountsColumns}
          successText={inventoryCountsSuccessText}
          failureText={inventoryCountsFailureText}
          successItems={items?.successInventoryCounts}
          failureItems={items?.failureInventoryCounts}
        />
      ) : null}
    </Modal>
  );
};
