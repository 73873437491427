import { generatePath } from 'react-router-dom';

import { Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { InventoryCountStatus } from '../modules/Restaurants/InventoryCounts/models/enums/inventory-count-status.enum';
import { InventoryCountResponse } from '../modules/Restaurants/InventoryCounts/models/inventory-count-response';
import { InventoryCountUpdateRequest } from '../modules/Restaurants/InventoryCounts/models/inventory-count-update-request';
import { createFormData } from './api-utils';
import axiosInstance from './axios';

export const getInventoryCounts = async (
  limit: number,
  offset: number,
  statuses: InventoryCountStatus[],
): Promise<Paginated<InventoryCountResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit);
  statuses.forEach((status) => searchParams.append('status[]', status));
  const { data } = await axiosInstance.get(apiPaths.INVENTORY_COUNTS, { params: searchParams });
  return data;
};

export const getRestaurantInventoryCounts = async (
  restaurantId: string,
  limit: number,
  offset: number,
): Promise<Paginated<InventoryCountResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit);
  const { data } = await axiosInstance.get(generatePath(apiPaths.RESTAURANTS_INVENTORY_COUNTS, { restaurantId }), {
    params: searchParams,
  });
  return data;
};

export const updateInventoryCount = (
  inventoryCountId: string,
  values: InventoryCountUpdateRequest,
): Promise<InventoryCountResponse> => {
  const formData = createFormData(values);
  return axiosInstance.patch(
    generatePath(apiPaths.RESTAURANTS_INVENTORY_COUNTS_INVENTORY_COUNT, { inventoryCountId }),
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export const deleteInvoice = (inventoryCountId: string): Promise<void> =>
  axiosInstance.delete(
    generatePath(apiPaths.RESTAURANTS_INVENTORY_COUNTS_INVENTORY_COUNT_INVOICES, { inventoryCountId }),
  );
