import React, { ComponentProps, useCallback } from 'react';
import Select, { SingleValue } from 'react-select';

import { useLocalizationContext } from '../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { useCountries } from '../../../../../services/countries';
import { CountryResponse } from '../../../../Countries/model/country-response';

type CountrySelectProps = Omit<
  ComponentProps<typeof Select>,
  'options' | 'isLoading' | 'getOptionLabel' | 'getOptionValue' | 'placeholder' | 'onChange'
> & {
  selectedValue: CountryResponse | null;
  onSelect: (value: CountryResponse | null) => void;
};

export const CountrySelect = ({ selectedValue, onSelect }: CountrySelectProps) => {
  const { data: paginatedCountries, isLoading: isLoadingCountries, isFetching: isFetchingCountries } = useCountries();
  const { language: languageFromContext } = useLocalizationContext();
  const handleChange = useCallback((value: SingleValue<CountryResponse>) => onSelect(value), [onSelect]);
  const handleOptionLabel = useCallback(
    (option: CountryResponse) => option.translations[languageFromContext],
    [languageFromContext],
  );
  const handleOptionValue = useCallback((option: CountryResponse) => option.id, []);
  return (
    <Select<CountryResponse>
      isLoading={isLoadingCountries || isFetchingCountries}
      getOptionLabel={handleOptionLabel}
      getOptionValue={handleOptionValue}
      onChange={handleChange}
      value={paginatedCountries?.items.find((option) => option.id === selectedValue?.id)}
      options={paginatedCountries?.items}
      menuPlacement={'auto'}
    />
  );
};
