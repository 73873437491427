import React, { useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormGroup, NumberInput, useToast } from '../../../../../common/components';
import FormInput from '../../../../../common/components/FormInput/FormInput';
import { Currency } from '../../../../../common/models/enums/currency.enum';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useUpdateRestaurantBillingInformation } from '../../../../../services/restaurant-billing-information';
import { CategoryGroup } from '../../../../Categories/model/Category.model';
import { mapCategoryGroupToTranslation } from '../../../../Categories/utils/category.helper';
import { CountryResponse } from '../../../../Countries/model/country-response';
import { CategoryGroupCard } from '../../common/components/CategoryGroupCard/CategoryGroupCard';
import { CategoryGroupNumber } from '../../models/billing-information-response';
import { RestaurantBillingInformationFormValues } from '../../models/restaurant-billing-information-form-values';
import {
  RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE,
  RESTAURANT_BILLING_INFORMATION_FEE_MIN_VALUE,
  RESTAURANT_BILLING_INFORMATION_FEE_PRECISION,
  RESTAURANT_BILLING_INFORMATION_FEE_STEP,
  RESTAURANT_BILLING_INFORMATION_QUANTITY_MIN_VALUE,
  RESTAURANT_BILLING_INFORMATION_QUANTITY_PRECISION,
  RESTAURANT_BILLING_INFORMATION_QUANTITY_STEP,
} from '../../utils/restaurant-billing-information.consts';
import {
  convertFormValuesToUpdateRequest,
  getNumberFormValue,
} from '../../utils/restaurant-billing-information.helper';
import { CitySelect } from '../CitySelect/CitySelect';
import { ContractTypeSelect } from '../ContractTypeSelect/ContractTypeSelect';
import { CountrySelect } from '../CountrySelect/CountrySelect';
import { LossContractTypeSelect } from '../LossContractTypeSelect/LossContractTypeSelect';
import { useRestaurantBillingInformationFormValidation } from './hooks/use-restaurant-billing-information-form-validation.hook';

type UpdateRestaurantBillingInformationProps = {
  restaurantId: string;
  restaurantName?: string;
  currency?: Currency | null;
  model?: RestaurantBillingInformationFormValues;
};

export const UpdateRestaurantBillingInformation = ({
  restaurantId,
  restaurantName,
  currency,
  model,
}: UpdateRestaurantBillingInformationProps) => {
  const translations = useTranslations();
  const navigate = useNavigate();
  const { displayToast } = useToast();
  const validationSchema = useRestaurantBillingInformationFormValidation();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm<RestaurantBillingInformationFormValues>({
    resolver: yupResolver(validationSchema),
  });
  const { mutate: updateRestaurantBillingInformation, isLoading: isSavingRestaurantBillingInformation } =
    useUpdateRestaurantBillingInformation();

  useEffect(() => reset(model), [reset, model]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleFormSubmit = handleSubmit(({ ...form }: RestaurantBillingInformationFormValues) => {
    const request = convertFormValuesToUpdateRequest(form);
    updateRestaurantBillingInformation(
      {
        id: restaurantId,
        restaurantBillingInformationUpdateRequest: request,
      },
      {
        onSuccess: () => {
          navigate(-1);
          displayToast('success', translations('restaurant_billing_information_update_success'), false, 4000);
        },
      },
    );
  });

  const watchCountry = useWatch({ control, name: 'country' });
  const isCityDisabled = useMemo(
    () => (watchCountry ? !watchCountry : !model?.country),
    [model?.country, watchCountry],
  );
  const selectedCountry = useMemo(() => watchCountry ?? model?.country, [model?.country, watchCountry]);
  const handleCountryChange = (onChange: (e: unknown) => void) => {
    return (country: CountryResponse | null) => {
      onChange(country);
      setValue('city', null);
    };
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Flex textAlign="start" wrap="wrap" width="100%">
        <Box width="100%" bg={'grey.10'} p={2}>
          {restaurantName}
        </Box>
        <Box width={['100%', '100%', '100%', '25%']} px={1}>
          <FormGroup
            label={translations('restaurant_billing_information_contract_type_label')}
            inputId={'contractType'}
          >
            <Controller
              control={control}
              name="contractType"
              render={({ field: { value, name, onChange } }) => (
                <ContractTypeSelect selectedValue={value} name={name} onSelect={onChange} inputId={'contractType'} />
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['100%', '100%', '100%', '25%']} px={1}>
          <FormGroup
            label={translations('restaurant_billing_information_loss_contract_type_label')}
            inputId={'lossContractType'}
          >
            <Controller
              control={control}
              name="lossContractType"
              render={({ field: { value, name, onChange } }) => (
                <LossContractTypeSelect
                  selectedValue={value}
                  name={name}
                  onSelect={onChange}
                  inputId={'lossContractType'}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['100%', '100%', '100%', '50%']} px={1}>
          <FormInput
            id={`businessName`}
            label={translations('restaurant_billing_information_business_name_label')}
            validationError={errors.businessName?.message}
            useFormRegisterReturn={register(`businessName`)}
            setValue={setValue}
          />
        </Box>
        <Box width={['100%', '100%', '50%']} px={1}>
          <FormInput
            id={`corporateTaxId`}
            label={translations('restaurant_billing_information_corporate_tax_id_label')}
            validationError={errors.corporateTaxId?.message}
            useFormRegisterReturn={register(`corporateTaxId`)}
            setValue={setValue}
          />
        </Box>
        <Box width={['100%', '100%', '50%']} px={1}>
          <FormInput
            id={`internalReferenceId`}
            label={translations('restaurant_billing_information_internal_reference_id_label')}
            validationError={errors.internalReferenceId?.message}
            useFormRegisterReturn={register(`internalReferenceId`)}
            setValue={setValue}
          />
        </Box>
        <Box width={['100%', '100%', '50%', '25%']} px={1}>
          <FormGroup
            label={translations('restaurant_billing_information_min_monthly_revenue_fee_label')}
            inputId="minMonthlyRevenueFee"
            validationError={errors.minMonthlyRevenueFee?.message}
          >
            <Controller
              control={control}
              name="minMonthlyRevenueFee"
              render={({ field: { value, onChange } }) => (
                <InputGroup size="lg" fontSize={16}>
                  <InputLeftAddon fontSize={16} children={currency || ''} />
                  <NumberInput
                    step={RESTAURANT_BILLING_INFORMATION_FEE_STEP}
                    precision={RESTAURANT_BILLING_INFORMATION_FEE_PRECISION}
                    width={'100%'}
                    name="minMonthlyRevenueFee"
                    register={register('minMonthlyRevenueFee')}
                    min={RESTAURANT_BILLING_INFORMATION_FEE_MIN_VALUE}
                    value={getNumberFormValue(value) ?? RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE}
                    onChange={onChange}
                  />
                </InputGroup>
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['100%', '100%', '50%', '25%']} px={1}>
          <FormGroup
            label={translations('restaurant_billing_information_monthly_software_fee_label')}
            inputId="monthlySoftwareFee"
            validationError={errors.monthlySoftwareFee?.message}
          >
            <Controller
              control={control}
              name="monthlySoftwareFee"
              render={({ field: { value, onChange } }) => (
                <InputGroup size="lg" fontSize={16}>
                  <InputLeftAddon fontSize={16} children={currency || ''} />
                  <NumberInput
                    step={RESTAURANT_BILLING_INFORMATION_FEE_STEP}
                    precision={RESTAURANT_BILLING_INFORMATION_FEE_PRECISION}
                    width={'100%'}
                    name="monthlySoftwareFee"
                    register={register('monthlySoftwareFee')}
                    min={RESTAURANT_BILLING_INFORMATION_FEE_MIN_VALUE}
                    value={getNumberFormValue(value) ?? RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE}
                    onChange={onChange}
                  />
                </InputGroup>
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['100%', '100%', '50%', '25%']} px={1}>
          <FormGroup
            label={translations('restaurant_billing_information_setup_fee_label')}
            inputId="setupFee"
            validationError={errors.setupFee?.message}
          >
            <Controller
              control={control}
              name="setupFee"
              render={({ field: { value, onChange } }) => (
                <InputGroup size="lg" fontSize={16}>
                  <InputLeftAddon fontSize={16} children={currency || ''} />
                  <NumberInput
                    step={RESTAURANT_BILLING_INFORMATION_FEE_STEP}
                    precision={RESTAURANT_BILLING_INFORMATION_FEE_PRECISION}
                    width={'100%'}
                    name="setupFee"
                    register={register('setupFee')}
                    min={RESTAURANT_BILLING_INFORMATION_FEE_MIN_VALUE}
                    value={getNumberFormValue(value) ?? RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE}
                    onChange={onChange}
                  />
                </InputGroup>
              )}
            />
          </FormGroup>
        </Box>
        <Box width={['100%', '100%', '50%', '25%']} px={1}>
          <FormGroup
            label={translations('restaurant_billing_information_logical_increments_label')}
            inputId="logicalInventoryIncrements"
            validationError={errors.logicalInventoryIncrements?.message}
          >
            <Controller
              control={control}
              name="logicalInventoryIncrements"
              render={({ field: { value, onChange } }) => (
                <InputGroup size="lg">
                  <NumberInput
                    step={RESTAURANT_BILLING_INFORMATION_QUANTITY_STEP}
                    precision={RESTAURANT_BILLING_INFORMATION_QUANTITY_PRECISION}
                    width={'100%'}
                    name="logicalInventoryIncrements"
                    register={register('logicalInventoryIncrements')}
                    min={RESTAURANT_BILLING_INFORMATION_QUANTITY_MIN_VALUE}
                    value={getNumberFormValue(value) ?? RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE}
                    onChange={onChange}
                  />
                </InputGroup>
              )}
            />
          </FormGroup>
        </Box>
        <CategoryGroupCard
          width={['100%', '100%', '100%', '50%']}
          header={translations('restaurant_billing_information_baseline_inventory_label')}
        >
          <>
            {Object.keys(CategoryGroup)
              .map((category) => category.toLowerCase() as keyof CategoryGroupNumber)
              .map((categoryGroupKey) => (
                <Box width={['100%', '100%', '33.33%', '30%']} px={1} key={categoryGroupKey}>
                  <FormGroup
                    key={categoryGroupKey}
                    label={translations(mapCategoryGroupToTranslation(categoryGroupKey.toUpperCase()))}
                    inputId={`baselineInventory.${categoryGroupKey}`}
                    validationError={errors.baselineInventory?.[categoryGroupKey]?.message}
                  >
                    <Controller
                      control={control}
                      name={`baselineInventory.${categoryGroupKey}`}
                      render={({ field: { value, onChange } }) => (
                        <InputGroup size="lg">
                          <NumberInput
                            step={RESTAURANT_BILLING_INFORMATION_QUANTITY_STEP}
                            precision={RESTAURANT_BILLING_INFORMATION_QUANTITY_PRECISION}
                            width={'100%'}
                            name={`baselineInventory.${categoryGroupKey}`}
                            register={register(`baselineInventory.${categoryGroupKey}`)}
                            min={RESTAURANT_BILLING_INFORMATION_QUANTITY_MIN_VALUE}
                            value={getNumberFormValue(value) ?? RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE}
                            onChange={onChange}
                          />
                        </InputGroup>
                      )}
                    />
                  </FormGroup>
                </Box>
              ))}
          </>
        </CategoryGroupCard>
        <CategoryGroupCard
          width={['100%', '100%', '100%', '50%']}
          header={translations('restaurant_billing_information_min_transactions_label')}
        >
          <>
            {Object.keys(CategoryGroup)
              .map((category) => category.toLowerCase() as keyof CategoryGroupNumber)
              .map((categoryGroupKey) => (
                <Box width={['100%', '100%', '33.33%', '30%']} px={1} key={categoryGroupKey}>
                  <FormGroup
                    key={categoryGroupKey}
                    label={translations(mapCategoryGroupToTranslation(categoryGroupKey.toUpperCase()))}
                    inputId={`minTransactions.${categoryGroupKey}`}
                    validationError={errors.minTransactions?.[categoryGroupKey]?.message}
                  >
                    <Controller
                      control={control}
                      name={`minTransactions.${categoryGroupKey}`}
                      render={({ field: { value, onChange } }) => (
                        <InputGroup size="lg">
                          <NumberInput
                            step={RESTAURANT_BILLING_INFORMATION_QUANTITY_STEP}
                            precision={RESTAURANT_BILLING_INFORMATION_QUANTITY_PRECISION}
                            width={'100%'}
                            name={`minTransactions.${categoryGroupKey}`}
                            register={register(`minTransactions.${categoryGroupKey}`)}
                            min={RESTAURANT_BILLING_INFORMATION_QUANTITY_MIN_VALUE}
                            value={getNumberFormValue(value) ?? RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE}
                            onChange={onChange}
                          />
                        </InputGroup>
                      )}
                    />
                  </FormGroup>
                </Box>
              ))}
          </>
        </CategoryGroupCard>
        <CategoryGroupCard
          width={['100%', '100%', '100%', '50%']}
          header={translations('restaurant_billing_information_pay_per_use_fee_label')}
        >
          <>
            {Object.keys(CategoryGroup)
              .map((category) => category.toLowerCase() as keyof CategoryGroupNumber)
              .map((categoryGroupKey) => (
                <Box width={['100%', '100%', '33.33%', '30%']} px={1} key={categoryGroupKey}>
                  <FormGroup
                    key={categoryGroupKey}
                    label={translations(mapCategoryGroupToTranslation(categoryGroupKey.toUpperCase()))}
                    inputId={`payPerUseFee.${categoryGroupKey}`}
                    validationError={errors.payPerUseFee?.[categoryGroupKey]?.message}
                  >
                    <Controller
                      control={control}
                      name={`payPerUseFee.${categoryGroupKey}`}
                      render={({ field: { value, onChange } }) => (
                        <InputGroup size="lg">
                          <InputLeftAddon fontSize={16} children={currency || ''} />
                          <NumberInput
                            step={RESTAURANT_BILLING_INFORMATION_FEE_STEP}
                            precision={RESTAURANT_BILLING_INFORMATION_FEE_PRECISION}
                            width={'100%'}
                            name={`payPerUseFee.${categoryGroupKey}`}
                            register={register(`payPerUseFee.${categoryGroupKey}`)}
                            min={RESTAURANT_BILLING_INFORMATION_FEE_MIN_VALUE}
                            value={getNumberFormValue(value) ?? RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE}
                            onChange={onChange}
                          />
                        </InputGroup>
                      )}
                    />
                  </FormGroup>
                </Box>
              ))}
          </>
        </CategoryGroupCard>
        <CategoryGroupCard
          width={['100%', '100%', '100%', '50%']}
          header={translations('restaurant_billing_information_loss_fee_label')}
        >
          <>
            {Object.keys(CategoryGroup)
              .map((category) => category.toLowerCase() as keyof CategoryGroupNumber)
              .map((categoryGroupKey) => (
                <Box width={['100%', '100%', '33.33%', '30%']} px={1} key={categoryGroupKey}>
                  <FormGroup
                    key={categoryGroupKey}
                    label={translations(mapCategoryGroupToTranslation(categoryGroupKey.toUpperCase()))}
                    inputId={`lossFee.${categoryGroupKey}`}
                    validationError={errors.lossFee?.[categoryGroupKey]?.message}
                  >
                    <Controller
                      control={control}
                      name={`lossFee.${categoryGroupKey}`}
                      render={({ field: { value, onChange } }) => (
                        <InputGroup size="lg">
                          <InputLeftAddon fontSize={16} children={currency || ''} />
                          <NumberInput
                            step={RESTAURANT_BILLING_INFORMATION_FEE_STEP}
                            precision={RESTAURANT_BILLING_INFORMATION_FEE_PRECISION}
                            width={'100%'}
                            name={`lossFee.${categoryGroupKey}`}
                            register={register(`lossFee.${categoryGroupKey}`)}
                            min={RESTAURANT_BILLING_INFORMATION_FEE_MIN_VALUE}
                            value={getNumberFormValue(value) ?? RESTAURANT_BILLING_INFORMATION_EMPTY_VALUE}
                            onChange={onChange}
                          />
                        </InputGroup>
                      )}
                    />
                  </FormGroup>
                </Box>
              ))}
          </>
        </CategoryGroupCard>
        <CategoryGroupCard width="100%" header={translations('restaurant_billing_information_address_label')}>
          <>
            <Box width={['100%', '50%', '50', '25%']} px={1}>
              <FormGroup
                label={translations('restaurant_billing_information_address_country_label')}
                inputId={'country'}
              >
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { value, name, onChange } }) => (
                    <CountrySelect name={name} selectedValue={value} onSelect={handleCountryChange(onChange)} />
                  )}
                ></Controller>
              </FormGroup>
            </Box>
            <Box width={['100%', '50%', '50%', '25%']} px={1}>
              <FormInput
                id={`careOf`}
                label={translations('restaurant_billing_information_address_c_o_label')}
                validationError={errors.careOf?.message}
                useFormRegisterReturn={register(`careOf`)}
                setValue={setValue}
              />
            </Box>
            <Box width={['100%', '50%', '50%', '35%']} px={1}>
              <FormInput
                id={`street`}
                label={translations('restaurant_billing_information_address_street_label')}
                validationError={errors.street?.message}
                useFormRegisterReturn={register(`street`)}
                setValue={setValue}
              />
            </Box>
            <Box width={['100%', '50%', '50%', '15%']} px={1}>
              <FormInput
                id={`houseNumber`}
                label={translations('restaurant_billing_information_address_house_number_label')}
                validationError={errors.houseNumber?.message}
                useFormRegisterReturn={register(`houseNumber`)}
                setValue={setValue}
              />
            </Box>
            <Box width={['100%', '50%', '50%', '25%']} px={1}>
              <FormInput
                id={`postalCode`}
                label={translations('restaurant_billing_information_address_postal_code_label')}
                validationError={errors.postalCode?.message}
                useFormRegisterReturn={register(`postalCode`)}
                setValue={setValue}
              />
            </Box>
            <Box width={['100%', '50%', '50%', '25%']} px={1}>
              <FormGroup
                label={translations('restaurant_billing_information_address_city_label')}
                inputId={'city'}
                labelTooltipText={translations('restaurant_billing_information_address_city_label_tooltip')}
              >
                <Controller
                  control={control}
                  name="city"
                  render={({ field: { value, name, onChange } }) => (
                    <CitySelect
                      isDisabled={isCityDisabled}
                      country={selectedCountry}
                      name={name}
                      selectedValue={value}
                      onSelect={onChange}
                    />
                  )}
                ></Controller>
              </FormGroup>
            </Box>
          </>
        </CategoryGroupCard>
      </Flex>
      <Flex justifyContent="flex-start" pt={1}>
        <Button mr={2} type="submit" isLoading={isSavingRestaurantBillingInformation}>
          {translations('save')}
        </Button>
        <Button onClick={handleCancel}>{translations('cancel')}</Button>
      </Flex>
    </form>
  );
};
