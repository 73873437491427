export enum Currency {
  EUR = 'EUR',
  CHF = 'CHF',
  PLN = 'PLN',
  DKK = 'DKK',
  SEK = 'SEK',
  GBP = 'GBP',
  NOK = 'NOK',
  CAD = 'CAD',
}
