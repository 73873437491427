import { Currency } from '../../../../common/models/enums/currency.enum';
import { RestaurantDetailsResponse, RestaurantRequest } from '../../models';
import { CLASSIC_META_CATEGORY_ID, INDIVIDUAL_KEY_ACCOUNT_ID, RELEVO_CLOSING_SOURCE_ID } from '../../restaurant.consts';

export const restaurantRequestToRestaurantResponseMapper = (
  id: string,
  restaurant: RestaurantRequest,
): RestaurantDetailsResponse => {
  return {
    ...restaurant,
    id: id,
    restaurantName: restaurant.restaurantName || '',
    city: restaurant.cityId
      ? {
          id: restaurant.cityId,
          translationKey: '',
          translations: { en: '', de: '', fr: '', nl: '', se: '', dk: '', fi: '', no: '', pl: '', it: '' },
          country: {
            id: '',
            countryCode: '',
            translationKey: '',
            translations: { en: '', de: '', fr: '', nl: '', se: '', dk: '', fi: '', no: '', pl: '', it: '' },
            currency: Currency.EUR,
          },
        }
      : undefined,
    tags: restaurant.tags?.map((tagId) => {
      return {
        id: tagId,
        translationKey: '',
        translations: { en: '', de: '', fr: '', nl: '', se: '', dk: '', fi: '', no: '', pl: '', it: '' },
      };
    }),
    promotions: restaurant.promotions?.map((promotionId) => {
      return {
        id: promotionId,
        translations: { en: '', de: '', fr: '', nl: '', se: '', dk: '', fi: '', no: '', pl: '', it: '' },
      };
    }),
    metaCategories: restaurant.metaCategories?.map((metaCategoryId) => {
      return {
        id: metaCategoryId,
        translationKey: '',
        translations: { en: '', de: '', fr: '', nl: '', se: '', dk: '', fi: '', no: '', pl: '', it: '' },
        image: null,
        type: null,
        color: null,
      };
    }) || [
      {
        id: '1',
        translationKey: '',
        translations: { en: '', de: '', fr: '', nl: '', se: '', dk: '', fi: '', no: '', pl: '', it: '' },
        image: null,
        type: null,
        color: null,
      },
    ],
  };
};

export const restaurantResponseToRestaurantRequestMapper = (
  restaurant: RestaurantDetailsResponse,
): RestaurantRequest => {
  const { city, id, closingSource, ...data } = restaurant;
  return {
    ...data,
    starterSetStartDate: restaurant.starterSetStartDate?.length ? restaurant.starterSetStartDate : undefined,
    starterSetSize: restaurant.starterSetSize?.length ? restaurant.starterSetSize : undefined,
    restaurantName: restaurant.restaurantName,
    ...(city?.id ? { cityId: city?.id } : { cityId: undefined }),
    ...(closingSource?.id ? { closingSourceId: closingSource?.id } : { closingSourceId: RELEVO_CLOSING_SOURCE_ID }),
    tags: restaurant.tags?.map((tag) => tag.id),
    promotions: restaurant.promotions?.map((promotion) => promotion.id),
    metaCategories: restaurant.metaCategories?.map((metaCategory) => metaCategory.id) || [CLASSIC_META_CATEGORY_ID],
    keyAccountId: restaurant.keyAccountId || INDIVIDUAL_KEY_ACCOUNT_ID,
    restaurantOpeningHours: restaurant.restaurantOpeningHours,
    overrideOpeningHours: restaurant.overrideOpeningHours,
    latitude: restaurant.latitude || undefined,
    longitude: restaurant.longitude || undefined,
  };
};
