export enum Routes {
  HOME_PATH = '/',
  WAREHOUSES_PATH = '/warehouses',
  CREATE_WAREHOUSE_PATH = '/warehouses/create',
  UPDATE_WAREHOUSE_PATH = '/warehouses/:id',
  RESTAURANTS_PATH = '/restaurants',
  CREATE_RESTAURANT_PATH = '/restaurants/create',
  UPDATE_RESTAURANT_PATH = '/restaurants/:id',
  RESTAURANT_RETURN_CODES_PATH = '/restaurants/:restaurantId/return-codes',
  CREATE_RESTAURANT_RETURN_CODES_PATH = '/restaurants/:restaurantId/return-codes/new',
  UPDATE_RESTAURANT_RETURN_CODES_PATH = '/restaurants/:restaurantId/return-codes/:id',
  DOWNLOAD_RESTAURANT_RETURN_CODES_PATH = '/restaurants/:restaurantId/return-codes/:id/download',
  IMPORT_RESTAURANTS_PATH = '/restaurants/import/csv',
  MASS_EDIT_RESTAURANTS_PATH = '/restaurants/mass/edit',
  RESTAURANT_TWO_FACTOR_RETURN_PATH = '/restaurants/:restaurantId/two-factor-return',
  RESTAURANT_DEPOSIT_PATH = '/restaurants/:restaurantId/deposit',
  RESTAURANT_INVENTORY_COUNTS_PATH = '/restaurants/:restaurantId/inventory-counts',
  RESTAURANT_IMAGES_PATH = '/restaurants/:restaurantId/images',
  RESTAURANT_BILLING_INFORMATION_PATH = '/restaurants/:restaurantId/billing',
  TAGS_PATH = '/tags',
  CREATE_TAG_PATH = '/tags/create',
  UPDATE_TAG_PATH = '/tags/:id',
  CITIES_PATH = '/cities',
  CREATE_CITY_PATH = '/cities/new',
  UPDATE_CITY_PATH = '/cities/:id',
  PROMOTIONS_PATH = '/promotions',
  CREATE_PROMOTION = '/promotions/new',
  UPDATE_PROMOTION = '/promotions/:id',
  NEWS_PATH = '/news',
  CREATE_NEWS_PATH = '/news/new',
  UPDATE_NEWS_PATH = '/news/:id',
  ABOUT_PATH = '/about-us',
  CREATE_ABOUT_PATH = '/about-us/new',
  UPDATE_ABOUT_PATH = '/about-us/:id',
  USERS_PATH = '/application-users',
  USERS_ASSIGNED_ITEMS_PATH = '/application-users/:userId/assigned-items',
  ASSIGNED_ITEMS_EXTEND_PATH = '/application-users/:userId/assigned-items/:assignedItemsId/extend',
  USERS_PROFILE_SETTINGS_PATH = '/application-users/:userId/settings',
  RELEVO_USERS_PATH = '/relevo-users',
  CREATE_RELEVO_USER_PATH = '/relevo-users/new',
  UPDATE_RELEVO_USER_PATH = '/relevo-users/:id',
  PAYMENTS_PATH = '/payments',
  SCRIPTS_PATH = '/scripts',
  CATEGORIES_PATH = '/categories',
  CREATE_CATEGORY_PATH = '/categories/new',
  UPDATE_CATEGORY_PATH = '/categories/:id',
  CORPORATES_PATH = '/corporates',
  CREATE_CORPORATE_PATH = '/corporates/new',
  UPDATE_CORPORATE_PATH = '/corporates/:id',
  PARTNERS_PATH = '/partners',
  CREATE_PARTNER_PATH = '/partners/new',
  CREATE_PARTNERS_BATCH_PATH = '/partners/new/batch',
  UPDATE_PARTNER_PATH = '/partners/:id',
  NOTIFICATIONS_PATH = '/push-notifications',
  REPORTS_PATH = '/reports',
  ASSIGN_ITEM = '/assign-item',
  LOST_ITEMS = '/lost-items',
  KEY_ACCOUNT_GROUPS_PATH = '/key-account-groups',
  CREATE_KEY_ACCOUNT_GROUP_PATH = '/key-account-groups/create',
  KEY_ACCOUNT_GROUP_RESTAURANTS_PATH = '/key-account-groups/:keyAccountId/restaurants',
  KEY_ACCOUNT_GROUP_RETURN_CODES_PATH = '/key-account-groups/:keyAccountId/return-codes',
  ADD_RESTAURANT_KEY_ACCOUNT_GROUP_PATH = '/key-account-groups/:keyAccountId/add-restaurant',
  EDIT_KEY_ACCOUNT_GROUP_PATH = '/key-account-groups/:keyAccountId/edit',
  MASS_EDIT_KEY_ACCOUNT_RESTAURANTS_PATH = '/key-account-groups/:keyAccountId/restaurants/mass/edit',
  KEY_ACCOUNT_GROUP_IMAGES_PATH = '/key-account-groups/:keyAccountId/images',
  RESTAURANT_USERS_PATH = '/restaurant-users',
  UPDATE_RESTAURANT_USERS_PATH = '/restaurant-users/:id',
  RESTAURANT_USERS_NOTIFICATION_SETTINGS_PATH = '/restaurant-users/:id/notification-settings',
  CREATE_RESTAURANT_USER_PATH = '/restaurant-users/new',
  PRODUCTS = '/products',
  GENERATE_PRODUCTS = '/products/generate',
  META_CATEGORIES_PATH = '/meta-categories',
  CREATE_META_CATEGORY_PATH = '/meta-categories/new',
  UPDATE_META_CATEGORY_PATH = '/meta-categories/:id',
  CLOSING_SOURCES_PATH = '/closing-sources',
  CREATE_CLOSING_SOURCE_PATH = '/closing-sources/new',
  UPDATE_CLOSING_SOURCE_PATH = '/closing-sources/:id',
  CUSTOMERS_PATH = '/customers',
  UPDATE_CUSTOMER_PATH = '/customers/:id',
  CUSTOMER_SUMMARY_PATH = '/customers/:id/summary',
  RESTAURANT_DRAFTS_PATH = '/restaurants/drafts',
  RESTAURANT_DRAFT_DETAILS_PATH = '/restaurants/:restaurantId/drafts/:draftId',
  FEATURE_FLAGS_PATH = '/feature-flags',
  TEST_NOTIFICATIONS_PATH = '/test-notifications',
  FREQUENTLY_ASKED_QUESTIONS_PATH = '/faq',
  CREATE_FAQ_SECTION_PATH = '/faq/section/new',
  UPDATE_FAQ_SECTION_PATH = '/faq/section/:id',
  CREATE_FAQ_QUESTION_PATH = '/faq/section/:sectionId/question/new',
  UPDATE_FAQ_QUESTION_PATH = '/faq/section/:sectionId/question/:id',
  REUSE_CARDS_PATH = '/reuse-cards',
  REUSE_CARDS_CREATE_PATH = '/reuse-cards/create',
  REUSE_CARDS_UPDATE_PATH = '/reuse-cards/:id',
  REUSE_CARDS_ASSIGNED_ITEMS_PATH = '/reuse-cards/:id/assigned-items',
  COUNTRIES_PATH = '/countries',
  COUNTRIES_COUNTRY_PATH = '/countries/:id',
  COUNTRIES_COUNTRY_PRICING_PATH = '/countries/:id/pricing',
  REUSABLE_TIPS_PATH = '/reusable-tips',
  CREATE_REUSABLE_TIP_PATH = '/reusable-tips/new',
  UPDATE_REUSABLE_TIP_PATH = '/reusable-tips/:id',
}
