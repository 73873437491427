import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import {
  getCountries,
  getCountry,
  getCountryPricing,
  updateCountry,
  updateCountryCategoryPricing,
} from '../api/countries';
import { ALL_PAGINATED_LIST_ITEMS_PARAMS } from '../common/constants/common';
import { BaseQueryParams, Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { CategoryPricingResponse } from '../modules/Countries/model/category-pricing-response';
import { CategoryPricingUpdateRequest } from '../modules/Countries/model/category-pricing-update-request';
import { CountryPricingResponse } from '../modules/Countries/model/country-pricing-response';
import { CountryResponse } from '../modules/Countries/model/country-response';
import { CountryUpdateRequest } from '../modules/Countries/model/country-update-request';

export const CK_COUNTRIES = 'countries';

export const useCountries = (
  params: BaseQueryParams = ALL_PAGINATED_LIST_ITEMS_PARAMS,
): QueryObserverResult<Paginated<CountryResponse>, AxiosError<HttpException>> =>
  useQuery([CK_COUNTRIES, { text: params.searchText, page: params.offset, limit: params.limit }], () =>
    getCountries(params),
  );

export const useCountry = (countryId: string): QueryObserverResult<CountryResponse, AxiosError<HttpException>> =>
  useQuery([CK_COUNTRIES, countryId], () => getCountry(countryId));

export const useUpdateCountry = (): UseMutationResult<
  CountryResponse,
  AxiosError<HttpException>,
  { id: string; values: CountryUpdateRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(({ id, values }: { id: string; values: CountryUpdateRequest }) => updateCountry(id, values), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(CK_COUNTRIES);
    },
  });
};

export const useCountryPricing = (
  countryId: string,
): QueryObserverResult<CountryPricingResponse, AxiosError<HttpException>> =>
  useQuery([CK_COUNTRIES, countryId, 'pricing'], () => getCountryPricing(countryId));

export const useUpdateCountryPricing = (): UseMutationResult<
  CategoryPricingResponse,
  AxiosError<HttpException>,
  { countryId: string; categoryId: string; values: CategoryPricingUpdateRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      countryId,
      categoryId,
      values,
    }: {
      countryId: string;
      categoryId: string;
      values: CategoryPricingUpdateRequest;
    }) => updateCountryCategoryPricing({ countryId, categoryId }, values),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(CK_COUNTRIES);
      },
    },
  );
};
