import React, { JSX, useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text, useDisclosure } from '@chakra-ui/react';

import { Table } from '../Table';

type ItemsSummarySectionModalBodyProps<FailureDataType extends object, SuccessDataType extends object> = {
  successText: string | JSX.Element | JSX.Element[];
  failureText: string | JSX.Element | JSX.Element[];
  columns: Column<FailureDataType>[];
  failureItems?: FailureDataType[];
  successItems?: SuccessDataType[];
};

export const ItemsSummarySectionModalBody = <FailureDataType extends object, SuccessDataType extends object>({
  successText,
  failureText,
  columns,
  failureItems,
  successItems,
}: ItemsSummarySectionModalBodyProps<FailureDataType, SuccessDataType>) => {
  const summaryTextColor = useMemo(() => {
    if (successItems?.length === 0) {
      return 'red.100';
    } else if (failureItems?.length === 0) {
      return 'green.100';
    } else {
      return 'orange.100';
    }
  }, [failureItems?.length, successItems?.length]);

  const {
    isOpen: showFailureItems,
    onOpen: handleShowFailureItems,
    onClose: handleCloseFailureItems,
  } = useDisclosure();

  const handleToggle = useCallback(
    () => (showFailureItems ? handleCloseFailureItems() : handleShowFailureItems()),
    [handleCloseFailureItems, handleShowFailureItems, showFailureItems],
  );

  return (
    <Flex alignItems="start" display="column">
      <Text p={2} bgColor={summaryTextColor}>
        {successText}
      </Text>
      {failureItems?.length ? (
        <>
          <Flex onClick={handleToggle} cursor="pointer" py={4}>
            <Center mr={2}>
              {!showFailureItems ? <ChevronDownIcon h={6} w={6} /> : <ChevronUpIcon h={6} w={6} />}
            </Center>
            <Text>{failureText}</Text>
          </Flex>
          {showFailureItems ? (
            <Box py={4}>
              <Table<FailureDataType> data={failureItems} columns={columns} isLoading={false} />
            </Box>
          ) : null}
        </>
      ) : null}
    </Flex>
  );
};
