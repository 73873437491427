import * as yup from 'yup';

import { useTranslations } from '../../contexts/LocalizationContext';
import { CategoryGroup } from './model/Category.model';

export type CategoryGroupSelect = {
  label: CategoryGroup | string;
  value: CategoryGroup | string;
};

export const selectOptions: CategoryGroupSelect[] = [
  { value: '', label: 'No category' },
  { value: CategoryGroup.BOWLS, label: CategoryGroup.BOWLS },
  { value: CategoryGroup.CUPS, label: CategoryGroup.CUPS },
  { value: CategoryGroup.PIZZA, label: CategoryGroup.PIZZA },
];

export const useCategoriesFormValidationSchema = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    translationKey: yup
      .string()
      .required(translations('validation_required'))
      .max(255, translations('validation_too_long')),
    categoryGroup: yup.string().nullable(),
    borrowingPeriod: yup.number().typeError(translations('validation_number_type_error')).nullable(),
    extensionPeriod: yup.number().typeError(translations('validation_number_type_error')).nullable(),
  });
};
