import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { ErrorMessages, FullPageLoader, MainPageContent, PageHeading } from '../../../common/components';
import { useTranslations } from '../../../contexts/LocalizationContext';
import { useRestaurantBillingInformation } from '../../../services/restaurant-billing-information';
import { UpdateRestaurantBillingInformation } from './components/UpdateRestaurantBillingInformation/UpdateRestaurantBillingInformation';
import { convertResponseToFormValues } from './utils/restaurant-billing-information.helper';

export const RestaurantBillingInformation = () => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  invariant(restaurantId, 'restaurantId is not set within the route');
  const translations = useTranslations();

  const {
    data: restaurantBillingInformation,
    isLoading: isLoadingBillingInformation,
    isFetching: isFetchingBillingInformation,
    error: billingInformationError,
  } = useRestaurantBillingInformation(restaurantId);

  const model = useMemo(
    () =>
      restaurantBillingInformation
        ? convertResponseToFormValues(restaurantBillingInformation.billingInformation)
        : undefined,
    [restaurantBillingInformation],
  );

  return (
    <MainPageContent>
      <PageHeading>{translations('restaurant_billing_information_header')}</PageHeading>
      <FullPageLoader show={isLoadingBillingInformation || isFetchingBillingInformation}>
        <ErrorMessages errors={billingInformationError} />
        <UpdateRestaurantBillingInformation
          restaurantId={restaurantId}
          restaurantName={restaurantBillingInformation?.restaurant.restaurantName}
          currency={restaurantBillingInformation?.restaurant.currency}
          model={model}
        />
      </FullPageLoader>
    </MainPageContent>
  );
};
