import * as yup from 'yup';

import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH } from '../../../utils/restaurant-billing-information.consts';

export const useRestaurantBillingInformationFormValidation = (): yup.AnyObjectSchema => {
  const translations = useTranslations();

  return yup.object().shape({
    businessName: yup
      .string()
      .nullable()
      .max(RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH, translations('validation_too_long')),
    billingAddress: yup
      .string()
      .nullable()
      .max(RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH, translations('validation_too_long')),
    corporateTaxId: yup
      .string()
      .nullable()
      .max(RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH, translations('validation_too_long')),
    internalReferenceId: yup
      .string()
      .nullable()
      .max(RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH, translations('validation_too_long')),
    street: yup
      .string()
      .nullable()
      .max(RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH, translations('validation_too_long')),
    number: yup
      .string()
      .nullable()
      .max(RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH, translations('validation_too_long')),
    careOf: yup
      .string()
      .nullable()
      .max(RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH, translations('validation_too_long')),
    postalCode: yup
      .string()
      .nullable()
      .max(RESTAURANT_BILLING_INFORMATION_TEXT_INPUT_MAX_LENGTH, translations('validation_too_long')),
  });
};
