import { generatePath } from 'react-router-dom';

import { BaseQueryParams, Paginated } from '../common/models';
import { buildBasicPaginatedSearchParams } from '../common/utils/searchParams.helper';
import apiPaths from '../config/apiPaths';
import { CategoryPricingResponse } from '../modules/Countries/model/category-pricing-response';
import { CategoryPricingUpdateRequest } from '../modules/Countries/model/category-pricing-update-request';
import { CountryPricingResponse } from '../modules/Countries/model/country-pricing-response';
import { CountryResponse } from '../modules/Countries/model/country-response';
import { CountryUpdateRequest } from '../modules/Countries/model/country-update-request';
import axiosInstance from './axios';

export const getCountries = ({ searchText, offset, limit }: BaseQueryParams): Promise<Paginated<CountryResponse>> => {
  const searchParams = buildBasicPaginatedSearchParams(offset, limit, searchText);
  return axiosInstance
    .get<Paginated<CountryResponse>>(`${apiPaths.COUNTRIES}?${searchParams}`)
    .then(({ data }) => data);
};

export const getCountry = (countryId: string): Promise<CountryResponse> => {
  return axiosInstance
    .get<CountryResponse>(generatePath(apiPaths.COUNTRIES_COUNTRY, { countryId }))
    .then(({ data }) => data);
};

export const updateCountry = (countryId: string, request: CountryUpdateRequest): Promise<CountryResponse> => {
  return axiosInstance.put(generatePath(apiPaths.COUNTRIES_COUNTRY, { countryId }), request);
};

export const getCountryPricing = (countryId: string): Promise<CountryPricingResponse> => {
  return axiosInstance.get(generatePath(apiPaths.COUNTRIES_COUNTRY_PRICING, { countryId })).then(({ data }) => data);
};

export const updateCountryCategoryPricing = (
  { countryId, categoryId }: { countryId: string; categoryId: string },
  request: CategoryPricingUpdateRequest,
): Promise<CategoryPricingResponse> => {
  return axiosInstance.put(
    generatePath(apiPaths.COUNTRIES_COUNTRY_CATEGORY_PRICING, { countryId, categoryId }),
    request,
  );
};
